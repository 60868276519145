import React, { Fragment } from 'react';

import { WingBlank, Carousel, Flex } from 'antd-mobile';
import { Icon } from 'antd';
import { observer, inject } from 'mobx-react';
import styled, { css } from 'styled-components';

import Header from '../components/header';
import Footer from '../components/footer';
import MainEventCard from 'containers/mainEventCard';
import EventList from 'containers/eventList';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import ItemLinkRequiredModal from 'components/popModal/itemLinkRequiredModal';
import SpinnerCurtain from 'components/loadingSpinner/spinnerCurtain';

import {
	Wrap,
	IndexWrap,
	MainEventWrap,
} from '../components/style/custom-styled-components';
import TopNavBar from '../components/nav/navBar';

import * as eventAPI from 'axios/eventAPI';

const MainEventBanner = styled.div`
	width: 100%;
	margin: auto;
	height: 132px;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
`;

@inject('cacheStore')
@inject('userStore')
@observer
class Event extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = true;
		this.state = this.props.cacheStore.eventData
			? this.props.cacheStore.eventData
			: {
					banner: [],
					data: [],
					filter: 'deadline',
					apiSuccess: false,
			  };
	}
	async componentDidMount() {
		const req = {
			filter: this.state.filter,
			headers: this.props.userStore.auth_token
				? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
				: false,
		};
		const eventBanner = await eventAPI.getEventBanner('event').then(res => {
			return res.data;
		});
		const events = await eventAPI.getEvents(req).then(res => {
			return res.data.data;
		});
		if (this._isMounted) {
			this.setState({
				apiSuccess: true,
				banner: eventBanner,
				data: events,
			});
		}
	}
	componentWillUnmount() {
		this._isMounted = false;
		this.props.cacheStore.setEventData(this.state);
	}
	render() {
		const { banner, data } = this.state;
		return (
			<Wrap style={{ paddingBottom: 56 }}>
				<TopNavBar />
				<LoginRequiredModal bottom={'0px'} />
				<ItemLinkRequiredModal bottom={'0px'} />

				<IndexWrap style={{ paddingTop: 45 }}>
					{this.state.apiSuccess ? (
						<Fragment>
							<Carousel
								autoplay={false}
								slideWidth={0.94135}
								selectedIndex={0}
								//beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
								//afterChange={index => console.log('slide to', index)}
								dotStyle={{
									transition: '1s',
									width: '6px',
									height: '5px',
									backgroundColor: '#dedede',
								}}
								dotActiveStyle={{
									transition: '1s',
									width: '17px',
									height: '5px',
									borderRadius: '20px',
									backgroundColor: '#828ff7',
								}}
							>
								{banner.map((item, index) => (
									<div
										key={index}
										style={{
											paddingTop: '16px',
											paddingBottom: '25px',
											paddingLeft: '6px',
											paddingRight: '10px',
										}}
									>
										<MainEventBanner>
											<MainEventCard data={item} mode="event" key={index} />
										</MainEventBanner>
									</div>
								))}
							</Carousel>
							<EventList events={data} />
						</Fragment>
					) : (
						<SpinnerCurtain fullsize={true} />
					)}
				</IndexWrap>
				<Footer />
			</Wrap>
		);
	}
}

export default Event;
