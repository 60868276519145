import React from 'react';
import axios from 'axios';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import Loader from 'components/loadingSpinner/spinnerCurtain';
import * as postAPI from 'axios/postAPI';
import history from 'utils/history';
import Select from 'components/Select';
import clsx from 'clsx';
import Avatars from 'components/Avatars';
import UserList from 'components/UserList';
import EllipsisText from 'components/EllipsisText';
import back_ico from 'images/back_ico_w.svg';

const NavWrap = styled.div`
  padding-top: 34px;
  z-index: 10;
  background: #854fff;
  width: 100%;
  max-width: 1024px;
  // transition: 0.5s;
  ${(props) =>
    props.toggle &&
    css`
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    `}
`;
const FilterText = styled.span`
  color: #bebebe;
  font-family: 'Noto Sans CJK KR';
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.name == props.clicked &&
    css`
      color: #333333;
      font-family: 'Noto Sans CJK KR';
      font-size: 15px;
      font-weight: 700;
    `}
`;

const IconWrap = styled.div`
  color: white;
  fill: white;
  cursor: pointer;
  margin-bottom: 30px;
  :hover {
    opacity: 0.8;
  }
`;

const base = 'https://star-api.lay-er.me';

const INIT = 'INIT';
const SORT_BY = { RECENT: 'recent', RECOMMENDED: 'recommended' };

@inject('cacheStore')
@inject('userStore')
@inject('postStore')
@observer
class MyPage extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      initData: this.props.cacheStore.myProfileData,
      eventBanner: this.props.cacheStore.myEventBanner,
      accessToken: this.props.userStore.auth_token,
      changed: false,
      productCategoryModalOpen: false,
      posts: [],
      next: INIT,
      page: 0,
      topic: [],
      firstTopic: null,
      sortByOptions: [
        { text: '신규순', value: SORT_BY.RECENT },
        { text: '추천순', value: SORT_BY.RECOMMENDED },
      ],
      sortBySelected: SORT_BY.RECENT,
      postProfiles: [],
      userListModalOn: false,
    };
  }
  async componentDidMount() {
    // 토픽 정보 요청 시작
    const { search } = window.location;
    let topicTitle;
    let topic;
    const topicTitleRegExp = /title=(.+?)(?=(\s|&|$))/.exec(search);
    if (topicTitleRegExp) {
      topicTitle = topicTitleRegExp[1];
      try {
        const topicReq = await axios.get(
          `${base}/topics?title=${topicTitle}&_sort=id:DESC`
        );
        topic = topicReq.data;

        const reg = /scroll=(\d+)/.exec(window.location.search);
        if (reg) {
          const scroll = Number(reg[1]);
          setTimeout(() => {
            this.scrollTo(scroll);
          }, 300);
        }
      } catch (e) {
        console.error(e);
        window.location.href = '/';
      }
    }
    const searchParams = new URLSearchParams(window.location.search);
    const sortBy = searchParams.get('sortBy') || this.state.sortBySelected;
    this.setState({ topic, sortBySelected: sortBy });
    // 토픽 정보 요청 종료
    this.getPostList({
      sortBy,
    });

    this.scrollEvent = this.getScrollEle().addEventListener(
      'scroll',
      this.scrollHandler
    );
  }

  toSns = (url) => {
    if (url) window.open(url, '_blank');
  };
  componentWillUnmount() {
    this.getScrollEle().removeEventListener('scroll', this.scrollHandler);
    this._isMounted = false;
    this.props.cacheStore.setMyProfileData(this.state.initData);
    this.props.cacheStore.setMyEventBanner(this.state.eventBanner);
    this.props.cacheStore.setUserProfileData(this.state.initData);
  }
  scrollHandler = () => {
    const { scrollHeight, scrollTop, offsetHeight } =
      document.getElementById('SCROLL_WRAP');
    const maxScroll = scrollHeight - offsetHeight;
    if (maxScroll - scrollTop < 100) {
      this.getPostList();
    }
  };
  handleClickAdd = () => {
    this.setState({
      productCategoryModalOpen: true,
    });
  };
  getScrollEle = () => document.getElementById('SCROLL_WRAP');
  scrollTo = (number) => (this.getScrollEle().scrollTop = number);
  getScrollY = () => {
    const ele = this.getScrollEle();
    return ele.scrollTop;
  };
  getPostList = async (
    { renew, sortBy } = { renew: false, sortBy: SORT_BY.RECENT }
  ) => {
    // 포스트를 요청하고 상태를 업데이트 한다.
    // 프로필 Id로 필터링한다.
    if (!this.state.loading) {
      this.setState({ loading: true });
      const userStore = this.props;

      const { topic } = this.state;
      const Authorization = `Bearer ${userStore.auth_token}`;
      const headers = userStore.auth_token ? { Authorization } : undefined;
      let posts = (
        await Promise.all(
          topic.map(async ({ postId }) => {
            if (postId) {
              try {
                const req = {
                  id: postId,
                  headers,
                };
                const postData = await postAPI
                  .getPost(req)
                  .then((res) => {
                    return res.data;
                  })
                  .catch((e) => {
                    console.error(e);
                  });
                return postData;
              } catch (e) {
                console.log(e);
              }
            }
          })
        )
      ).filter((o) => o);
      const postProfiles = posts
        .map(({ profile }) => profile)
        .reduce((acc, val) => {
          if (!acc.find((item) => item.id === val.id)) acc.push(val);
          return acc;
        }, []);
      posts = posts.sort((a, b) => {
        if (sortBy === SORT_BY.RECENT) {
          return new Date(b.created_at) - new Date(a.created_at);
        } else {
          return new Date(b.like.count) - new Date(a.like.count);
        }
      });
      this.setState({ loading: false, posts, postProfiles });
    }
  };
  render() {
    const { classes } = this.props;
    const { topic, posts } = this.state;
    let firstTopic;
    if (topic.length && posts.length) {
      firstTopic = topic.find(({ first }) => first);
    }
    const topicName = firstTopic ? firstTopic.title : '';
    const topicDescription = firstTopic ? firstTopic.description : '';
    let topicStarter = '';
    if (firstTopic) {
      topicStarter = firstTopic.starter;
    }
    const notLogin = !this.props.userStore.auth_token;

    return (
      <Wrap
        style={{
          paddingBottom: 0,
          height: '-webkit-fill-available',
          // backgroundColor: "rgb(248,248,248)",
          overflowY: 'hidden',
        }}
      >
        <IndexWrap
          id="SCROLL_WRAP"
          style={{
            paddingTop: 0,
            backgroundColor: '#f8f8f8',
          }}
        >
          <NavWrap>
            <div style={{ paddingLeft: 22 }}>
              <IconWrap key="iconback" onClick={() => window.history.back()}>
                <img
                  src={back_ico}
                  style={{
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                  }}
                />
              </IconWrap>
              <div style={{ fontSize: 18, color: 'white' }}>
                <strong>{topicName}</strong>
              </div>
              <div style={{ color: 'white', fontSize: 13 }}>
                <p>
                  {this.state.postProfiles.length}명 참여 중
                  {topicStarter && topicStarter !== ''
                    ? ` ∙ by ${topicStarter}`
                    : ''}
                </p>
              </div>
              {topicDescription && topicDescription !== '' ? (
                <EllipsisText
                  text={topicDescription}
                  maxLines={3}
                  backgroundColor="#854fff"
                  style={{ paddingRight: 22, marginBottom: 12 }}
                />
              ) : null}
            </div>
            <div
              style={{
                display: 'flex',
                padding: '2px 8px',
                fontSize: 14,
                color: '#959dad',
                alignItems: 'center',
                background: 'white',
              }}
            >
              <Avatars
                style={{ flex: 1 }}
                profiles={this.state.postProfiles}
                onClick={() => {
                  this.setState({
                    userListModalOn: true,
                  });
                }}
              />
              <Select
                items={this.state.sortByOptions}
                value={this.state.sortBySelected}
                onClick={(value) => {
                  const url = new URL(window.location.href);
                  url.searchParams.set('sortBy', value);
                  history.replace(`${window.location.pathname}${url.search}`);
                  this.setState({
                    sortBySelected: value,
                  });
                  this.getPostList({ renew: false, sortBy: value });
                }}
              />
            </div>
          </NavWrap>
          <div style={{ overflowY: 'scroll' }}>
            <div className={classes.grid}>
              {this.state.posts &&
                this.state.posts.map(({ id, thumbnail_image, profile }, i) => (
                  <div
                    key={`${id}-${i}`}
                    className={clsx(classes.imgWrapper, {
                      [classes.noBorderImgWrapper]: i % 3 === 2,
                    })}
                  >
                    <a
                      onClick={() => {
                        let prevSearch = window.location.search.replace(
                          /&scroll=\d+/g,
                          ''
                        );
                        prevSearch = prevSearch.replace(/scroll=\d+/g, '');
                        let search =
                          window.location.search === ''
                            ? '?'
                            : `${prevSearch}&`;
                        history.replace(
                          window.location.pathname +
                            search +
                            `scroll=${this.getScrollY()}`
                        );
                        history.push(`/post/${id}`);
                      }}
                    >
                      <img src={thumbnail_image} className={classes.img} />
                      <div className={classes.overlay}>
                        <div className={classes.overlaiedRank}>
                          {this.state.sortBySelected === SORT_BY.RECOMMENDED
                            ? `${i + 1}위`
                            : ''}
                        </div>
                        {profile ? (
                          <div className={classes.overlaiedNickname}>
                            {profile.nickname}
                          </div>
                        ) : null}
                      </div>
                    </a>
                  </div>
                ))}
            </div>
          </div>
          {this.state.loading && <Loader />}
        </IndexWrap>
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="add"
          onClick={() => {
            if (notLogin) {
              history.push('/login');
            } else {
              const title = this.state.topic && this.state.topic[0].title;
              let regSumId = /sumId=(\d+)(?=\s|&|$)/.exec(
                window.location.search
              );
              if (regSumId) {
                window.location.href = `/new-post?sum=${
                  regSumId[1]
                }&topic=${encodeURIComponent(title)}`;
              }
            }
          }}
        >
          <AddIcon />
        </Fab>
        {this.state.userListModalOn && (
          <UserList
            onClose={() => {
              this.setState({ userListModalOn: false });
            }}
            profiles={this.state.postProfiles}
          />
        )}
        {/* <Footer onClickAdd={this.handleClickAdd} /> */}
      </Wrap>
    );
  }
}
export default withStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: 48,
    right: theme.spacing(2),
  },
  grid: { display: 'flex', flexFlow: 'row wrap' },
  imgWrapper: {
    width: '33.333333%',
    paddingTop: '33.333333%',
    position: 'relative',
    borderBottom: '1px solid white',
    borderRight: '1px solid white',
  },
  noBorderImgWrapper: {
    borderRight: 'none',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  intro: {
    padding: 16,
    paddingBottom: 16,
    borderBottom: '1px solid #f2f2f2',
  },
  tabWrapper: {
    flexGrow: 1,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabs: {
    width: '100%',
  },
  tab: {
    width: '50%',
    fontSize: 16,
    fontWeight: 700,
  },
  overlay: {
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    fontSize: 14,
    fontWeight: 500,
  },
  overlaiedRank: {
    backgroundImage:
      'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.3)  100%)',
    width: '100%',
    color: 'white',
    paddingLeft: 8,
    paddingTop: 6,
  },
  overlaiedNickname: {
    backgroundImage:
      'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.3)  100%)',
    width: '100%',
    color: 'white',
    paddingLeft: 8,
    paddingBottom: 6,
  },
}))(MyPage);
