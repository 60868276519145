export function setLocalStorageValidTime() {
  var hours = 87500; // Reset when storage is more than 24hours
  var now = new Date().getTime();
  var setupTime = localStorage.getItem("setupTime");
  if (setupTime == null) {
    localStorage.setItem("setupTime", now);
  } else {
    if (now - setupTime > hours * 60 * 60 * 1000) {
      localStorage.removeItem("setupTime");
      localStorage.removeItem("ms_auth_token");
      localStorage.setItem("setupTime", now);
    }
  }
}

export function getAuthToken() {
  setLocalStorageValidTime();
  let token = localStorage.getItem("ms_auth_token");
  if (token) {
    return JSON.parse(localStorage.getItem("ms_auth_token"));
  }
  return false;
}

export function getRecentSearch() {
  let search = localStorage.getItem("rec_search");
  if (search) return JSON.parse(search);
  return [];
}

export function updateRecentSearch(search) {
  localStorage.setItem("rec_search", JSON.stringify(search));
}

export function resetRecentSearch() {
  localStorage.setItem("rec_search", "");
}
