import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import BaseIcon from 'components/myIcons/baseIcon';
import TopicIcon from 'components/myIcons/topicIcon';
import PollIcon from 'components/myIcons/pollIcon';
import MissionIcon from 'components/myIcons/missionIcon';
import QnaIcon from 'components/myIcons/qnaIcon';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20%',
    marginBottom: 16,
  },
  circle: {
    borderRadius: '100%',
    border: '1px solid transparent',
    background: 'white',
    width: 56,
    height: 56,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 6,
  },
  selectedCircle: { border: '1px solid #5773ff' },
  text: { fontSize: 13 },
  selectedText: { color: '#5773ff' },
  icon: { width: 18, height: 18, fill: '#454f63' },
  selectedIcon: { fill: '#5773ff' },
});
const TYPES = [
  { Icon: BaseIcon, text: '일반' },
  { Icon: PollIcon, text: '투표' },
  { Icon: QnaIcon, text: 'Q&A' },
  { Icon: MissionIcon, text: '미션' },
  { Icon: TopicIcon, text: '토픽' },
];
const PostTypeSelect = ({ selected, handleSelect }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {TYPES.map(({ Icon, text }, i) => {
        const _selected = selected === i;
        return (
          <div
            className={classes.button}
            key={text}
            role="button"
            onClick={() => handleSelect(i)}
          >
            <div
              className={clsx(
                classes.circle,
                _selected && classes.selectedCircle
              )}
            >
              <Icon
                className={clsx(
                  classes.icon,
                  _selected && classes.selectedIcon
                )}
              />
            </div>
            <p
              className={clsx(classes.text, _selected && classes.selectedText)}
            >
              {text}
            </p>
          </div>
        );
      })}
    </div>
  );
};
export default PostTypeSelect;
