import React, { Fragment } from "react";
import styled, { css, keyframes } from "styled-components";
import { observer, inject } from "mobx-react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import history from "utils/history";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "components/loadingSpinner/spinnerCurtain";

import FollowBtn from "components/followBtn";
import InstaIcon from "components/myIcons/instaIcon";
import YoutubeIcon from "components/myIcons/youtubeIcon";

// import history from "utils/history";
import * as userAPI from "axios/userAPI";
import * as postAPI from "axios/postAPI";

import default_profile from "images/default_profile.png";

const IMAGE =
  "https://movedot.s3.amazonaws.com/media/thumbnail/2020-05-31/GQg5iMwc5g_thumbnail.jpeg";

const INIT = "INIT";
@inject("userStore")
@inject("postStore")
@observer
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {},
      profileId: this.props.id,
      userProfileModalOn: false,
      notOnServiceModalOn: false,
      reportModalOn: false,
      loginRequiredModalOn: false,
      tabIdx: null,
      posts: [],
      next: INIT,
      page: 0,
    };
    this.scrollEvent = null;
  }
  async componentDidMount() {
    const reg = /scroll=(\d+)/.exec(window.location.search);
    if (reg) {
      const scroll = Number(reg[1]);
      setTimeout(() => {
        this.scrollTo(scroll);
      }, 500);
    }

    this.scrollEvent = this.getScrollEle().addEventListener(
      "scroll",
      this.scrollHandler
    );
  }
  componentWillUnmount() {
    this.getScrollEle().removeEventListener("scroll", this.scrollHandler);
  }
  scrollHandler = () => {
    const { scrollHeight, scrollTop, offsetHeight } = document.getElementById(
      "SCROLL_WRAP"
    );
    const maxScroll = scrollHeight - offsetHeight;
    if (maxScroll - scrollTop < 100) {
      this.props.getPostList();
    }
  };
  getScrollEle = () => document.getElementById("SCROLL_WRAP");
  scrollTo = (number) => (this.getScrollEle().scrollTop = number);
  getScrollY = () => {
    const ele = this.getScrollEle();
    return ele.scrollTop;
  };
  render() {
    const { classes, posts } = this.props;
    return (
      <div className={classes.grid}>
        {posts &&
          posts.map(({ id, thumbnail_image }, i) => (
            <div
              key={`${id}-${i}`}
              className={clsx(classes.imgWrapper, {
                [classes.noBorderImgWrapper]: i % 3 === 2,
              })}
            >
              <a
                onClick={() => {
                  let prevSearch = window.location.search.replace(
                    /&scroll=\d+/g,
                    ""
                  );
                  prevSearch = prevSearch.replace(/scroll=\d+/g, "");
                  let search =
                    window.location.search === "" ? "?" : `${prevSearch}&`;
                  history.replace(
                    window.location.pathname +
                      search +
                      `scroll=${this.getScrollY()}`
                  );
                  history.push(`/post/${id}`);
                }}
              >
                <img src={thumbnail_image} className={classes.img} />
              </a>
            </div>
          ))}
      </div>
    );
  }
}
export default withStyles((theme) => ({
  grid: { display: "flex", flexFlow: "row wrap" },
  imgWrapper: {
    width: "33.333333%",
    paddingTop: "33.333333%",
    position: "relative",
    borderBottom: "1px solid white",
    borderRight: "1px solid white",
  },
  noBorderImgWrapper: {
    borderRight: "none",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  intro: {
    padding: 16,
    paddingBottom: 16,
    borderBottom: "1px solid #f2f2f2",
  },
  tabWrapper: {
    flexGrow: 1,
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabs: {
    width: "100%",
  },
  tab: {
    width: "50%",
    fontSize: 16,
    fontWeight: 700,
  },
}))(Profile);
