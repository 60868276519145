import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Grid } from 'antd-mobile';
import { observer, inject } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios';

import CommentModal from 'components/popModal/commentModal';
import MainCard from 'components/mainCrtPost/mainCard';

import * as postAPI from 'axios/postAPI';
import * as userAPI from 'axios/userAPI';
import history from 'utils/history';
import LoadingSpinner from '../components/loadingSpinner/loadingSpinner';
import UpperDeco from 'components/upperDeco';
import Notice from 'components/notice';
import ArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import ImageViewer from '../components/ImageViewer';
import getUserOrCreate from 'utils/getUserOrCreate';
import Picks from 'components/Picks';

const Wrap = styled.div`
  ${(props) => (props.header ? `padding-top: 40px;` : '')}
`;

const Header = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 2000;
  background: rgba(248, 248, 248);
  height: 50px;
`;
const MyGrid = styled(Grid)`
  display: block;
  // overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
`;
const Nickname = styled.div`
  position: absolute;
  top: 14px;
  left: 0;
  right: 0;
  text-align: center;
`;

const TABS = ['포스팅', '상품', '사용자'];

const base = 'https://star-api.lay-er.me';
@inject('userStore')
@observer
class SearchResult extends React.Component {
  constructor(props) {
    super(props);
    const isFollowing =
      typeof window !== 'undefined' &&
      window.location.href.includes('following=true');

    this.state = {
      isFollowing,
      currentTab: this.props.currentTab ? this.props.currentTab : '포스팅',
      filter: 'new',
      filterText: '최신순',
      filterDownToggle: false,
      data: null,
      page: 0,
      next: null,
      loading: false,
      modalOpen: false,
      newNoti: false,
      filterSelected: null,
      commentModalOpen: false,
      commentModalPost: null,
      world: null,
      sum: null,
      user: null,
      error: false,
      viewerOpen: false,
      viewerImage: null,
      picks: [],
    };

    const prevState = window.localStorage.getItem('listState-individual-sum');
    if (prevState) {
      if (
        typeof window !== 'undefined' &&
        !window.location.href.includes('/post/')
      ) {
        this.state = JSON.parse(prevState);
      }
    }
    this.scrollParent = React.createRef();
  }

  loadWorld = async (worldId) => {
    return await axios.get(`${base}/worlds/${worldId}`);
  };
  loadSum = async () => {
    const sums = await axios.get(`${base}/sums?_sort=priority:ASC`);
    const sumId = String(this.props.sumId);
    const sum = sums.data.find(({ id }) => String(id) === String(sumId));
    return sum;
  };
  async componentDidMount() {
    // 유저 정보 획득하고 없으면 생성
    const user = await getUserOrCreate(this.props.userStore.auth_token);
    this.setState({ user });

    const { worldId } = this.props;

    if (worldId) {
      // 월드 정보 획득
      const world = await this.loadWorld(worldId);
      this.setState({ world: world.data }, () => {
        this.loadMore();
      });
    } else {
      // 섬 정보 획득
      const sum = await this.loadSum();
      this.setState({ sum });

      // 이벤트
      try {
        const event = await axios.get(
          `${base}/events?sum.id=${sum.id}&hidden=false`
        );
        if (event && event.data && event.data.length) {
          this.setState({ event: event.data[0] });
        }
      } catch (e) {
        console.log('섬 이벤트 없음');
      }

      // 픽
      try {
        const picks = await axios.get(
          `${base}/picks?sum.id=${sum.id}&hidden=false`
        );
        if (picks && picks.data) {
          const headers = this.props.userStore.auth_token
            ? {
                Authorization: `Bearer ${this.props.userStore.auth_token}`,
              }
            : undefined;
          const posts = (
            await Promise.all(
              picks.data.map(({ postId }) =>
                postAPI
                  .getPost({
                    id: postId,
                    headers,
                  })
                  .then(
                    ({ data } = { data: null }) => ({ data }),
                    (error) => error
                  )
              )
            )
          )
            .map(({ data }) => data)
            .filter((o) => o);
          this.setState({
            picks: posts.map((post, i) => ({
              post,
              ...picks.data[i],
            })),
          });
        }
      } catch (e) {
        console.log('섬 이벤트 없음');
      }
    }
    this.props.handleTabChange(this.state.currentTab);
    window.onpopstate = this.closeModal;
    // noti
    const token = this.props.userStore.auth_token;
    if (token) {
      const req = {
        headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
      };
      const res = await userAPI.getIsNewNoti(req).then((res) => {
        return res;
      });
      if (res.data) {
        this.setState({
          newNoti: res.data.is_new,
        });
      }
    }
    // 사파리에서 최소화시 비디오 자동재생이 종료된다.
    // 이를 해결한다.
    document.addEventListener('visibilitychange', (e) => {
      if (!document.hidden) {
        setTimeout(() => {
          const vids = document.querySelectorAll('.VIDEOS');
          vids.forEach((o) => o.play());
        }, 1000);
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.searchVal !== '' &&
        this.props.tagListOn &&
        !nextProps.tagListOn) ||
      (nextProps.searchVal === '' &&
        !nextProps.tagListOn &&
        this.props.searchVal !== '')
    ) {
      window.localStorage.removeItem('listState-individual-sum');
      this.setState({ data: null, page: 0, next: null });
    }
  }

  async componentDidUpdate(prevProps) {
    // user만 검색
    if (prevProps.searchVal !== this.props.searchVal) {
      if (this.state.currentTab === '사용자' && this.props.searchVal) {
        this.setState({
          data: [],
          count: 0,
          page: 0,
          next: null,
        });
      }
    }
    console.log('componentDidUpdate and save state', this.state);
    window.localStorage.setItem(
      'listState-individual-sum',
      JSON.stringify(this.state)
    );
    console.log('componentDidUpdate and save state [end]');
  }
  handleMyFilter = () => {
    const token = this.props.userStore.getTokenOrToggleLogin();
    if (token) {
      this.props.userStore.toggleMyFilter();
    }
  };
  handleFilter = (filter) => {
    const dict = {
      popular: '인기순',
      new: '최신순',
    };
    this.setState({
      filter,
      filterText: dict[filter],
      filterDropDown: false,
      data: null,
      page: 0,
      next: null,
    });
  };
  toggleFilter = () => {
    this.setState({
      filterDownToggle: !this.state.filterDownToggle,
    });
  };
  _onChange = (tab, _) => {
    this.setState({
      currentTab: tab.title,
      data: null,
      page: 0,
      next: null,
    });
    this.props.handleTabChange(tab.title);
  };
  loadData = async ({ page, tab }) => {
    try {
      this.setState({ loading: true });
      const headers = this.props.userStore.auth_token
        ? {
            Authorization: `Bearer ${this.props.userStore.auth_token}`,
          }
        : undefined;

      let result;
      if (this.props.singlePostId) {
        result = await postAPI
          .getPost({
            id: this.props.singlePostId,
            headers,
          })
          .then((res) => ({
            data: [res.data],
          }));

        // 게시물 1개일 경우 onLoad 호출
        if (this.props.onLoadSinglePost && result.data[0]) {
          this.props.onLoadSinglePost(result.data[0]);
        }
      } else if (this.props.worldId) {
        if (this.state.world) {
          const isHot = window.location.href.includes('hot=true');
          const hotThreshold = (isHot && this.state.world.hotThreshold) || 0;
          result = await postAPI
            .getPostList({
              id_of_sum: this.state.world.sums.map(({ id }) => id),
              following: this.state.isFollowing,
              category: this.state.filterSelected,
              hotThreshold,
              ordering: hotThreshold > 0 ? 'threshold' : undefined,
              // profile: this.state.profileId,
              headers,
              next: this.state.next,
            })
            .then((res) => res.data);
        }
      } else {
        result = await postAPI
          .getPostList({
            id_of_sum: String(this.props.sumId),
            following: this.state.isFollowing,
            category: this.state.filterSelected,
            // profile: this.state.profileId,
            headers,
            next: this.state.next,
          })
          .then((res) => res.data);
      }
      if (result) {
        let data = (this.state.data || []).concat(result.data);

        // DELETE DUPLICATE
        data = data.reduce((acc, val) => {
          if (!acc.find((o) => o.id === val.id)) acc.push(val);
          return acc;
        }, []);

        // 탭을 빠르게 변환하여 탭 변환 이후에 리스트 데이터가 변화할 경우
        if (TABS[tab] === this.state.currentTab) {
          this.setState({
            data,
            count: result.count,
            page,
            next: result.next,
            // loading: false,
          });
        }
        this.setState({ loading: false });
        return data;
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, error: true });
    }
  };
  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  handleClickPostHeader = (name) => {
    switch (name) {
      case 'search': {
        history.push('/search');
        break;
      }
      case 'noti': {
        if (this.props.userStore.getTokenOrToggleLogin()) {
          history.push('/notification');
        }
        break;
      }
      case 'recommendation': {
        window.location.href = '/';
        break;
      }
      case 'following': {
        const token = this.props.userStore.getTokenOrToggleLogin();
        if (token)
          if (!window.location.search.includes('following')) {
            if (window.location.search === '') {
              window.location.href = '/?following=true';
            } else {
              window.location.href = '&&following=true';
            }
          }
        break;
      }
      case 'detailMore': {
        this.setState({
          postMoreInfoOpen: true,
        });
        break;
      }
    }
  };
  loadMore = async () => {
    let initialPage;
    if (this.state.currentTab == '포스팅') initialPage = 0;
    else if (this.state.currentTab == '상품') initialPage = 1;
    else if (this.state.currentTab == '사용자') initialPage = 2;
    const data = await this.loadData({
      page: this.state.page + 1,
      tab: initialPage,
    });
    return data;
  };
  handleClick = (name) => {
    switch (name) {
      case 'recommendation': {
        window.location.href = window.location.href.slice(
          0,
          window.location.href.indexOf('?')
        );
        break;
      }
      case 'following': {
        const token = this.props.userStore.getTokenOrToggleLogin();
        if (token)
          if (!window.location.search.includes('following')) {
            if (window.location.search === '') {
              window.location.href = '?following=true';
            } else {
              window.location.href = '&following=true';
            }
          }
        break;
      }
    }
  };
  updateFollow = async ({ user, sumId, isFollow }) => {
    if (!user) {
      alert('로그인이 필요합니다.');
    } else {
      const { sums } = user;
      let newSums = sums.slice();
      if (isFollow) {
        newSums.push({ id: sumId });
      } else {
        newSums = newSums.filter((o) => o.id !== sumId);
      }
      await axios.put(`${base}/users/${user.id}`, {
        sums: newSums,
      });
      // 갱신
      const renew = await getUserOrCreate(this.props.userStore.auth_token);
      this.setState({ user: renew });

      let followers = this.state.sum.followers;
      if (isFollow) {
        followers += 1;
      } else {
        followers -= 1;
      }
      await axios.put(`${base}/sums/${sumId}`, {
        followers,
      });
      // 섬 정보 갱신
      const sumsRenew = await this.loadSum();
      this.setState({ sum: sumsRenew });
    }
  };
  render() {
    const hasMore = this.state.page === 0 || Boolean(this.state.next);

    let clickedPost;
    if (this.state.data) clickedPost = this.state.data[this.state.modalIndex];

    const isAlreadyFollowed =
      this.state.user &&
      this.state.sum &&
      this.state.user.sums.find((o) => o.id === this.state.sum.id);

    console.log('-------------------------', this.state.picks);

    return (
      <Wrap header={this.props.singlePostId}>
        {this.state.error && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              position: 'absolute',
            }}
          >
            <h1>삭제된 게시글입니다.</h1>
          </div>
        )}
        {this.props.singlePostId ? (
          <Header>
            <IconButton
              style={{ zIndex: 100 }}
              onClick={this.props.onClickBack}
            >
              <ArrowBack />
            </IconButton>
            {this.state.data && this.state.data.length && (
              <Nickname>
                <strong>
                  {this.state.data[0].profile &&
                    this.state.data[0].profile.nickname}
                </strong>
              </Nickname>
            )}
          </Header>
        ) : this.state.world ? (
          <UpperDeco
            avatar={`${base}${this.state.world.image.url}`}
            title={this.state.world ? this.state.world.name : ' '}
            handleClick={this.handleClick}
          />
        ) : (
          <>
            <UpperDeco
              avatar={
                this.state.sum ? `${base}${this.state.sum.image.url}` : null
              }
              title={this.state.sum ? this.state.sum.name : ' '}
              handleClick={this.handleClick}
              followButton={this.state.sum}
              handleFollow={(next) => {
                if (this.state.user && this.state.sum) {
                  this.updateFollow({
                    user: this.state.user,
                    sumId: this.state.sum.id,
                    isFollow: next,
                  });
                } else {
                  if (this.props.userStore.getTokenOrToggleLogin()) {
                    history.push('/login');
                  }
                }
              }}
              isAlreadyFollowed={isAlreadyFollowed}
              marginBottom={this.state.event}
            />
            {this.state.event && (
              <Notice
                text={this.state.event.name}
                onClick={() => (window.location.href = this.state.event.link)}
              />
            )}

            {this.state.picks.length > 0 && (
              <Picks
                sumName={this.state.sum ? this.state.sum.name : '모든섬'}
                items={this.state.picks.map((pick) => ({
                  id: pick.id,
                  postId: pick.postId,
                  title: pick.name,
                  img: pick.post && pick.post.thumbnail_image,
                }))}
              />
            )}
          </>
        )}
        <InfiniteScroll
          pageStart={this.state.page}
          loadMore={this.loadMore}
          hasMore={!this.state.loading && hasMore && !this.state.error}
          useWindow={true}
        >
          <MyGrid
            data={this.state.data}
            columnNum={1}
            hasLine={false}
            activeStyle={false}
            itemStyle={{
              position: 'relative',
              width: '100%',
            }}
            renderItem={(post, index) => (
              <MainCard
                key={post.id}
                sum={
                  this.state.world
                    ? this.state.world.sums.find(
                        (sum) => String(sum.id) === post.id_of_sum
                      )
                    : null
                }
                onClickSumName={() => history.push(`/sum/${post.id_of_sum}`)}
                userId={this.state.user && this.state.user.id}
                stop={this.state.modalOpen}
                data={JSON.stringify(post)}
                isOpenCommentModal={this.state.commentModalOpen}
                openCommentModal={(state, post) => {
                  this.setState({
                    commentModalOpen: state,
                    commentModalPost: post ? post : this.state.commentModalPost,
                  });
                }}
                isMain
                onClickPostMore={this.props.onClickPostMore}
                onClickProducts={this.props.onClickProducts}
                toggleLogin={this.props.userStore.getTokenOrToggleLogin}
                onClickImage={(image) =>
                  this.setState({
                    viewerOpen: true,
                    viewerImage: image,
                  })
                }
              />
            )}
          />
          {this.state.loading && (
            <div
              className="loader"
              style={{ textAlign: 'center', height: 400 }}
            >
              <LoadingSpinner />
            </div>
          )}
          <div style={{ height: 100 }} />
        </InfiniteScroll>

        <CommentModal
          open={this.state.commentModalOpen}
          handleClose={() =>
            this.setState({
              commentModalOpen: false,
            })
          }
          voteData={
            this.state.commentModalPost && this.state.commentModalPost.voteData
          }
          // 코멘트 카운트
          handleChange={(commentCount) => {
            const post = this.state.data.find(
              (post) => post.id === this.state.commentModalPost.id
            );
            post.comment.count = commentCount;
            const id = this.state.commentModalPost.id;
            this.setState({ commentCount });
          }}
          postId={this.state.commentModalPost && this.state.commentModalPost.id}
          bottom={'0px'}
        />
        <ImageViewer
          open={this.state.viewerOpen}
          handleClose={() => this.setState({ viewerOpen: false })}
          src={this.state.viewerImage}
        />
      </Wrap>
    );
  }
}
export default SearchResult;
