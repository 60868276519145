import React from 'react';
import styled, { css } from 'styled-components';
import CloseIcon from '../myIcons/closeIcon';
import SearchIcon from 'components/myIcons/searchIcon';

const Layout = styled.div`
	padding-top: 28px;
	padding-bottom: 8px;
	z-index: 10;
	width: 100%;
	color: white;
	text-align: center;
`;
const Button = styled.button`
	background-color: transparent;
	border: none;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	margin-right: 4px;
	margin-left: 4px;
	padding-right: 0px;
	padding-left: 0px;
	${(props) =>
		props.selected &&
		css`
			color: #828ff7;
			border-bottom: 2px solid #828ff7;
		`}
`;
const Detail = styled.span`
	position: absolute;
	right: 16px;
`;
const Close = styled.span`
	position: absolute;
	left: 16px;
`;
const Dot = styled.div`
	position: absolute;
	width: 8px;
	height: 8px;
	left: 29px;
	border-radius: 50%;
	top: 15px;
	border: 1px solid #f8f8f8;
	background-color: #828ff7;
`;
const IconWrap = styled.i`
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;
const Nav = styled.div`
	display: flex;
	justify-content: space-between;
`;
export default ({
	isRecommendation,
	disableRecommendationAndFollowing,
	onClick,
	newNoti,
	onClose,
}) => (
	<Layout>
		{disableRecommendationAndFollowing ? null : (
			<div>
				<Button
					selected={isRecommendation}
					onClick={() => onClick('recommendation')}
					role="button"
				>
					추천
				</Button>
				<Button
					selected={!isRecommendation}
					onClick={() => onClick('following')}
					role="button"
				>
					팔로잉
				</Button>
			</div>
		)}
		{onClose && (
			<Close
				onClick={(e) => {
					e.stopPropagation();
					onClose();
				}}
			>
				<CloseIcon style={{ fill: 'white' }} />
			</Close>
		)}
	</Layout>
);
