export function switchProfileHeight(v) {
  var height;
  switch (v) {
    case 0:
      height = "전체";
      break;
    case 1:
      height = "155";
      break;
    case 2:
      height = "160";
      break;
    case 3:
      height = "165";
      break;
    case 4:
      height = "170";
      break;
    case 5:
      height = "175";
      break;
    case 6:
      height = "180";
      break;
  }
  return height;
}

export function switchProfileSize(v) {
  var size;
  switch (v) {
    case 0:
      size = "전체";
      break;
    case 1:
      size = "XS";
      break;
    case 2:
      size = "S";
      break;
    case 3:
      size = "M";
      break;
    case 4:
      size = "L";
      break;
    case 5:
      size = "XL";
      break;
  }
  return size;
}

export function switchProfileSkin(v) {
  var skin;
  switch (v) {
    case 0:
      skin = "전체";
      break;
    case 1:
      skin = "지성";
      break;
    case 2:
      skin = "건성";
      break;
    case 3:
      skin = "복합성";
      break;
    case 4:
      skin = "민감성";
      break;
  }
  return skin;
}
