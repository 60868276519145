import * as userAPI from 'axios/userAPI';
import axios from 'axios';
import * as iosHandler from 'utils/iosHandler';

const base = 'https://star-api.lay-er.me';

export default async (token) => {
  try {
    // 유저 정보 획득하고 없으면 생성
    const userOnPrevDB = await userAPI
      .getMyUserProfile({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
    const { id, nickname } = userOnPrevDB;
    let user;
    const userRequested = await axios.get(`${base}/users?userId=${id}`);
    if (userRequested && userRequested.data.length) {
      user = userRequested.data[0];
      await axios.put(`${base}/users/${user.id}`, {
        lastTime: new Date().toISOString(),
      });
    } else {
      const userRequested = await axios.post(`${base}/users`, {
        userId: id,
        name: nickname,
        lastTime: new Date().toISOString(),
      });
      user = userRequested.data;
    }
    return user;
  } catch (e) {
    return null;
  }
};
