const getUserId = () => {
  if (typeof window !== 'undefined') {
    try {
      return window.initialData.userId;
    } catch (e) {
      // kiyeopyang 테스트 계정
      return 19;
    }
  }
};
export default getUserId;
