import React from 'react';
import axios from 'axios';
import { observer, inject } from 'mobx-react';
import SearchIcon from '@material-ui/icons/Search';
import styled, { css } from 'styled-components';
import Sum from 'components/sumWithPic';
import SumWithLastUploaded from 'components/sum';
import EnteredSums from 'containers/enteredSums';
import { Wrap } from 'components/style/custom-styled-components';
import UpperDeco from 'components/upperDeco';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Footer from 'components/footer';
import Swiper from 'react-id-swiper';
import history from 'utils/history';
import * as iosHandler from 'utils/iosHandler';
import sail from 'images/sail.png';
import right from 'images/right_light.png';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import getUserOrCreate from 'utils/getUserOrCreate';
import * as postAPI from 'axios/postAPI';
import Loader from 'components/loadingSpinner/loadingSpinner';
import sendMsgToAndroid from 'utils/sendMsgToAndroid';

const base = 'https://star-api.lay-er.me';

@inject('userStore')
@observer
class Sums extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const user = await getUserOrCreate(this.props.userStore.auth_token);
      console.log(user);
      if (user) {
        this.setState({ user });
        iosHandler.setPushKey(user.userId);
      }
    } catch (e) {
      console.log(e);
    }

    this.setState({
      loading: false,
    });
  }
  render() {
    // if (this.state.loading)
    //   return (
    //     <Wrap
    //       style={{
    //         height: '100vh',
    //         display: 'flex',
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //       }}
    //     >
    //       <Loader />
    //     </Wrap>
    //   );

    const sumIdReg =
      typeof window !== 'undefined' && /sumId=(\d+)/.exec(window.location.href);

    const sumId = sumIdReg ? sumIdReg[1] : null;

    return (
      <Wrap
        style={{
          paddingBottom: 0,
          backgroundColor: 'rgb(248,248,248)',
        }}
      >
        <LoginRequiredModal bottom={'0px'} />

        <Footer
          sumId={sumId}
          refreshOnlyContents={() => {
            window.location.href = '/';
          }}
          onClickAdd={() => {
            if (this.props.userStore.getTokenOrToggleLogin())
              history.push(`/new-post?sum=${sumId}`);
          }}
        />
        <EnteredSums
          sums={this.state.user && this.state.user.sums}
          sumId={sumId}
        />
      </Wrap>
    );
  }
}

export default Sums;
