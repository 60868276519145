import moment from 'moment';
import 'moment/locale/ko'; // without this line it didn't work
moment.locale('ko');

export function countDown(datetime) {
	var eventTime = moment('2019-05-31T17:46:01+09:00'); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
	var currentTime = moment(); // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
	var diffTime = eventTime - currentTime;
	var duration = moment.duration(diffTime, 'milliseconds');
	var interval = 1000;
	setInterval(function() {
		duration = moment.duration(duration - interval, 'milliseconds');
	}, interval);
}

export default function getRemainTime(datetime) {
	var eventTime = moment(datetime);
	var currentTime = moment();
	var diffTime = eventTime - currentTime;
	var duration = moment.duration(diffTime, 'milliseconds');
	return duration;
}
