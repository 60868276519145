import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';

import { observer, inject } from 'mobx-react';

import * as searchAPI from 'axios/searchAPI';

const Wrap = styled.div`
	p {
		cursor: pointer;
		color: #333333;
		font-family: 'Noto Sans CJK KR';
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 23px !important;
		:hover {
			opacity: 0.8;
		}
		span {
			float: right;
			color: #bebebe;
			font-family: 'Noto Sans CJK KR';
			font-size: 15px;
			font-weight: 500;
		}
	}
`;

@inject('cacheStore')
@observer
class SearchTagList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tags: [],
		};
	}
	async componentDidMount() {
		if (this.props.searchVal) {
			const result = await searchAPI
				.getTagList({ tag: this.props.searchVal })
				.then(res => {
					return res.data.data;
				});
			this.setState({ tags: result });
		}
	}
	async componentDidUpdate(prevProps) {
		if (prevProps.searchVal !== this.props.searchVal) {
			if (this.props.searchVal) {
				const result = await searchAPI
					.getTagList({ tag: this.props.searchVal })
					.then(res => {
						return res.data.data;
					});
				this.setState({ tags: result });
			}
		}
	}
	render() {
		return (
			<Wrap>
				{this.state.tags.map((tag, index) => {
					return (
						<p key={index} onClick={() => this.props.handleSelectTag(tag.name)}>
							{' '}
							#{tag.name} <span>포스팅 {tag.count}개</span>{' '}
						</p>
					);
				})}
			</Wrap>
		);
	}
}
export default SearchTagList;
