import React, { Component } from "react";
import styled, { css } from "styled-components";

class YoutubeIcon extends Component {
  constructor() {
    super();
  }

  render() {
    if (!this.props.mode) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="10"
          viewBox="0 0 13 10"
        >
          <g>
            <g>
              <path
                fill="#f61c0d"
                d="M8.474 4.784L4.92 6.48a.143.143 0 0 1-.204-.129V2.854c0-.106.112-.175.207-.127l3.555 1.801a.143.143 0 0 1-.003.256zM10.3 0H2.7A2.7 2.7 0 0 0 0 2.7v3.8a2.7 2.7 0 0 0 2.7 2.7h7.6A2.7 2.7 0 0 0 13 6.5V2.7A2.7 2.7 0 0 0 10.3 0z"
              />
            </g>
          </g>
        </svg>
      );
    } else if (this.props.mode == "userProfile") {
      return (
        <svg
          style={{ verticalAlign: "middle" }}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <g>
            <g>
              <path
                fill="#f61c0d"
                d="M10.277 6.27L6.176 8.226a.165.165 0 0 1-.236-.148V4.043c0-.123.13-.202.24-.147l4.101 2.079a.165.165 0 0 1-.004.295zM3.615.75A3.115 3.115 0 0 0 .5 3.865V8.25a3.115 3.115 0 0 0 3.115 3.115h8.77a3.115 3.115 0 0 0 3.114-3.115V3.865A3.115 3.115 0 0 0 12.384.75z"
              />
            </g>
          </g>
        </svg>
      );
    } else if (this.props.mode == "myProfile") {
      return (
        <svg
          style={{ verticalAlign: "text-bottom", marginRight: 25 }}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="15"
          viewBox="0 0 20 15"
        >
          <g>
            <g>
              <path
                fill="#f61c0d"
                d="M13.037 7.36L7.567 9.97a.22.22 0 0 1-.313-.199V4.39a.22.22 0 0 1 .319-.195l5.468 2.771a.22.22 0 0 1-.004.394zM15.846 0H4.154A4.154 4.154 0 0 0 0 4.154v5.844a4.154 4.154 0 0 0 4.154 4.154h11.692a4.154 4.154 0 0 0 4.153-4.154V4.154A4.154 4.154 0 0 0 15.846 0z"
              />
            </g>
          </g>
        </svg>
      );
    }
  }
}

export default YoutubeIcon;
