import React, { Component } from "react";
import styled, { css } from "styled-components";

class DetailMoreIcon extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="25"
        viewBox="0 0 28 22"
      >
        <defs>
          <mask id="paskb" width="2" height="2" x="-1" y="-1">
            <path fill="#fff" d="M4 2h20v18H4z" />
            <path d="M22 7.5c0 2.89-3.14 5.74-7.9 10.05l-.1.1-.1-.1C9.14 13.24 6 10.39 6 7.5 6 5.5 7.5 4 9.5 4c1.54 0 3.04.99 3.56 2.36h.007a1 1 0 0 0 1.889-.065C15.508 4.959 16.985 4 18.5 4c2 0 3.5 1.5 3.5 3.5zm-6.65 11.63l.1-.09c5.25-4.77 8.69-7.88 8.55-11.76-.06-1.71-.94-3.33-2.34-4.29-2.64-1.81-5.9-.96-7.66 1.1-1.76-2.06-5.02-2.9-7.66-1.1-1.4.96-2.28 2.58-2.34 4.28-.13 3.88 3.3 6.99 8.55 11.75l.11.1a1.99 1.99 0 0 0 2.69.01z" />
          </mask>
          <filter
            id="paska"
            width="50"
            height="50"
            x="-11"
            y="-13"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="2" in="SourceGraphic" result="FeOffset1152Out" />
            <feGaussianBlur
              in="FeOffset1152Out"
              result="FeGaussianBlur1153Out"
              stdDeviation="2 2"
            />
          </filter>
        </defs>
        <g>
          <g>
            <g filter="url(#paska)">
              <path
                fill="none"
                d="M22 7.5c0 2.89-3.14 5.74-7.9 10.05l-.1.1-.1-.1C9.14 13.24 6 10.39 6 7.5 6 5.5 7.5 4 9.5 4c1.54 0 3.04.99 3.56 2.36h.007a1 1 0 0 0 1.889-.065C15.508 4.959 16.985 4 18.5 4c2 0 3.5 1.5 3.5 3.5zm-6.65 11.63l.1-.09c5.25-4.77 8.69-7.88 8.55-11.76-.06-1.71-.94-3.33-2.34-4.29-2.64-1.81-5.9-.96-7.66 1.1-1.76-2.06-5.02-2.9-7.66-1.1-1.4.96-2.28 2.58-2.34 4.28-.13 3.88 3.3 6.99 8.55 11.75l.11.1a1.99 1.99 0 0 0 2.69.01z"
                mask='url("#paskb")'
              />
              <path
                fillOpacity=".2"
                d="M22 7.5c0 2.89-3.14 5.74-7.9 10.05l-.1.1-.1-.1C9.14 13.24 6 10.39 6 7.5 6 5.5 7.5 4 9.5 4c1.54 0 3.04.99 3.56 2.36h.007a1 1 0 0 0 1.889-.065C15.508 4.959 16.985 4 18.5 4c2 0 3.5 1.5 3.5 3.5zm-6.65 11.63l.1-.09c5.25-4.77 8.69-7.88 8.55-11.76-.06-1.71-.94-3.33-2.34-4.29-2.64-1.81-5.9-.96-7.66 1.1-1.76-2.06-5.02-2.9-7.66-1.1-1.4.96-2.28 2.58-2.34 4.28-.13 3.88 3.3 6.99 8.55 11.75l.11.1a1.99 1.99 0 0 0 2.69.01z"
              />
            </g>
            <path
              fill="#fff"
              d="M22 7.5c0 2.89-3.14 5.74-7.9 10.05l-.1.1-.1-.1C9.14 13.24 6 10.39 6 7.5 6 5.5 7.5 4 9.5 4c1.54 0 3.04.99 3.56 2.36h.007a1 1 0 0 0 1.889-.065C15.508 4.959 16.985 4 18.5 4c2 0 3.5 1.5 3.5 3.5zm-6.65 11.63l.1-.09c5.25-4.77 8.69-7.88 8.55-11.76-.06-1.71-.94-3.33-2.34-4.29-2.64-1.81-5.9-.96-7.66 1.1-1.76-2.06-5.02-2.9-7.66-1.1-1.4.96-2.28 2.58-2.34 4.28-.13 3.88 3.3 6.99 8.55 11.75l.11.1a1.99 1.99 0 0 0 2.69.01z"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default DetailMoreIcon;
