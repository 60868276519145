import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20%',
    marginBottom: 4,
  },
  circle: {
    borderRadius: '100%',
    border: '1px solid transparent',
    background: 'white',
    width: 45,
    height: 45,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 6,
  },
  selectedCircle: { border: '1px solid #5773ff' },
  text: { fontSize: 13, fontWeight: 'bold' },
  selectedText: { color: '#5773ff' },
  icon: { width: 24, height: 24, fill: '#454f63' },
  selectedIcon: { fill: '#5773ff' },
  formControl: {
    minWidth: 60,
  },
});
const SelectComp = ({ items, value, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <Select
          native
          disableUnderline
          value={value}
          onChange={(e) => onClick(e.target.value)}
        >
          {items.map(({ text, value }) => (
            <option value={value} key={text}>
              {text}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default SelectComp;
