import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles({
  imgWrapper: {
    maxWidth: 600,
    minWidth: 300,
    width: "100%",
  },
  img: {
    width: "100%",
  },
  buttonWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  icon: {
    color: "white",
  },
});
const ZoomDialog = ({ open, onClose, img, vid }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <div>
        <div className={classes.imgWrapper}>
          {img ? (
            <img className={classes.img} src={img} alt="zoom" />
          ) : (
            <video src={vid} autoPlay controls />
          )}
        </div>
        <div className={classes.buttonWrapper}>
          <IconButton onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        </div>
      </div>
    </Dialog>
  );
};
export default ZoomDialog;
