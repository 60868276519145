import React from 'react';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import CloseIcon from '../myIcons/closeIcon';
import SearchIcon from 'components/myIcons/searchIcon';
import Avatar from '@material-ui/core/Avatar';
import history from 'utils/history';

const Layout = styled.div`
  padding-top: 28px;
  padding-bottom: 8px;
  z-index: 10;
  width: 100%;
  color: white;
  text-align: center;
`;
const Button = styled.button`
  background-color: transparent;
  width: 95px;
  height: 35px;
  border-radius: 90px;
  border: none;
  color: black;
  font-family: 'Noto Sans CJK KR';
  font-weight: 500;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
  padding-right: 0px;
  padding-left: 0px;
  ${(props) =>
    props.selected &&
    css`
      background-image: linear-gradient(116deg, #1f75fe 0%, #00a0f3 100%);
      color: white;
    `}
`;
const FollowButton = styled.button`
  width: 90px;
  height: 28px;
  background-color: transparent;
  border: 1px solid #1f75fe;
  color: #1f75fe;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 20px;
`;
const Detail = styled.span`
  position: absolute;
  right: 16px;
`;
const Close = styled.span`
  position: absolute;
  left: 16px;
`;
const Dot = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  left: 29px;
  border-radius: 50%;
  top: 15px;
  border: 1px solid #f8f8f8;
  background-color: #828ff7;
`;
const IconWrap = styled.i`
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
const Nav = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Svg = ({ style } = { style: {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="375.867"
    height="188.787"
    viewBox="0 0 375.867 188.787"
    style={{
      fill: 'url(#linear-gradient)',
      width: '100%',
      ...style,
    }}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.021"
        y1="0.052"
        x2="0.978"
        y2="0.934"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#1f75fe" />
        <stop offset="1" stop-color="#1f75fe" />
      </linearGradient>
    </defs>
    <path
      id="Top_BG"
      data-name="Top BG"
      class="cls-1"
      d="M0,0H375.867a0,0,0,0,1,0,0V158.787a30,30,0,0,1-30,30H30a30,30,0,0,1-30-30V0A0,0,0,0,1,0,0Z"
    />
  </svg>
);

@inject('userStore')
@observer
class UpperDeco extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecommendation: this.props.isRecommendation || true,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.hasOwnProperty('isRecommendation')) {
      this.setState({ isRecommendation: nextProps.isRecommendation });
    }
  }
  componentDidMount() {
    if (window.location.search.includes('following=true')) {
      this.setState({ isRecommendation: false });
    }
  }

  render() {
    let {
      avatar,
      title,
      home,
      disableButton,
      handleClick,
      recommendLabel,
      followingLabel,
      Icon,
      followButton,
      handleFollow,
      isAlreadyFollowed,
      marginBottom,
    } = this.props;
    return (
      <div
        style={{
          height: home ? 80 : disableButton ? 164 : marginBottom ? 170 : 170,
        }}
      >
        {title && (
          <p
            style={{
              position: 'absolute',
              color: 'black',
              fontSize: 24,
              width: 210,
              left: 35,
              top: 35,
              height: 64,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {avatar && (
              <div
                style={{
                  background: 'white',
                  width: 46,
                  height: 46,
                  border: '1px solid #efefef',
                  overflow: 'hidden',
                  marginRight: 8,
                  borderRadius: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
                }}
              >
                <img style={{ width: '100%' }} src={avatar} alt="avatar" />
              </div>
            )}
            {title}
          </p>
        )}
        {Icon && (
          <div style={{ position: 'absolute', right: 30, top: home ? 32 : 40 }}>
            <Icon
              style={{ fontSize: 32, color: '#777777' }}
              onClick={() => history.push('/search')}
            />
          </div>
        )}
        {followButton && (
          <div style={{ position: 'absolute', right: 30, top: 54 }}>
            <FollowButton onClick={() => handleFollow(!isAlreadyFollowed)}>
              {isAlreadyFollowed ? '입주중' : '입주하기'}
            </FollowButton>
          </div>
        )}

        {!disableButton && (
          <div style={{ position: 'absolute', left: 35, top: home ? 27 : 120 }}>
            <Button
              selected={this.state.isRecommendation}
              onClick={() => handleClick('recommendation')}
              role="button"
            >
              {recommendLabel || '소식'}
            </Button>
            <Button
              selected={!this.state.isRecommendation}
              onClick={() => handleClick('following')}
              role="button"
            >
              {followingLabel || '이웃'}
            </Button>
          </div>
        )}
      </div>
    );
  }
}
export default UpperDeco;
