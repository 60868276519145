import React, { Component } from "react";
import styled, { css } from "styled-components";

const IconWrap = styled.i`
  svg {
    vertical-align: sub;
  }
`;

class ClickedChkIcon extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <IconWrap>
        {this.props.clicked ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <g>
              <g>
                <path
                  fill="#828ff7"
                  d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z"
                />
              </g>
              <g>
                <g />
                <g>
                  <path
                    fill="#fff"
                    d="M8.499 12.26l-2.21-2.21a.621.621 0 0 0-.883 0 .621.621 0 0 0 0 .884l2.645 2.645a.629.629 0 0 0 .89 0l5.429-5.423a.621.621 0 0 0 0-.884.621.621 0 0 0-.884 0z"
                  />
                </g>
              </g>
            </g>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <defs>
              <clipPath id="vab5a">
                <path
                  fill="#fff"
                  d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z"
                />
              </clipPath>
            </defs>
            <g>
              <g>
                <path
                  fill="#fff"
                  d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z"
                />
                <path
                  fill="none"
                  stroke="#dedede"
                  strokeMiterlimit="50"
                  strokeWidth="2"
                  d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z"
                  clipPath='url("#vab5a")'
                />
              </g>
            </g>
          </svg>
        )}
      </IconWrap>
    );
  }
}

export default ClickedChkIcon;
