import { Grid, Tabs, WhiteSpace } from "antd-mobile";
import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { Icon } from "antd";
import { observer, inject } from "mobx-react";

import LoadingSpinner from "components/loadingSpinner/loadingSpinner";
import NotiCard from "components/myNotification/notiCard";

import * as userAPI from "axios/userAPI";

const NotiCardList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 400px;
  padding: 16px;
  padding-top: 18px;
`;
const CreatedType = styled.p`
  margin: 0px;
  color: #333333;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 14px !important;
`;

@inject("userStore")
@observer
class NotificationTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: this.props.userStore.auth_token,
      notiData: false,
    };
  }
  componentWillMount() {}
  async componentDidMount() {
    const req = {
      headers: { Authorization: `Bearer ${this.state.accessToken}` },
    };
    const notiData = await Promise.all([
      userAPI.getNotify(req).then((res) => {
        return res.data;
      }),
    ]);
    let i;
    let ln = 100;
    let today = [];
    let this_week = [];
    let this_month = [];
    let before = [];

    for (i = 0; i < ln && i < notiData[0].data.length; i++) {
      if (notiData[0].data[i].created_at_type == "today") {
        today.push(notiData[0].data[i]);
      } else if (notiData[0].data[i].created_at_type == "this_week") {
        this_week.push(notiData[0].data[i]);
      } else if (notiData[0].data[i].created_at_type == "this_month") {
        this_month.push(notiData[0].data[i]);
      } else if (notiData[0].data[i]) {
        before.push(notiData[0].data[i]);
      }
    }

    this.setState({
      notiData: notiData[0].data,
      todayData: today,
      thisWeekData: this_week,
      thisMonthData: this_month,
      before,
    });
  }

  _onChange = (tab) => {
    if (tab.title == "메세지") {
      this.props.userStore.toggleNotOnService();
    }
  };

  render() {
    const tabs = [{ title: "알림" }, { title: "메세지" }];
    return (
      <Fragment>
        {this.state.notiData ? (
          <Tabs
            tabs={tabs}
            initialPage={0}
            animated={false}
            swipeable={false}
            useOnPan={false}
            tabBarTextStyle={{
              fontWeight: 700,
              fontFamily: "Noto Sans CJK KR",
              cursor: "pointer",
            }}
            tabBarActiveTextColor={"#828ff7"}
            tabBarInactiveTextColor={"#bebebe"}
            onChange={this._onChange}
            renderTabBar={(props) => (
              <Tabs.DefaultTabBar
                ref={(ref) => (this.tabBar = ref)}
                {...props}
              />
            )}
          >
            <div>
              {this.state.notiData ? (
                <NotiCardList>
                  {this.state.todayData.length > 0 && (
                    <CreatedType> 오늘 </CreatedType>
                  )}
                  {this.state.todayData.map((data, index) => {
                    return (
                      <NotiCard
                        key={index}
                        data={data}
                        accessToken={this.state.accessToken}
                      />
                    );
                  })}
                  {this.state.thisWeekData.length > 0 && (
                    <CreatedType> 이번주 </CreatedType>
                  )}
                  {this.state.thisWeekData.map((data, index) => {
                    return (
                      <NotiCard
                        key={index}
                        data={data}
                        accessToken={this.state.accessToken}
                      />
                    );
                  })}
                  {this.state.thisMonthData.length > 0 && (
                    <CreatedType> 이번달 </CreatedType>
                  )}
                  {this.state.thisMonthData.map((data, index) => {
                    return (
                      <NotiCard
                        key={index}
                        data={data}
                        accessToken={this.state.accessToken}
                      />
                    );
                  })}
                  {this.state.before.length > 0 && (
                    <CreatedType> 이전 </CreatedType>
                  )}
                  {this.state.before.map((data, index) => {
                    return (
                      <NotiCard
                        key={index}
                        data={data}
                        accessToken={this.state.accessToken}
                      />
                    );
                  })}
                </NotiCardList>
              ) : (
                <LoadingSpinner height={"250px"} />
              )}
            </div>
            <div style={{ width: "100%", minHeight: "400px" }}></div>
          </Tabs>
        ) : (
          <LoadingSpinner />
        )}
      </Fragment>
    );
  }
}

export default NotificationTabs;
