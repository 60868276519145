/* global Swiper */
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { CSSTransition } from "react-transition-group";
import LikeBtn from "components/likeBtn";
import "./productList.css";

const Layout = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  font-family: "Noto Sans CJK KR";
`;
const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
const ProductNum = styled.div`
  color: white;
  z-index: 1001;
  margin-bottom: 8px;
  font-size: 16px;
  margin-left: 16px;
`;
const ProductItem = styled.div`
  background-color: rgba(255, 255, 255, 1);
  z-index: 1001;
  // width: 100%;
  height: 100px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;
const ProductImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
`;
const ProductDesc = styled.div`
  padding: 12px;
  width: calc(100% - 100px);
`;

const ProductName = styled.p`
  font-size: 16px;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductStore = styled.p`
  font-size: 16px;
  margin-bottom: 4px;
`;

const ProductPrice = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 4px;
  flex: 1;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const priceWithComma = (number) => {
  const stringNum = String(number).split("").reverse();
  const withComma = stringNum.reduce((acc, val, i) => {
    acc.push(val);
    if (i % 3 === 2 && i + 1 < stringNum.length) acc.push(",");
    return acc;
  }, []);
  return withComma.reverse().join("");
};

export default ({
  on,
  onClose,
  items,
  className = "swiper-container-productList",
  ...props
}) => {
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (on && items.length && !swiper) {
      // 켜질 때마다 스와이퍼생성
      setSwiper(
        new Swiper(`.${className}`, {
          slidesPerView: 1.1,
          spaceBetween: 10,
          centeredSlides: true,
        })
      );
    } else if (!on && swiper) {
      // 스와이퍼 삭제
      setTimeout(() => {
        swiper.destroy();
        setSwiper(null);
      }, 100);
    }
  }, [on, items]);
  const like = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <CSSTransition in={on} timeout={300} classNames="example" unmountOnExit>
      <Layout>
        <Backdrop onClick={onClose} role="button" />
        <ProductNum>{items.length}개의 정보</ProductNum>
        <div className={`swiper-container ${className}`}>
          <div className="swiper-wrapper">
            {items.map((item) => (
              <ProductItem key={item.link} className="swiper-slide">
                <ProductImg
                  src={item.image.image}
                  role="button"
                  onClick={() => window.open(item.link)}
                />
                <ProductDesc>
                  <ProductName
                    role="button"
                    onClick={() => window.open(item.link)}
                  >
                    {item.name}
                  </ProductName>
                  <ProductStore>{item.store}</ProductStore>
                  <FlexRow>
                    <ProductPrice>{priceWithComma(item.price)}</ProductPrice>
                    <LikeBtn
                      is_liked={item.like.is_liked}
                      id={item.id}
                      option="item"
                      marginRight="4px"
                      doAfterClick={(like) => {
                        item.like.is_liked = like;
                      }}
                    />
                  </FlexRow>
                </ProductDesc>
              </ProductItem>
            ))}
          </div>
        </div>
      </Layout>
    </CSSTransition>
  );
};
