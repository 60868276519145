import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import BaseIcon from 'components/myIcons/baseIcon';
import TopicIcon from 'components/myIcons/topicIcon';
import PollIcon from 'components/myIcons/pollIcon';
import MissionIcon from 'components/myIcons/missionIcon';
import QnaIcon from 'components/myIcons/qnaIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 6,
    marginRight: 12,
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20%',
    marginBottom: 4,
  },
  circle: {
    borderRadius: '100%',
    border: '1px solid transparent',
    background: 'white',
    width: 45,
    height: 45,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 6,
  },
  selectedCircle: { border: '1px solid #5773ff' },
  text: { fontSize: 13, fontWeight: 'bold' },
  selectedText: { color: '#5773ff' },
  icon: { width: 24, height: 24, fill: '#454f63' },
  selectedIcon: { fill: '#5773ff' },
  formControl: {
    minWidth: 60,
  },
});
const TYPES = [
  { Icon: BaseIcon, text: '전체', color: '#5574f7' },
  { Icon: BaseIcon, text: '일반', color: '#5574f7' },
  { Icon: PollIcon, text: '투표', color: '#3adbe1' },
  { Icon: QnaIcon, text: 'Q&A', color: '#ffb900' },
  { Icon: MissionIcon, text: '미션', color: '#ff854f' },
  { Icon: TopicIcon, text: '토픽', color: '#854fff' },
];
const PostTypeSelect = ({ type, handleSelect }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <Select
          native
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          disableUnderline
          value={type}
          onChange={(e) => handleSelect(e.target.value)}
        >
          {TYPES.map(({ Icon, text, color }, i) => (
            <option value={text} key={text}>
              {text}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default PostTypeSelect;
