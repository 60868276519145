import React, { Fragment } from "react";
import Beforeunload from "react-beforeunload";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";
import { animateScroll as scroll } from "react-scroll";

import { Wrap, IndexWrap } from "components/style/custom-styled-components";
import VideoInput from "components/videoInput";
import ItemLinkAddComponent from "components/itemLinkInput/itemLinkAddComponent";

import * as postAPI from "axios/postAPI";
import * as userAPI from "axios/userAPI";
import history from "utils/history";

import back_ico from "images/back_ico_b.svg";

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  line-height: 56px;
  height: 44px;
  line-height: 44px;
  width: 100%;
  max-width: 1024px;
  z-index: 3;
  top: 0;
  background: rgb(248, 248, 248);
`;
const ContentWrap = styled.div`
  padding: 10px 16px;
  padding-bottom: 70px;
  color: #333333;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 700;
  p {
    margin-bottom: 10px;
  }
  span.sub-btn {
    float: right;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }
  div {
    position: relative;
  }
  input::placeholder {
    color: #bebebe;
    font-family: "Noto Sans CJK KR";
    font-size: 14px;
    font-weight: 500;
  }
`;
const VideoInputWrap = styled.div`
  width: 100%;
  max-width: 1024px;
  border-radius: 6px;
  padding-bottom: 36px;
  display: flex;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none !important;
  }
`;

const UpdateInput = styled.input`
  width: 100%;
  height: 44px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  border: none;
  margin-bottom: 36px;
  padding: 0px 16px;
  color: #333333;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
`;

const SubmitWrap = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 10px 16px;
  position: fixed !important;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;
const SubmitBtn = styled.button`
  border: none;
  width: 100%;
  height: 44px;
  box-shadow: 0 10px 20px rgba(31, 117, 254, 0.3);
  background: #1f75fe;
  border-radius: 68px;
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.disabled &&
    css`
      background-image: none;
      background-color: rgb(150, 150, 150);
    `}
`;

const ToSampleBtn = styled.button`
  color: #828ff7;
  font-family: "Noto Sans CJK KR";
  font-size: 15px;
  font-weight: 700;
  border: none;
  background-color: transparent;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
const Title = styled.p`
  margin-top: 2px;
  font-family: "Noto Sans CJK KR";
  font-size: 15px;
  font-weight: 700;
`;
const CategoryList = styled.div`
  margin-bottom: 36px;
`;
const Category = styled.button`
  height: 29px;
  border-radius: 50px;
  background-color: #eeeeee;
  opacity: 0.78;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  border: none;

  ${(props) =>
    props.selected &&
    css`
      background-color: #828ff7;
      color: white;
    `}
`;

@inject("postStore")
@inject("userStore")
@observer
class CreatePostPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoInputArr: [<Fragment key={0} />],
      videoLen: this.props.postStore.videoLen,
      videoFiles: this.props.postStore.addedVideoArr,
      linkArr: this.props.postStore.createPost.links
        ? this.props.postStore.createPost.links
        : [],
      linkNum: this.props.postStore.createPost.linkNum
        ? this.props.postStore.createPost.linkNum
        : 1,
      tags: this.props.postStore.createPost.tags
        ? this.props.postStore.createPost.tags
        : undefined,
      title: this.props.postStore.createPost.title
        ? this.props.postStore.createPost.title
        : undefined,
      profile: this.props.postStore.createPost.profile
        ? this.props.postStore.createPost.profile
        : undefined,
      deleteMode: false,
      selected: null,
      link: "",
      shop: "",
    };
    if (this.props.selectedProduct) {
      this.state = {
        ...this.state,
        ...this.props.selectedProduct,
        selected: this.props.selectedProduct.label,
      };
    }
  }
  async componentWillMount() {
    if (!this.props.userStore.auth_token) history.push("/login");
    else if (!this.props.postStore.createPost.profile) {
      const req = {
        headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
      };
      const profile = await userAPI.getMyUserProfile(req).then((res) => {
        return res.data;
      });
      this.setState({
        profile: profile,
      });
    }
  }
  onBack = () => {
    this.props.onBack();
  };
  componentDidMount() {
    let videoInputArr = [];
    for (var i = 0; i < this.state.videoLen; i++) {
      const video = this.props.postStore.addedVideoArr[i]
        ? this.props.postStore.addedVideoArr[i].video
        : undefined;
      videoInputArr.push(
        <VideoInput
          key={i}
          index={i}
          onChange={this.handleInput}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
    }
    this.setState({
      videoInputArr: videoInputArr,
    });
    window.addEventListener("popstate", this.onBack);
  }
  componentWillUnmount() {
    this.props.postStore.createPost = {
      title: this.state.title,
      tags: this.state.tags,
      links: this.state.linkArr,
      linkNum: this.state.linkNum,
      profile: this.state.profile,
    };
    window.removeEventListener("popstate", this.onBack);
  }
  handleInput = (event) => {
    const eName = event.target.name;
    if (eName == "tags") {
      var special_pattern = /[`~!@#$%^&*|\\\'\";:\/?]/gi;
      if (special_pattern.test(event.target.value) == true) {
        alert("태그에 특수문자는 입력할 수 없습니다.");
        this.setState({
          [eName]: this.state.tags ? this.state.tags : "",
        });
      } else {
        this.setState({ [eName]: event.target.value });
      }
    } else {
      this.setState({ [eName]: event.target.value });
    }
  };
  addVideoInput = (video) => {
    const videoDict = {
      id: undefined,
      video: video,
    };
    this.props.postStore.addVideo(video);
    let videoInputArr = [];
    for (var i = 0; i < this.props.postStore.videoLen; i++) {
      const video = this.props.postStore.addedVideoArr[i]
        ? this.props.postStore.addedVideoArr[i].video
        : undefined;
      videoInputArr.push(
        <VideoInput
          key={i}
          index={i}
          onChange={this.handleInput}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
    }
    this.setState({
      videoInputArr: videoInputArr,
      videoFiles: this.props.postStore.addedVideoArr,
      videoLen: this.props.postStore.videoLen,
    });
  };
  delVideo = (index) => {
    this.props.postStore.delVideo(index);
    let videoInputArr = [];
    for (var i = 0; i < this.props.postStore.videoLen; i++) {
      const video = this.props.postStore.addedVideoArr[i]
        ? this.props.postStore.addedVideoArr[i].video
        : undefined;
      videoInputArr.push(
        <VideoInput
          key={i}
          index={i}
          onChange={this.handleInput}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
    }
    this.setState({
      videoInputArr: videoInputArr,
      videoFiles: this.props.postStore.addedVideoArr,
      videoLen: this.props.postStore.videoLen,
    });
  };
  handleAddLink = () => {
    this.setState(
      {
        linkNum: this.state.linkNum + 1,
      },
      () => {
        scroll.scrollToBottom({
          duration: 500,
          delay: 0,
          smooth: true,
        });
      }
    );
  };
  toggleLinkDelete = () => {
    this.setState({
      deleteMode: !this.state.deleteMode,
    });
  };
  handleAddLinkArr = (id, data) => {
    const { linkArr } = this.state;
    let isNew = true;
    for (var i = 0; i < this.state.linkArr.length; i++) {
      if (this.state.linkArr[i].id == id) {
        isNew = false;
        break;
      }
    }
    if (isNew) {
      const { linkArr } = this.state;
      this.setState({
        linkArr: linkArr.concat({ id: id, data }),
      });
    } else {
      this.setState({
        linkArr: linkArr.map(
          (arr) =>
            id === arr.id
              ? { ...arr, data } // 새 객체를 만들어서 기존의 값과 전달받은 data 을 덮어씀
              : arr // 기존의 값을 그대로 유지
        ),
      });
    }
  };
  handleRemoveLinkArr = (id) => {
    const { linkArr } = this.state;
    this.setState({
      linkArr: linkArr.filter((arr) => arr.id !== id),
    });
  };
  formSubmit = async () => {
    if (this.props.postStore.addedVideoArr.length == 0) {
      alert("영상을 하나 이상 업로드해야 합니다");
    } else if (!this.state.title) {
      alert("제목을 입력해 주세요");
    } else if (!this.state.tags) {
      alert("태그를 입력해 주세요");
    } else {
      this.props.postStore.setLinkLoading(true);
      const tags = this.state.tags.replace(/ /gi, "").split(",");

      // 스타일 태그 추가
      // if (tags.indexOf('스타일') < 0) {
      // 	tags.unshift('스타일');
      // }

      let links = [];
      for (var i = 0; i < this.state.linkArr.length; i++) {
        links.push(this.state.linkArr[i].data);
      }
      const req = {
        data: {
          title: this.state.title,
          tags: tags,
          links: links,
          video: this.props.postStore.addedVideoArr,
        },
        headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
      };
      await postAPI
        .createPost(req)
        .then(() => {
          window.location.href = "/following";
          this.props.postStore.setLinkLoading(false);
        })
        .catch((e) => {
          alert(e);
          this.props.postStore.setLinkLoading(false);
          window.location.href = "/following";
        });
    }
  };
  render() {
    let videoInputArr = [];
    for (var i = 0; i < this.state.videoLen; i++) {
      const video = this.props.postStore.addedVideoArr[i]
        ? this.props.postStore.addedVideoArr[i].video
        : undefined;
      videoInputArr.push(
        <VideoInput
          key={i}
          index={i}
          onChange={this.handleInput}
          addVideoInput={this.addVideoInput}
          video={video}
          delVideo={this.delVideo}
        />
      );
    }
    return (
      <Beforeunload onBeforeunload={() => "변경 사항이 저장되지 않습니다."}>
        <Wrap style={{ paddingBottom: 0, backgroundColor: "rgb(248,248,248)" }}>
          <IndexWrap style={{ paddingTop: 44, backgroundColor: "#f8f8f8" }}>
            <NavBar>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  position: "absolute",
                }}
              >
                <Title>상품 정보</Title>
              </div>
              <div style={{ width: "50%", zIndex: 1 }}>
                <img
                  src={back_ico}
                  onClick={this.props.onBack}
                  style={{
                    marginLeft: 19,
                    width: 19,
                    verticalAlign: "middle",
                    cursor: "pointer",
                  }}
                />
              </div>
            </NavBar>
            <ContentWrap>
              {/* <p>상품 종류</p>
              <CategoryList>
                {this.props.categoryItems.map((text) => {
                  return (
                    <Category
                      key={text}
                      onClick={() => {
                        this.setState({ selected: text });
                      }}
                      selected={this.state.selected === text}
                    >
                      {text}
                    </Category>
                  );
                })}
              </CategoryList> */}
              <p>정보 등록</p>
              <div>
                <UpdateInput
                  name="shop"
                  value={this.state.shop}
                  onChange={this.handleInput}
                  placeholder="제목을 입력해주세요."
                />
              </div>
              <p>링크 등록 (선택)</p>

              <div>
                <UpdateInput
                  name="link"
                  value={this.state.link}
                  onChange={this.handleInput}
                  placeholder="링크를 입력해주세요."
                />
              </div>
              <SubmitWrap>
                <SubmitBtn
                  onClick={() => {
                    // if (!this.state.selected) {
                    // 	alert('선택이 잘못되었습니다.');
                    // } else

                    if (this.state.shop === "") {
                      alert("구매처를 입력하여주세요.");
                    } else if (
                      this.state.link !== "" &&
                      this.state.link.indexOf("http") !== 0
                    ) {
                      alert("상품 링크를 바르게 입력하여 주십시요.");
                    } else
                      this.props.onSubmit({
                        label: this.state.selected,
                        shop: this.state.shop,
                        link: this.state.link,
                      });
                  }}
                >
                  완료
                </SubmitBtn>
              </SubmitWrap>
            </ContentWrap>
          </IndexWrap>
        </Wrap>
      </Beforeunload>
    );
  }
}
export default CreatePostPage;
