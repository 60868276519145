import React from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import DetailVideo from 'components/detailVideo';
import {
	BlackWrap,
	IndexWrap,
} from 'components/style/custom-styled-components';
import * as postAPI from 'axios/postAPI';
import history from 'utils/history';

const VideoWrap = styled.div`
	position: relative;
	height: 100vh;
	top: 0px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`;

@inject('userStore')
@inject('postStore')
@observer
class PostDetail extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = true;
		this.myWrap = React.createRef();
		this.state = {
			postData: {
				video: [],
				profile: {},
				comment: { count: 0 },
				like: { count: 0 },
				item: [],
				tag: [],
			},
			playFault: true,
			prgBarWidth: [
				{
					id: 0,
					width: '0%',
					currentDuration: 0,
				},
			],
			popOver: false,
			vi: -1,
			videoEl: undefined,
		};
	}
	async componentDidMount() {
		window.test = this.props.setPrgBarWidth;
		const { params } = this.props;
		const req = {
			id: params.id,
			headers: this.props.userStore.auth_token
				? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
				: undefined,
		};
		let postData = await postAPI
			.getPost(req)
			.then(res => {
				return res.data;
			})
			.catch(e => {
				if (e.response.data.detail == '찾을 수 없습니다.') {
					postData = false;
					alert('해당 포스팅은 삭제되었습니다.');
					history.push('/');
				}
			});

		if (postData) {
			if (this._isMounted) {
				this.setState(
					{
						postData,
						vi: 0,
						spinner: false,
					},
					this.initPrgBarWidth(postData.video.length),
				);
				this.props.updatePostsMap(this.props.params.id, postData);
			}
		}
	}
	initPrgBarWidth = length => {
		let arr = [];
		for (var i = 0; i < length; i++) {
			arr.push({
				id: i,
				width: '0%',
				currentDuration: 0,
			});
		}
		this.setState({
			prgBarWidth: arr,
		});
	};

	handlePlayFault = t => {
		this.setState({
			playFault: t,
		});
	};
	setCurrentVideo = v => {
		this.setState({
			videoEl: v,
		});
	};

	setVideoIndex = () => {
		this.setState({
			vi: (this.state.vi + 1) % this.state.postData.video.length,
		});
	};

	nextVideo = () => {
		this.setState({
			vi: (this.state.vi + 1) % this.state.postData.video.length,
		});
	};
	prevVideo = () => {
		let vi = this.state.vi - 1;
		if (vi < 0) vi = 0;
		this.setState({
			vi,
		});
	};
	render() {
		const videoLen = this.state.postData.video.length;

		return (
			<BlackWrap ref={this.myWrap}>
				<IndexWrap style={{ height: '100%' }}>
					<VideoWrap className="profileImg">
						<DetailVideo
							videoPrefix={this.props.videoPrefix}
							videoLen={videoLen}
							data={this.state.postData}
							videoData={this.state.postData.video[this.state.vi]}
							index={this.state.vi}
							nextVideo={this.nextVideo}
							prevVideo={this.prevVideo}
							onClick={this.props.onClick}
							setPrgBarWidth={
								// () => {}
								// 현재 비디오일때만 프로그레스바 제어
								this.props.active ? this.props.setPrgBarWidth : () => {}
							}
							handlePlayFault={this.handlePlayFault}
							setCurrentVideo={this.setCurrentVideo}
							active={this.props.active}
							muted={this.props.muted}
							thumbnail={this.props.thumbnail}
							onPlay={this.props.onPlay}
						/>
					</VideoWrap>
				</IndexWrap>
			</BlackWrap>
		);
	}
}
export default PostDetail;
