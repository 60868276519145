import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";

import ClickedChkIcon from "components/myIcons/clickedChkIcon";

import * as userAPI from "axios/userAPI";
import history from "utils/history";

const Wrapper = styled.div`
  display: none;
  width: 100%;
  max-width: 480px;
  height: 100vh;
  top: 0px;
  z-index: -1;
  position: fixed;
  ${(props) =>
    props.active &&
    css`
      display: flex;
      align-items: center;
      z-index: 1002;
    `}
`;
const Modal = styled.div`
  min-width: 295px;
  max-width: 400px;
  margin: auto;
  height: 176px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  background-color: #ffffff;
  z-index: 1003;
`;
const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 120px;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #ebebeb;
  p {
    color: #333333;
    font-family: "Noto Sans CJK KR";
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0px;
  }
`;

const ModalFooter = styled.div`
  display: flex;
  text-align: center;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 700;
  color: #333333;
  span {
    display: inline-block;
    width: 50%;
    height: 55px;
    line-height: 55px;
  }
`;

const ModalItem = styled.p`
  width: 100%;
  padding: 18px 16px;
  text-align: center;
  color: #333333;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  cursor: pointer;
  ${(props) =>
    props.id == props.clicked &&
    css`
      color: #828ff7;
    `}
`;
const OutSideWrap = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0px;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
`;

@inject("userStore")
@observer
class PostDeleteModal extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <Wrapper active={this.props.open}>
        <OutSideWrap onClick={this.props.handleClose} />
        <Modal active={this.props.open} bottom={this.props.bottom}>
          <TitleWrap>
            <p style={{ marginBottom: 10 }}> 정말 삭제하시겠어요? </p>
            <p style={{ fontWeight: 500 }}>
              {" "}
              삭제한 댓글은 복구할 수 없습니다.{" "}
            </p>
          </TitleWrap>
          <ModalFooter>
            <span onClick={this.props.handleClose}>취소</span>
            <span
              style={{ color: "#f85e3a", borderLeft: "solid 1px #ebebeb" }}
              onClick={this.props.handleDelete}
            >
              {" "}
              삭제{" "}
            </span>
          </ModalFooter>
        </Modal>
      </Wrapper>
    );
  }
}

export default PostDeleteModal;
