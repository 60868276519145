import React from 'react';
import styled, { css } from 'styled-components';
import { TabBar } from 'antd-mobile';
import { Icon } from 'antd';
import { observer, inject } from 'mobx-react';

import MountainIcon from 'components/myIcons/mountainIcon';
import ProfileIcon from 'components/myIcons/profileIcon';
import CompassIcon from '@material-ui/icons/Explore';
import NotiIcon from '@material-ui/icons/Notifications';

import history from 'utils/history';

const PostTabBtn = styled.div`
  box-shadow: 0 2px 10px rgba(31, 117, 254, 0.5);
  background-image: linear-gradient(
    180deg,
    rgb(31, 117, 254) 0%,
    rgb(31, 117, 254) 100%
  );
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  margin-top: 1px;
  ${(props) =>
    props.disabled &&
    css`
      box-shadow: none;
      background-image: linear-gradient(180deg, #eeeeee 0%, #eeeeee 100%);
    `}
`;
const FooterWrap = styled.div`
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  position: fixed;
  height: 62px !important;
  bottom: 0px;
  width: 100%;
  max-width: 1024px;
`;

@inject('userStore')
@observer
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'homeTab',
      hidden: false,
      fullScreen: false,
    };
  }
  componentDidMount() {
    if (window.location.pathname == '/') {
      this.setState({
        selectedTab: 'homeTab',
      });
    } else if (window.location.pathname.includes('/sum/')) {
      this.setState({
        selectedTab: 'sumTab',
      });
    } else if (window.location.pathname.includes('/search')) {
      this.setState({
        selectedTab: 'searchTab',
      });
    } else if (window.location.pathname.includes('/notification')) {
      this.setState({
        selectedTab: 'notiTab',
      });
    } else if (window.location.pathname.includes('/my-page')) {
      this.setState({
        selectedTab: 'userTab',
      });
    } else if (window.location.pathname.includes('/explore')) {
      this.setState({
        selectedTab: 'exploreTab',
      });
    }
  }

  handleRequiredLoginNav = (path) => {
    if (this.props.userStore.getTokenOrToggleLogin()) {
      history.push(path);
    }
  };
  render() {
    const { selectedTab } = this.state;
    const isHome = selectedTab === 'homeTab';

    return (
      <FooterWrap style={this.props.style}>
        <TabBar barTintColor={`rgba(255,255,255,1)`}>
          {/*1*/}
          <TabBar.Item
            key="Life"
            icon={<MountainIcon opacity={0.2} />}
            selectedIcon={<MountainIcon opacity={'.7'} />}
            selected={window.location.pathname === '/'}
            onPress={() => {
              if (isHome) {
                window.location.href = '/';
              } else {
                history.push('/');
              }
            }}
          ></TabBar.Item>
          <TabBar.Item
            key="Explore"
            icon={<CompassIcon style={{ fontSize: 28 }} opacity={0.4} />}
            selectedIcon={
              <CompassIcon
                opacity={'.7'}
                style={{ fontSize: 28, color: 'rgba(0, 0, 0, 0.65)' }}
              />
            }
            selected={window.location.pathname === '/event-list'}
            onPress={() => {
              history.push('/event-list');
            }}
          ></TabBar.Item>

          <TabBar.Item
            icon={
              <PostTabBtn>
                <Icon
                  type="plus"
                  style={{
                    fontSize: 15,
                    color: 'rgb(255,255,255)',
                    verticalAlign: 'text-top',
                    height: 35,
                    width: 35,
                    lineHeight: '35px',
                  }}
                />
              </PostTabBtn>
            }
            selectedIcon={
              <PostTabBtn>
                <Icon
                  type="plus"
                  style={{
                    fontSize: 15,
                    color: 'rgb(255,255,255)',
                    verticalAlign: 'text-top',
                    height: 35,
                    width: 35,
                    lineHeight: '35px',
                  }}
                />
              </PostTabBtn>
            }
            key="Post"
            selected={this.state.selectedTab === 'sumTab' || this.props.sumId}
            onPress={() => {
              if (this.state.selectedTab === 'sumTab' || this.props.sumId) {
                this.props.onClickAdd();
              } else {
                history.push('/new-post');
              }
            }}
          ></TabBar.Item>
          <TabBar.Item
            key="Noti"
            icon={<NotiIcon opacity={0.4} style={{ fontSize: 28 }} />}
            selectedIcon={
              <NotiIcon
                opacity={0.7}
                style={{ fontSize: 28, color: 'rgba(0, 0, 0, 0.65)' }}
              />
            }
            selected={window.location.pathname === '/notification'}
            onPress={() => {
              this.handleRequiredLoginNav('/notification');
            }}
          ></TabBar.Item>
          <TabBar.Item
            icon={<ProfileIcon style={{}} opacity={0.2} />}
            selectedIcon={<ProfileIcon opacity={0.7} />}
            key="User"
            selected={this.state.selectedTab === 'userTab'}
            onPress={() => {
              this.handleRequiredLoginNav('/my-page');
            }}
          ></TabBar.Item>
        </TabBar>
      </FooterWrap>
    );
  }
}
export default Footer;
