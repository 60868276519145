import React, { Fragment } from 'react';
import ItemLinkInput from './itemLinkInput';
import ItemLinkInputEditMode from './itemLinkInputEditMode';
import ItemLinkInputContainer from './itemLinkInputContainer';

export default class ItemLinkAddComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const children = [];
		if (this.props.mode == 'edit') {
			for (var i = 0; i < this.props.linkNum; i++) {
				children.push(
					<ItemLinkInputEditMode
						isGetLink={this.props.isGetLink}
						key={i}
						number={i}
						handleAddLinkArr={this.props.handleAddLinkArr}
						handleRemoveLinkArr={this.props.handleRemoveLinkArr}
						deleteMode={this.props.deleteMode}
					/>,
				);
			}
		} else {
			for (var i = 0; i < this.props.linkNum; i++) {
				children.push(
					<ItemLinkInput
						key={i}
						number={i}
						handleAddLinkArr={this.props.handleAddLinkArr}
						handleRemoveLinkArr={this.props.handleRemoveLinkArr}
						deleteMode={this.props.deleteMode}
					/>,
				);
			}
		}
		return <ItemLinkInputContainer>{children}</ItemLinkInputContainer>;
	}
}
