import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import greyPath from 'images/grey-left.png';
import parseLink from 'utils/parseLink';
import parser from 'html-react-parser';

const useStyles = makeStyles({
  layout: {
    position: 'relative',
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  path: {
    top: 2,
    position: 'absolute',
    width: 24,
  },
  root: {
    fontSize: 14,
    borderRadius: 16,
    // background: "rgba(0,0,0,0.04)",
    // color: "black",
    padding: '6px 6px',
    marginBottom: 12,
    display: 'inline-block',
  },

  oneLine: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
  },
  primary: {
    fontSize: 14,
    borderRadius: 16,
    background: '#eeeeee',
    width: '100%',
    color: 'black',
    padding: 16,
    '& > span': {
      color: 'white !important',
    },
    '& > span > div > a': {
      color: 'white !important',
    },
  },
  white: {
    '& > span': {
      color: 'white',
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  folded: {
    maxHeight: '64px',
    overflow: 'hidden',
    // line-
  },
  parsed: {
    '& > a': {
      color: 'white',
      fontWeight: 'bold',
    },
  },
  more: {
    position: 'absolute',
    right: 17,
    width: 54,
    bottom: 28,
    background: '#5887F9',
  },
});

const Comment = ({ writer, text, primary, maxLine, ...props }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.layout)} {...props}>
      <img className={clsx(classes.path)} src={greyPath} />
      <div className={clsx(classes.root, classes.primary)}>
        아직 팔로우 하고 있는 이웃이 없네요.
        <br />
        다양한 섬들을 둘러보고 이웃을 추가해 보세요.
        <br />
        <strong onClick={() => (window.location.href = '/explore')}>
          섬 둘러보기
        </strong>
      </div>
    </div>
  );
};
export default Comment;
