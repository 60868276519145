import React from 'react';
import styled, { css } from 'styled-components';

const Backdrop = styled.div`
	width: 100%;
	height: 100%;
	z-index: 999;
	top: 0;
	bottom: 0;
	position: fixed;
	background: transparent;
	${props =>
		props.active &&
		css`
			z-index: 1002;
			background-color: rgba(0, 0, 0, 0.5);
			transition: 0.2s background-color ease;
		`}
`;

export default ({ handleClose, open }) => {
	if (!open) return null;
	return <Backdrop active={open} onClick={handleClose} />;
};
