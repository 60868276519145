import moment from 'moment';

export default (date = new Date()) => {
  const m = moment(date);
  m.set('hours', 0);
  m.set('minutes', 0);
  m.set('seconds', 0);
  m.set('millisecond', 0);
  return m;
};
