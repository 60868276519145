import React from 'react';

import SpinnerCurtain from 'components/loadingSpinner/spinnerCurtain';
import LikableItem from 'components/likableItem';

const NOT_CRAWLED_ITEM_NAME = '클릭시 상품 링크로 이동합니다.';
class ItemResultList extends React.Component {
	render() {
		return (
			<div>
				{!this.props.itemData ? (
					<SpinnerCurtain fixed />
				) : (
					<LikableItem
						data={this.props.itemData
							.reduce((acc, val) => {
								acc = acc.concat(val.item);
								return acc;
							}, [])
							.filter(({ name }) => name !== NOT_CRAWLED_ITEM_NAME)}
						backgroundColor="rgb(248,248,248)"
						marginTop="0px"
					/>
				)}
			</div>
		);
	}
}
export default ItemResultList;
