import images from 'images/category';
export default {
	fashion: [
		{
			label: '아우터',
			src: images.fashion.outer,
		},
		{
			label: '상의',
			src: images.fashion.upper,
		},
		{
			label: '바지',
			src: images.fashion.pant,
		},
		{
			label: '스커트',
			src: images.fashion.skirt,
		},
		{
			label: '원피스',
			src: images.fashion.one,
		},
		{
			label: '신발',
			src: images.fashion.shoe,
		},
		{
			label: '가방',
			src: images.fashion.back,
		},
		{
			label: '악세서리',
			src: images.fashion.acc,
		},
		{
			label: '기타',
			src: images.fashion.etc,
		},
	],
	beauty: [
		{
			label: '기초',
			src: images.beauty.basic,
		},
		{
			label: '베이스',
			src: images.beauty.base,
		},
		{
			label: '아이',
			src: images.beauty.eye,
		},
		{
			label: '립',
			src: images.beauty.lib,
		},
		{
			label: '블러셔',
			src: images.beauty.blu,
		},
		{
			label: '클렌징',
			src: images.beauty.clean,
		},
		{
			label: '네일',
			src: images.beauty.nail,
		},
		{
			label: '도구&툴',
			src: images.beauty.tool,
		},
		{
			label: '기타',
			src: images.beauty.etc,
		},
	],
	hair: [
		{
			label: '에센스',
			src: images.hair.essence,
		},
		{
			label: '케어',
			src: images.hair.cair,
		},
		{
			label: '스타일링',
			src: images.hair.style,
		},
		{
			label: '염색&펌',
			src: images.hair.color,
		},
		{
			label: '헤어기기',
			src: images.hair.hair,
		},
		{
			label: '샵',
			src: images.hair.shop,
		},
		{
			label: '기타',
			src: images.hair.etc,
		},
	],
};
