import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';

const Button = styled.button`
	background-color: transparent;
	border: none;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	margin-right: 4px;
	margin-left: 4px;
	padding-right: 0px;
	padding-left: 0px;
	${(props) =>
		props.selected &&
		css`
			color: #828ff7;
			border-bottom: 2px solid #828ff7;
		`}
`;
const Layout = styled.div`
	margin-top: 12px;
	margin-bottom: 12px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	${(props) =>
		props.spaceAround &&
		css`
			justify-content: space-around;
		`}
	${(props) =>
		props.border &&
		css`
			border-bottom: 1px solid #efefef;
		`}
`;
function a11yProps(index) {
	return {
		id: `scrollable-prevent-tab-${index}`,
		'aria-controls': `scrollable-prevent-tabpanel-${index}`,
	};
}
const useStyles = makeStyles({
	tab: {
		color: '#333333',
		fontFamily: 'Noto Sans CJK KR',
		fontSize: 16,
		fontWeight: 500,
	},
	center: {
		justifyContent: 'space-around',
	},
	indicator: {
		background: '#828ff7',
	},
});
export default ({ list, selected, onClick, center }) => {
	const classes = useStyles();

	return (
		<Tabs
			classes={center && { flexContainer: classes.center }}
			value={selected}
			variant="scrollable"
			scrollButtons="off"
			aria-label="scrollable prevent tabs example"
			TabIndicatorProps={{ className: classes.indicator }}
		>
			{list.map((item, i) => (
				<Tab
					className={classes.tab}
					key={item}
					aria-label={item}
					onClick={() => onClick(i)}
					{...a11yProps(i)}
					label={item}
					disableRipple
					disableTouchRipple
				/>
			))}
		</Tabs>
	);
};
