import React, { Component } from "react";

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 18 18"
    {...props}
  >
    <g>
      <g>
        <path
          fill="#"
          d="M9.9 3.6v4.5h4.5a.901.901 0 0 1 0 1.8H9a.901.901 0 0 1-.9-.9V3.6a.901.901 0 0 1 1.8 0zM18 9c0-4.963-4.041-9-9.009-9C4.033 0 0 4.037 0 9s4.033 9 8.991 9C13.959 18 18 13.963 18 9z"
        />
      </g>
    </g>
  </svg>
);
