import React, { Fragment } from "react";
import { animateScroll as scroll } from "react-scroll";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";
import "rmc-picker/assets/index.css";
import "rmc-picker/assets/popup.css";
import Picker from "rmc-picker";
import Popup from "rmc-picker/lib/Popup";
import moment from "moment";
import { Wrap, IndexWrap } from "../components/style/custom-styled-components";

import * as userAPI from "axios/userAPI";
import history from "utils/history";
import getDaysInMonth from "utils/myDateRange";

import default_profile from "images/default_profile.png";
import imgAdd from "images/imgAdd.svg";
import agreeChk from "images/agree_chk.svg";
import back_ico from "images/back_ico_w.svg";

const MainWrap = styled.div`
  position: relative;
  margin-bottom: 40px;
  width: 100%;
  min-height: 262px;
  height: 42vh;
  background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
`;
const MainWrapBottom = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 13%;
  background-image: linear-gradient(
    to top,
    rgb(248, 248, 248) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;
const NavBar = styled.div`
  width: 100%;
  height: 64px;
  padding: 17px 19px;
`;

const ProfileImgUp = styled.div`
  position: relative;
  width: 104px;
  height: 104px;
  box-shadow: 0 2px 10px rgba(130, 143, 247, 0.4);
  background-color: #f8f8f8;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  input {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
  }
  label {
    cursor: pointer;
    height: 28px;
    width: 75px;
  }
  div.profile-image {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    ${(props) =>
      props.isDefaultProfileImg &&
      css`
        margin: auto;
        width: 42px;
        height: 42px;
        border-radius: 0;
      `}
  }
`;
const ImgBtn = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 32px;
  height: 32px;
  box-shadow: 0 2px 5px rgba(130, 143, 247, 0.4);
  background-color: #ffffff;
  border-radius: 50%;
  :hover {
    opacity: 0.7;
  }
`;
const Dot = styled.div`
  position: absolute;
  top: 13.5px;
  left: 13px;
  width: 6px;
  height: 6px;
  background-color: #999999;
  border-radius: 50%;
`;
const InputWrap = styled.div`
  position: relative;
  padding: 0px 16px;
  margin-top: -110px;
  p.signup-nickname {
    color: #ffffff;
    font-family: "Noto Sans CJK KR";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 24px;
    margin-bottom: 10px;
  }
  p.signup-inputname {
    color: #333333;
    font-family: "Noto Sans CJK KR";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 32px;
    margin-bottom: 10px;
  }
  p.signup-note {
    color: #bebebe;
    font-family: "Noto Sans CJK KR";
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 10px;
  }
  input {
    border: none;
    width: 100%;
    height: 44px;
    box-shadow: 0 1px 5px rgba(130, 143, 247, 0.2);
    border-radius: 8px;
    background-color: #ffffff;
    padding: 11px 16px;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #bebebe;
    }
  }
`;
const DropDownWrap = styled.div`
  display: flex;
  span {
    width: 100%;
    flex: 1 1 auto;
    margin-left: 8px;
  }
  span:first-child {
    margin-left: 0px;
  }
`;
const MyDropDown = styled.button`
  position: relative;
  width: 100%;
  height: 44px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ffffff;
  border: none;

  color: #333333;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  padding: 11px 16px;
  text-align: left;
`;
const IconWrapper = styled.i`
  z-index: 0;
  position: absolute;
  right: 16px;
  top: 12px;
  span {
    margin-left: 11px;
    vertical-align: text-bottom;
    font-style: normal;
    font-weight: 500;
    color: rgb(172, 172, 172);
    font-family: "Noto Sans CJK KR";
    font-size: 14px;
  }
`;

const AgreeBox = styled.div`
  width: 100%;
  position: relative;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 21px;
  padding: 18px 19px;
  img {
    vertical-align: baseline !important;
    margin-right: 11px;
    :hover {
      opacity: 0.8;
    }
  }
  p {
    color: #333333;
    font-family: "Noto Sans CJK KR";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
  }
  span.agree-terms {
    position: absolute;
    right: 17px;
    color: #828ff7;
    font-family: "Noto Sans CJK KR";
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }
`;
const AgreeDot = styled.span`
  border-radius: 50px;
  width: 15px;
  height: 15px;
  vertical-align: text-top;
  margin-right: 11px;
  background: #999999;
  display: inline-block;
`;
const AgreeBtn = styled.button`
  border: none;
  margin-bottom: 16px;
  width: 100%;
  height: 44px;
  box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
  border-radius: 68px;
  background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
const ErrorMsg = styled.p`
  height: 26px;
  border: 1px solid rgba(0, 0, 0, 0);
  color: #f85e3a;
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
`;

const BirthPlaceholder = styled.div`
  position: absolute;
  color: #bebebe;
  padding-top: 14px;
  top: 0px;
`;

var currentTime = moment();

@inject("userStore")
@observer
class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_updated: true,
      years: [],
      yearVal: currentTime.year(),
      monthVal: "",
      dayVal: "",
      accessToken: this.props.userStore.auth_token,
      service_agree: true,
      privacy_agree: true,
      nameForm: "",
      profileImg: null,
      profileImgURL: null,
      nicknameValid: true,
      gender: "f",
    };
    this.years = [];
  }
  async componentWillMount() {
    if (this.props.userStore.auth_token) {
      const req = {
        headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
      };
      const profile = await Promise.all([
        userAPI
          .getMyUserProfile(req)
          .then((res) => {
            return res.data;
          })
          .catch((e) => {
            return false;
          }),
      ]);
      if (profile[0].is_updated) {
        history.push("/");
      } else {
        this.setState({
          is_updated: false,
        });
      }
    } else {
      history.push("/login");
    }
  }
  componentDidMount() {
    let startYear = 1940;
    let years = [startYear];
    while (startYear <= this.state.yearVal) {
      years.push(startYear++);
    }
    this.setState({
      years: years,
    });
  }

  _Y_onScrollChange = (value) => {
    this.setState({
      yearVal: value,
    });
  };
  _M_onScrollChange = (value) => {
    this.setState({
      monthVal: value,
    });
  };
  _D_onScrollChange = (value) => {
    this.setState({
      dayVal: value,
    });
  };
  _GENDER_onScrollChange = (value) => {
    this.setState({
      gender: value,
    });
  };
  toggleServiceAgree = () => {
    // this.setState({
    //     service_agree: !this.state.service_agree
    // })
  };
  togglePrivacyAgree = () => {
    // this.setState({
    //     privacy_agree: !this.state.privacy_agree
    // })
  };
  isValidForm = () => {
    if (!this.state.nameForm) {
      alert("닉네임을 입력하여 주세요");
      return false;
    }
    if (!(this.state.privacy_agree && this.state.service_agree)) {
      alert("이용약관에 동의하여 주세요");
      return false;
    }
    return true;
  };
  formSubmit = async () => {
    if (this.isValidForm()) {
      let birthday = undefined;
      if (this.state.dayVal) {
        birthday = `${String(this.state.yearVal)}-${String(
          this.state.monthVal
        )}-${String(this.state.dayVal)}`;
      }
      const formData = new FormData();
      this.state.profileImg
        ? formData.append("image", this.state.profileImg)
        : formData.append("image", "");
      formData.append("nickname", this.state.nameForm);
      formData.append("gender", this.state.gender);
      birthday && formData.append("birthday", birthday);
      const req = {
        headers: {
          Authorization: `Bearer ${this.state.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      const result = await Promise.all([
        userAPI
          .patchUserProfile(req)
          .then((res) => {
            return res.data;
          })
          .catch((e) => {
            if (e.response.data.nickname) {
              this.setState(
                {
                  nicknameValid: false,
                },
                () => {
                  scroll.scrollToTop({
                    duration: 200,
                    delay: 0,
                    smooth: true,
                  });
                }
              );
            }
            return false;
          }),
      ]);
      if (result[0]) history.push("/");
    }
  };
  handleNameChange = (event) => {
    this.setState({ nameForm: event.target.value });
  };
  handleImage = (e) => {
    if (e.target.files.length > 0) {
      this.setState({
        profileImg: e.target.files[0],
        profileImgURL: window.URL.createObjectURL(e.target.files[0]),
      });
    } else {
      this.setState({
        profileImg: null,
        profileImgURL: null,
      });
    }
  };
  render() {
    if (this.state.accessToken && !this.state.is_updated) {
      const genderContent = (
        <div
          style={{
            maxWidth: 480,
            height: 160,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Picker
            indicatorClassName="my-picker-indicator"
            selectedValue={this.state.gender}
            onScrollChange={this._GENDER_onScrollChange}
          >
            <Picker.Item className="my-picker-view-item" value={"f"}>
              여성
            </Picker.Item>
            <Picker.Item className="my-picker-view-item" value={"m"}>
              남성
            </Picker.Item>
          </Picker>
        </div>
      );
      const yearPopupContent = (
        <div
          style={{
            maxWidth: 480,
            height: 160,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Picker
            indicatorClassName="my-picker-indicator"
            selectedValue={this.state.yearVal}
            onScrollChange={this._Y_onScrollChange}
          >
            {this.state.years.map((year, index) => {
              return (
                <Picker.Item
                  key={index}
                  className="my-picker-view-item"
                  value={year}
                >
                  {year}
                </Picker.Item>
              );
            })}
          </Picker>
        </div>
      );
      const monthPopupContent = (
        <div
          style={{
            maxWidth: 480,
            height: 160,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Picker
            indicatorClassName="my-picker-indicator"
            selectedValue={this.state.monthVal}
            onScrollChange={this._M_onScrollChange}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month, index) => {
              return (
                <Picker.Item
                  key={index}
                  className="my-picker-view-item"
                  value={month}
                >
                  {month}
                </Picker.Item>
              );
            })}
          </Picker>
        </div>
      );
      const dayPopupContent = (
        <div
          style={{
            maxWidth: 480,
            height: 160,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Picker
            indicatorClassName="my-picker-indicator"
            selectedValue={this.state.dayVal}
            onScrollChange={this._D_onScrollChange}
          >
            {getDaysInMonth(this.state.monthVal - 1, this.state.yearVal).map(
              (year, index) => {
                return (
                  <Picker.Item
                    key={index}
                    className="my-picker-view-item"
                    value={year}
                  >
                    {year}
                  </Picker.Item>
                );
              }
            )}
          </Picker>
        </div>
      );

      return (
        <Wrap
          style={{
            paddingBottom: 0,
            height: "-webkit-fill-available",
            backgroundColor: "rgb(248,248,248)",
          }}
        >
          <IndexWrap style={{ paddingTop: 0, backgroundColor: "#f8f8f8" }}>
            <MainWrap>
              <MainWrapBottom />
              <NavBar>
                <img
                  src={back_ico}
                  onClick={() => {
                    history.goBack();
                  }}
                  style={{
                    fontSize: 19,
                    verticalAlign: "middle",
                    cursor: "pointer",
                  }}
                />
              </NavBar>
              <ProfileImgUp
                isDefaultProfileImg={this.state.profileImgURL ? false : true}
              >
                <div
                  className="profile-image"
                  style={{
                    backgroundImage: `url('${
                      this.state.profileImgURL
                        ? this.state.profileImgURL
                        : default_profile
                    }')`,
                  }}
                />
                <ImgBtn>
                  <img width="18px" height="100%" src={imgAdd} /> <Dot />
                  <input
                    type="file"
                    onChange={this.handleImage}
                    accept="image/*"
                  />
                </ImgBtn>
              </ProfileImgUp>
            </MainWrap>
            <InputWrap>
              <p className="signup-nickname"> 닉네임 </p>
              <input
                onChange={this.handleNameChange}
                value={this.state.nameForm}
                placeholder="닉네임을 입력해주세요."
              />
              {!this.state.nicknameValid ? (
                <ErrorMsg style={{ paddingLeft: 10 }}>
                  이미 존재하는 닉네임입니다.
                </ErrorMsg>
              ) : null}
              {/* <p className="signup-inputname"> 생년월일(선택) </p>
              <DropDownWrap>
                <Popup
                  transitionName="rmc-picker-popup-slide-fade"
                  maskTransitionName="rmc-picker-popup-fade"
                  content={yearPopupContent}
                  title="연도"
                  dismissText=""
                  okText=""
                >
                  <MyDropDown style={{ lineHeight: "20px" }}>
                    <span style={{ verticalAlign: "sub" }}>
                      {this.state.yearVal}
                    </span>
                    <IconWrapper>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="7"
                        viewBox="0 0 12 7"
                      >
                        <path
                          fill="#999999"
                          d="M11.29.29a.996.996 0 0 0-1.41 0L6 4.17 2.12.29A.996.996 0 1 0 .71 1.7L5.3 6.29c.39.39 1.02.39 1.41 0L11.3 1.7c.38-.38.38-1.02-.01-1.41z"
                        />
                      </svg>
                    </IconWrapper>
                  </MyDropDown>
                </Popup>

                <Popup
                  transitionName="rmc-picker-popup-slide-fade"
                  maskTransitionName="rmc-picker-popup-fade"
                  content={monthPopupContent}
                  title="월"
                  dismissText=""
                  okText=""
                >
                  <MyDropDown style={{ lineHeight: "20px" }}>
                    {!this.state.monthVal ? (
                      <BirthPlaceholder>01</BirthPlaceholder>
                    ) : (
                      <BirthPlaceholder style={{ color: "black" }}>
                        {this.state.monthVal}
                      </BirthPlaceholder>
                    )}
                    <IconWrapper>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="7"
                        viewBox="0 0 12 7"
                      >
                        <path
                          fill="#999999"
                          d="M11.29.29a.996.996 0 0 0-1.41 0L6 4.17 2.12.29A.996.996 0 1 0 .71 1.7L5.3 6.29c.39.39 1.02.39 1.41 0L11.3 1.7c.38-.38.38-1.02-.01-1.41z"
                        />
                      </svg>
                    </IconWrapper>
                  </MyDropDown>
                </Popup>

                <Popup
                  transitionName="rmc-picker-popup-slide-fade"
                  maskTransitionName="rmc-picker-popup-fade"
                  content={dayPopupContent}
                  title="일"
                  dismissText=""
                  okText=""
                >
                  <MyDropDown style={{ lineHeight: "20px" }}>
                    {!this.state.dayVal ? (
                      <BirthPlaceholder>01</BirthPlaceholder>
                    ) : (
                      <BirthPlaceholder style={{ color: "black" }}>
                        {this.state.dayVal}
                      </BirthPlaceholder>
                    )}
                    <IconWrapper>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="7"
                        viewBox="0 0 12 7"
                      >
                        <path
                          fill="#999999"
                          d="M11.29.29a.996.996 0 0 0-1.41 0L6 4.17 2.12.29A.996.996 0 1 0 .71 1.7L5.3 6.29c.39.39 1.02.39 1.41 0L11.3 1.7c.38-.38.38-1.02-.01-1.41z"
                        />
                      </svg>
                    </IconWrapper>
                  </MyDropDown>
                </Popup>
              </DropDownWrap>
              <p className="signup-inputname">성별 (선택)</p>
              <DropDownWrap>
                <Popup
                  transitionName="rmc-picker-popup-slide-fade"
                  maskTransitionName="rmc-picker-popup-fade"
                  content={genderContent}
                  title="성별"
                  dismissText=""
                  okText=""
                >
                  <MyDropDown style={{ lineHeight: "20px" }}>
                    <span style={{ verticalAlign: "sub" }}>
                      {this.state.gender === "f" ? "여성" : "남성"}
                    </span>
                    <IconWrapper>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="7"
                        viewBox="0 0 12 7"
                      >
                        <path
                          fill="#333"
                          d="M11.29.29a.996.996 0 0 0-1.41 0L6 4.17 2.12.29A.996.996 0 1 0 .71 1.7L5.3 6.29c.39.39 1.02.39 1.41 0L11.3 1.7c.38-.38.38-1.02-.01-1.41z"
                        />
                      </svg>
                    </IconWrapper>
                  </MyDropDown>
                </Popup>
              </DropDownWrap> */}
              <p className="signup-inputname" style={{ marginBottom: 1 }}>
                {" "}
                이용 약관(필수){" "}
              </p>
              <p className="signup-note">
                {" "}
                원활한 서비스 이용을 위해 약관 동의가 필요합니다.{" "}
              </p>
              <AgreeBox>
                <p
                  style={{ marginBottom: "20px", cursor: "pointer" }}
                  onClick={this.toggleServiceAgree}
                >
                  {!this.state.service_agree ? (
                    <AgreeDot />
                  ) : (
                    <img src={agreeChk} width="15px" />
                  )}
                  서비스 이용약관 동의
                  <span
                    className="agree-terms"
                    role="button"
                    onClick={() => {
                      window.location.href = "http://lay-er.com/agree";
                    }}
                  >
                    보기
                  </span>
                </p>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={this.togglePrivacyAgree}
                >
                  {!this.state.privacy_agree ? (
                    <AgreeDot />
                  ) : (
                    <img src={agreeChk} width="15px" />
                  )}
                  개인정보처리방침 동의
                  <span
                    className="agree-terms"
                    role="button"
                    onClick={() =>
                      window.open("http://lay-er.com/privacy-policy")
                    }
                  >
                    보기
                  </span>
                </p>
              </AgreeBox>
              <AgreeBtn onClick={this.formSubmit}> 모두 동의합니다 </AgreeBtn>
            </InputWrap>
          </IndexWrap>
        </Wrap>
      );
    } else {
      return <Fragment />;
    }
  }
}
export default Signup;
