/* global Swiper */
import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import styled, { css } from 'styled-components';
import DetailCommentIcon from 'components/myIcons/detailCommentIcon';
import PriceTagIcon from 'components/myIcons/priceTagIcon';
import DetailMoreIcon from 'components/myIcons/detailMoreIcon';
import Footer from '../components/footer';
import CommentModal from 'components/popModal/commentModal';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import ItemLinkRequiredModal from 'components/popModal/itemLinkRequiredModal';
import PostMoreInfoModal from 'components/popModal/postMoreInfoModal';
import ReportModal from 'components/popModal/reportModal';
import MyFilterModal from 'components/popModal/myFilterModal';
import { Wrap } from '../components/style/custom-styled-components';
import PostDetail from 'pages/postDetail';
import default_profile from 'images/default_profile.png';
import LikeBtn from 'components/likeBtn';
import MutedIcon from 'components/myIcons/mutedIcon';
import SpinnerCurtain from 'components/loadingSpinner/spinnerCurtain';
import ProductList from 'containers/productList';
import UserProfilePage from './userProfilePage';
import HomeOfAnUser from './homeOfAnUser';
import { CSSTransition } from 'react-transition-group';
import './posts.css';

import * as userAPI from 'axios/userAPI';

const PostInfoWrap = styled.div`
  position:absolute;
  z-index: 1000;
  display:flex;
  align-items: flex-end;
  width:100%;
  padding-left:16px;
  padding-right:16px
  bottom:60px;
  left:0px;
	@media (min-width: 1024px) {
		position: absolute;
	}
`;
const ProfileImage = styled.div`
	width: 42px;
	height: 42px;
	border: 1px solid #ffffff;
	flex: 0 0 auto;
	margin-right: 8px;
	border-radius: 50%;
	display: flex;
	${props =>
		props.mode == 'card' &&
		css`
			width: 36px;
			height: 36px;
		`}
	${props =>
		props.isDefaultProfileImg &&
		css`
			background-color: rgb(255, 255, 255);
		`}
  div {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		${props =>
			props.isDefaultProfileImg &&
			css`
				margin: auto;
				width: 21px;
				height: 21px;
				border-radius: 0;
				${props =>
					props.mode == 'card' &&
					css`
						width: 18px;
						height: 18px;
					`}
			`}
	}
`;
const PostTags = styled.p`
	margin: 0px;
	span {
		text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		color: #ffffff;
		font-family: 'Noto Sans CJK KR';
		font-size: 14px;
		font-weight: 500;
	}
	span.post-tag {
		cursor: pointer;
		:hover {
			opacity: 0.8;
		}
	}
`;
const PostAuthor = styled.p`
	margin: 0px;
	margin-bottom: 8px;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 12px;
	font-weight: 400;
`;
const PostTitle = styled.p`
	margin: 0px;
	margin-bottom: 8px;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 18px;
	font-weight: 700;
`;
const IconWrap = styled.div`
	flex: 0 0 auto;
	width: 40px;
	height: 40px;
	margin: auto;
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.2);
	${props =>
		props.gradient &&
		css`
			box-shadow: 0 2px 10px rgba(138, 99, 223, 0.5);
			background-image: linear-gradient(100deg, #828ff7 0%, #b08df7 100%);
		`}
`;
const IconContainer = styled.div`
	text-align: center;
`;
const IconText = styled.span`
	color: white;
`;
const Detail = styled.span`
	position: absolute;
	right: 16px;
`;
const ProgressBarWrap = styled.div`
	position: absolute;
	z-index: 10;
	display: flex;
	top: 6px;
	left: 6px;
	right: 6px;
	height: 3px;
	background-color: transparent;
	width: calc(100% - 12px);
`;
const ProgressBar = styled.div`
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.2);
	position: relative;
`;
const BarSpace = styled.div`
	width: 5px;
	height: 100%;
	background-color: transparent;
`;
const ProgressFilter = styled.div`
	height: 100%;
	width: 0;
	background-color: rgba(255, 255, 255, 0.6);
	position: absolute;
	top: 0px;
	left: 0px;
	transition: width 10s linear;
`;
const SwiperWrap = styled.div`
	background: black;
	position: fixed;
	bottom: 0;
	top: 0;
	width: 100%;
	@media (min-width: 1024px) {
		position: static;
	}
`;
const GradientFilter = styled.div`
	z-index: 1;
	position: absolute;
	left: 0px;
	width: 100%;
	height: 25%;

	${props =>
		props.top &&
		css`
			top: 0px;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.3) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		`}

	${props =>
		props.bottom &&
		css`
			bottom: 0px;
			background-image: linear-gradient(
				to top,
				rgba(0, 0, 0, 0.3) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		`}
`;
const RecommendationOrFollowing = styled.div`
	position: absolute;
	z-index: 10;
	top: 22px;
	width: 100%;
	color: white;
	text-align: center;
`;
const Button = styled.button`
	background-color: transparent;
	border: none;
	color: white;
	opacity: 0.6;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	padding-right: 4px;
	padding-left: 4px;
	${props =>
		props.selected &&
		css`
			opacity: 1;
		`}
`;
const Badge = styled.span`
	background-color: white;
	border-radius: 50%;
	width: 14px;
	height: 14px;
	display: flex;
	font-size: 10px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 14px;
	margin-top: -14px;
	position: absolute;
`;
const ProfilePic = styled.div`
	// transition: background-image 0.5s ease;
`;

@inject('cacheStore')
@inject('userStore')
@inject('postStore')
@observer
class Home extends React.Component {
	constructor(props) {
		super(props);

		// 복사된 URL 통해서 들어왔을 경우 copiedId에 id 저장
		// 1개만 로딩할 수 있도록 하기 위함
		const { match } = props;
		let copiedId;
		if (match.params && match.params.id && match.path.includes('/single/')) {
			copiedId = match.params.id;
		}
		this._isMounted = false;
		this.state = {
			isRecommendation: !this.props.match.path.includes('/following'),
			mainFilter: this.props.cacheStore.mainFilter,
			mainTagArr: [],
			loginModalToggle: false,
			postMoreInfoData: false,
			postIdForComment: false,
			accessToken: this.props.userStore.auth_token,
			profile: false,
			postsMap: {},
			postData: {
				video: [],
				profile: {},
				comment: { count: 0 },
				like: { count: 0 },
				item: [],
				tag: [],
			},
			prgBarWidth: [
				{
					id: 0,
					width: '0%',
					currentDuration: 0,
				},
			],
			copiedId,
			founding: false,
		};
		// this.state.post = post;

		// 임시
		this.state.clicked = false;

		// 스와이퍼
		this.state.swiper = null;

		// 렌더링할 post
		this.state.slides = [];
		this.state.virtualData = {
			slides: [],
		};

		// 현재 슬라이드 id
		this.state.nowId = null;

		// 뮤트
		this.state.muted = true;

		// post data 의 next 키
		this.state.next = null;

		// 상품창 표시
		this.state.productListOn = false;

		// 프로필 이미지 원본
		this.state.minimizedProfileImgUrl = null;

		// 현재 포스트의 프로필 ID
		this.state.profileId = this.props.match.params.profileId;

		// 프로필 페이지 갈 시 비디오 정지
		this.state.deactivateVideo = Boolean(this.state.profileId);

		// 슬라이드 인덱스
		let initialSlide = 0;
		const { pathname } = this.props.location;
		if (pathname.includes('/postsOfProfile')) {
			// 프로필의 포스트 페이지에선 메인으로 리디렉션
			window.location.href = '/';
		} else if (this.state.profileId) {
			// 프로필 페이지
			initialSlide = 1;
		}
		this.state.initialSlide = initialSlide;

		// 4번째 프로필 포스트 리스트 관련 상태
		this.state.showHomeOfUser = initialSlide === 2;
		this.state.homeOfAnUserIndex = 0;

		this.state.swipingToUserProfileCompleted = pathname.includes('/profile');
	}
	async componentDidMount() {
		const { postStore, userStore } = this.props;
		this._isMounted = true;
		window.app = this;
		const mainTagArr = [];
		const mainTags = await postStore.getMainTag().then(res => {
			return res;
		});
		mainTags[0].forEach(function(item, index) {
			mainTagArr.push('#' + item.name);
		});
		const initMainTags = async () => {
			const mainTagArr = [];
			const mainTags = await Promise.all([
				postStore.getMainTag().then(res => {
					return res;
				}),
			]);
			if (mainTags[0][0]) {
				mainTags[0][0].forEach(function(item, index) {
					mainTagArr.push('#' + item.name);
				});

				let profile = false;
				if (this.state.accessToken) {
					const profileReq = {
						headers: this.state.accessToken
							? {
									Authorization: `Bearer ${this.state.accessToken}`,
							  }
							: false,
					};
					profile = await Promise.all([
						userAPI
							.getMyUserProfile(profileReq)
							.then(res => {
								return res.data;
							})
							.catch(e => {
								console.error(e);
								// location.reload();
								// 임시로 없애보자.
								// alert('로그인 오류로 인하여 새로고침 합니다.');
								// localStorage.removeItem('setupTime');
								// localStorage.removeItem('ms_auth_token');
								// this.props.userStore.logout();
							}),
					]);
				}

				const headers = userStore.auth_token
					? {
							Authorization: `Bearer ${userStore.auth_token}`,
					  }
					: undefined;
				const postData = await postStore
					.getPostList({
						tag: mainTagArr[0],
						headers,
					})
					.then(res => {
						return res;
					});
				if (this._isMounted) {
					this.setState({
						initPostData: postData[0],
						profile: profile[0],
						mainTagArr,
					});
				}
			}
		};
		if (!this.props.cacheStore.mainState) {
			initMainTags();
		}

		// 클릭한 태그를 이용하여 post 가져오기
		let clicked = this.state.clicked;
		if (!clicked) {
			clicked = mainTagArr[0];
		}
		let following = !this.state.isRecommendation;
		let posts = await this.getPostList({
			clicked,
			following,
		});
		const { pathname } = this.props.location;
		this.swiperH = new Swiper('.swiper-container-home-hor', {
			initialSlide: this.state.initialSlide,
			on: {
				init: () => {
					this.setState({ initSwiperH: true });
				},
				transitionEnd: () => {
					this.setState({ horizontalTransition: false });
				},
				slideChange: () => {
					this.setState({ horizontalTransition: true });
					if (this.swiperH.realIndex === 1) {
						this.setState({ swipingToUserProfileCompleted: true });
					}
					// 카피한 것이 아닐 때
					if (!this.state.copiedId) {
						// 슬라이드가 맨 처음부터 시작하도록 되어 있다.
						const slide = this.state.slides[this.swiper.realIndex];
						if (slide) {
							if (this.swiperH.realIndex === 2) {
								// 프로필 포스트 페이지
								window.history.replaceState(
									null,
									null,
									`/postsOfProfile/${slide.profile.id}`,
								);
								this.setState({
									deactivateVideo: true,
								});
							} else if (this.swiperH.realIndex === 1) {
								// 프로필 페이지
								if (this.state.showHomeOfUser) {
									// 첫 접속이 프로필 포스트 페이지일경우 그 프로필을 기준으로 아이디를 잡아준다.
									window.history.replaceState(
										null,
										null,
										`/profile/${this.state.profileId}`,
									);
								} else {
									window.history.replaceState(
										null,
										null,
										`/profile/${slide.profile.id}`,
									);
								}
								this.setState({
									deactivateVideo: true,
								});
							} else if (this.swiperH.realIndex === 0) {
								// 카드 페이지
								window.history.replaceState(null, null, `/${slide.id}`);
								this.setState({
									deactivateVideo: false,
								});
							}
						}
					}

					// 프로필 포스트 리스트를 나갈 시
					if (this.swiperH.realIndex !== 2 && this.state.showHomeOfUser) {
						this.setState({
							showHomeOfUser: false,
						});
					}
					if (this.swiperH.realIndex === 2) {
						this.setState({
							showHomeOfUser: true,
						});
					}
				},
			},
		});
		this.swiper = new Swiper('.swiper-container-home', {
			direction: 'vertical',

			virtual: {
				slides: this.state.slides,
				renderExternal: data => {
					this.setState({
						virtualData: data,
					});
				},
				addSlidesBefore: 4,
				addSlidesAfter: 4,
			},
			on: {
				init: () => {
					// URL이 / 인 메인페이지에서 초기 로딩시 profile Id 세팅해준다.
					if (this.props.match.path === '/') {
						const post = posts[0];
						this.setState({
							profileId: post.profile.id,
						});
					}
				},
				transitionEnd: () => {
					this.setState({ verticalTransition: false });
				},
				slideChange: () => {
					this.setState({
						verticalTransition: true,
						swipingToUserProfileCompleted: false,
					});
					if (!this.state.copiedId) {
						const slide = this.state.slides[this.swiper.realIndex];
						if (slide) {
							if (this.swiper.realIndex > 0) {
								window.history.replaceState(null, null, `/${slide.id}`);
							} else {
								window.history.replaceState(null, null, `/`);
							}
							setTimeout(() => {
								this.setState({
									nowId: slide.id,
									profileId: slide.profile.id,
								});
							}, 200);
						}
					}
				},
				// 마지막에 도달했을 땐 새로 로딩해야한다.
				reachEnd: () => {
					if (this.state.slides.length && !this.state.copiedId) {
						this.loadMore({ clicked });
					}
				},
			},
		});

		if (this.swiper && this.swiper.virtual && posts) {
			const postNow = posts[this.swiper.realIndex];
			this.setState({
				posts,
				clicked,
				nowId: postNow ? postNow.id : null,
			});
			this.swiper.virtual.appendSlide(posts);
		}
		this.setState({ swiper: this.swiper });

		// 터치 이벤트 추가
		document.body.addEventListener('touchstart', () => {
			// 터치 시작시 다음 영상 미리 소리 재생하기
			// 2가지 이슈 커버 완료
			// 1. 실제 슬라이딩 이뤄지지 않았을 때 멈추기
			// 2. 슬라이드 위로 이동 시 위에 있는 영상이 재생 되어야 한다.
			if (!this.state.muted) {
				// 슬라이드 데이터 찾기
				const prevSlide = this.state.slides[this.swiper.realIndex - 1];
				const nextSlide = this.state.slides[this.swiper.realIndex + 1];

				// id 구하기
				const prevSlideId = prevSlide && `video_${prevSlide.id}`;
				const nextSlideId = `video_${nextSlide.id}`;

				// DOM Element 찾기
				const prevSlideEle =
					prevSlideId && document.getElementById(prevSlideId);
				const nextSlideEle = document.getElementById(nextSlideId);

				// 위와 아래의 것의 muted를 푼다.
				if (prevSlideEle) {
					prevSlideEle.muted = false;
				}
				if (nextSlideEle) {
					nextSlideEle.muted = false;
				}
			}
		});

		// 메인 페이지일경우
		if (this.state.initialSlide === 0) {
			// 메인 페이지의 포스트 id를 슬라이스 한다.
			const sliced = /\d+/.exec(pathname);
			if (sliced) {
				const id = parseInt(sliced[0]);
				const found = posts.findIndex(post => post.id === id);
				if (found > 0) {
					this.swiper.slideTo(found, 0);
				} else if (found === 0) {
					// found 가 0일경우 slideTo를 호출하지 않기 때문에 slideChange이벤트가 발생하지 않는다.
					// 프로필 로딩을 위한 profileId는 slideChange에서 세팅되는데 그 이벤트가 발생하지 않으므로
					// 여기서 직접 세팅해준다.
					this.setState({
						profileId: posts[0].profile.id,
					});
				} else {
					this.setState({ founding: true });
					// 현재 불러온 posts에 찾는 post가 없을 경우,
					// 불러와서 다시 페이지를 만들어야 해.
					let found = -1;
					while (found < 0) {
						const result = await this.loadMore({
							clicked,
						});
						found = result.findIndex(post => post.id === id);
					}
					this.swiper.slideTo(found, 0);
					this.setState({ founding: false });
				}
			}
		}
	}
	loadMore = async ({ clicked }) => {
		let posts = await this.getPostList({ clicked });
		// 기존에 로드 된 것은 무시
		posts = posts.filter(
			o => this.state.posts.findIndex(j => j.id === o.id) < 0,
		);
		const concated = this.state.posts.concat(posts);
		this.setState({ posts: concated });
		this.swiper.virtual.appendSlide(posts);
		return concated;
	};

	componentWillUnmount() {
		this._isMounted = false;
		this.props.postStore.clearAllToggle();
		this.props.userStore.clearAllToggle();
		this.props.cacheStore.setMainState(this.state);
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.swiper && this.swiperH) {
			if (this.state.verticalTransition) {
				this.swiperH.allowTouchMove = false;
			} else {
				this.swiperH.allowTouchMove = true;
			}
			if (this.state.horizontalTransition) {
				this.swiper.allowTouchMove = false;
			} else {
				this.swiper.allowTouchMove = true;
			}
		}
		const postDataNow =
			this.state.postsMap[this.state.nowId] || this.state.postData;
		const postDataPrev =
			prevState.postsMap[prevState.nowId] || prevState.postData;
		if (postDataNow !== postDataPrev) {
			this.initPrgBarWidth(postDataNow.video.length);
			this.getMinimizedProfileImgUrl(postDataNow.profile.image)
				.then(url => {
					this.setState({ minimizedProfileImgUrl: url });
				})
				.catch(e => {
					this.setState({ minimizedProfileImgUrl: null });
				});
		}
	}
	getPostList = async ({ clicked }) => {
		const { postStore, userStore } = this.props;
		const headers = userStore.auth_token
			? { Authorization: `Bearer ${userStore.auth_token}` }
			: undefined;
		let posts;
		posts = await postStore
			.getPostList({
				tag: clicked,
				following: !this.state.isRecommendation,
				headers,
				next: this.state.next,
			})
			.then(res => {
				return res;
			});
		if (posts) {
			if (posts.next) {
				this.setState({ next: posts.next });
			}
			return posts.data;
		}
		return null;
	};
	initPrgBarWidth = length => {
		let arr = [];
		for (var i = 0; i < length; i++) {
			arr.push({
				id: i,
				width: '0%',
				currentDuration: 0,
			});
		}
		this.setState({
			prgBarWidth: arr,
		});
	};
	setPrgBarWidth = (id, currentDuration, width) => {
		this.setState({
			prgBarWidth: this.state.prgBarWidth.map(prg =>
				id == prg.id
					? { ...prg, currentDuration: currentDuration, width: width }
					: prg,
			),
		});
	};
	updatePostsMap = (id, data) => {
		this.setState({
			postsMap: { ...this.state.postsMap, [id]: data },
		});
	};
	handleMoreInfo = post => {
		this.setState({
			postMoreInfoData: post,
		});
		this.props.postStore.toggleMoreInfo();
	};
	getMinimizedProfileImgUrl = originalSrc => {
		// 64px 로 압축된 프로필 이미지가 있는지 확인한다.
		return new Promise((resolve, reject) => {
			const img = document.createElement('img');
			img.src = `${/.+\//.exec(originalSrc)[0]}64_${
				/.+\/(.+)/.exec(originalSrc)[1]
			}`;
			img.onload = () => {
				resolve(img.src);
			};
			img.onerror = e => {
				reject(e);
			};
		});
	};
	handleComment = postId => {
		this.setState({
			postIdForComment: postId,
		});
		this.props.postStore.toggleComment();
	};
	handleSound = () => {
		const nextValue = !this.state.muted;
		this.setState({
			muted: nextValue,
		});
		// 비디오 element에 전달
		// 슬라이드 데이터 찾기
		const slide = this.state.slides[this.swiper.realIndex];

		// id 구하기
		const slideId = slide && `video_${slide.id}`;

		// DOM Element 찾기
		const slideEle = slideId && document.getElementById(slideId);

		// 위와 아래의 것의 muted를 푼다.
		if (slideEle) {
			slideEle.muted = nextValue;
		}
	};
	toProfile = async () => {
		this.swiperH.slideTo(1);
	};
	toggleSelected = isRecommendation => () => {
		const isLogin = Boolean(this.props.userStore.auth_token);
		if (!isLogin && !isRecommendation) {
			this.props.userStore.getTokenOrToggleLogin();
		} else {
			this.setState({
				isRecommendation,
			});
			window.location.href = isRecommendation ? '/' : '/following';
		}
	};
	render() {
		const postNow =
			this.state.postsMap[this.state.nowId] || this.state.postData;
		const videoLen = postNow.video.length;
		const isLoaded = postNow.title;
		const { founding } = this.state;
		const isMine =
			this.props.userStore.auth_token &&
			this.state.profile &&
			postNow.profile &&
			postNow.profile.id == this.state.profile.id;
		return (
			<div className="swiper-container swiper-container-home-hor">
				<div className="swiper-wrapper">
					<div className="swiper-slide">
						<Wrap>
							<PostMoreInfoModal
								data={this.state.postMoreInfoData}
								bottom={'0px'}
								isMine={isMine}
							/>
							<CommentModal
								postId={this.state.postIdForComment}
								bottom={'0px'}
							/>
							<ItemLinkRequiredModal bottom={'0px'} />
							<MyFilterModal
								bottom={'0px'}
								is_applied={
									this.state.profile ? this.state.profile.is_applied : undefined
								}
								profile={this.state.profile}
							/>
							<ReportModal bottom={'0px'} />
							<LoginRequiredModal bottom={'0px'} />
							<SwiperWrap className="swiper-container swiper-container-home">
								<div className="swiper-wrapper">
									{this.state.virtualData.slides.map((slide, index) => (
										<div
											key={slide.id}
											className="swiper-slide"
											style={{
												top: `${this.state.virtualData.offset}px`,
												overflow: 'hidden',
											}}
										>
											<PostDetail
												{...this.props}
												params={{ id: slide.id }}
												active={
													!this.state.deactivateVideo &&
													this.state.nowId === slide.id
												}
												updatePostsMap={this.updatePostsMap}
												setPrgBarWidth={this.setPrgBarWidth}
												muted={this.state.muted}
												thumbnail={
													this.state.posts[this.swiper.realIndex]
														.thumbnail_image
												}
												showThumbDiv={!isLoaded}
											/>
											{!isLoaded && <SpinnerCurtain />}
										</div>
									))}
								</div>
								<ProgressBarWrap>
									{postNow.video.map((video, index) => {
										return (
											<Fragment key={index}>
												<ProgressBar>
													<ProgressFilter
														style={{
															width: this.state.prgBarWidth[index]
																? this.state.prgBarWidth[index].width
																: 0,
															transition: `width ${
																this.state.prgBarWidth[index]
																	? this.state.prgBarWidth[index]
																			.currentDuration
																	: 0
															}s linear`,
														}}
													/>
												</ProgressBar>
												{index == videoLen - 1 ? <Fragment /> : <BarSpace />}
											</Fragment>
										);
									})}
								</ProgressBarWrap>

								<GradientFilter top />
								<GradientFilter bottom />
								<RecommendationOrFollowing>
									<Button
										selected={this.state.isRecommendation}
										onClick={this.toggleSelected(true)}
										role="button"
									>
										추천
									</Button>
									<Button
										selected={!this.state.isRecommendation}
										onClick={this.toggleSelected(false)}
										role="button"
									>
										팔로잉
									</Button>
									<Detail
										onClick={() => isLoaded && this.handleMoreInfo(postNow)}
									>
										<DetailMoreIcon />
									</Detail>
								</RecommendationOrFollowing>

								<CSSTransition
									in={!this.state.productListOn}
									timeout={300}
									classNames="example"
									unmountOnExit
								>
									<>
										<PostInfoWrap>
											<div style={{ flex: '1 1 auto', width: '100%' }}>
												{isLoaded && (
													<>
														<PostAuthor>
															<span
																style={{ fontStyle: 'italic', marginRight: 4 }}
															>
																by
															</span>
															{postNow.profile.nickname}
															{'·'}
															{postNow.edited_at}
														</PostAuthor>
														<PostTitle>{postNow.title}</PostTitle>
													</>
												)}

												<PostTags>
													{postNow.tag.length
														? postNow.tag.map((tag, index) => {
																return (
																	<span
																		key={index}
																		onClick={() => {
																			window.location.href = `/search?tag=${tag.name}`;
																		}}
																		className="post-tag"
																		role="button"
																	>
																		{' '}
																		{`#${tag.name}`}{' '}
																	</span>
																);
														  })
														: null}
												</PostTags>
											</div>
											{isLoaded && (
												<div
													style={{
														flex: '0 0 auto',
														width: '40px',
														marginLeft: 24,
														height: '100%',
													}}
												>
													<div>
														<ProfileImage
															isDefaultProfileImg={
																postNow.profile.image ? false : true
															}
															onClick={this.toProfile}
														>
															<ProfilePic
																style={{
																	backgroundImage: `url('${
																		postNow.profile.image
																			? this.state.minimizedProfileImgUrl
																				? this.state.minimizedProfileImgUrl
																				: postNow.profile.image
																			: // ? postNow.profile.image
																			  default_profile
																	}')`,
																}}
															/>
														</ProfileImage>
														<IconContainer>
															<IconWrap>
																<LikeBtn
																	noClickedColor="rgb(255,255,255)"
																	fontSize="24px"
																	margin="auto"
																	height="auto"
																	marginTop="0px"
																	is_liked={postNow.like.is_liked}
																	id={postNow.id}
																	option="post"
																/>
															</IconWrap>
															<IconText>{postNow.like.count}</IconText>
														</IconContainer>
														<IconContainer>
															<IconWrap
																onClick={() =>
																	isLoaded && this.handleComment(postNow.id)
																}
															>
																<DetailCommentIcon />
															</IconWrap>
															<IconText>{postNow.comment.count}</IconText>
															<IconWrap
																ref={ref => (this.muteBtn = ref)}
																onClick={this.handleSound}
															>
																<MutedIcon muted={this.state.muted} />
															</IconWrap>
														</IconContainer>
														<IconWrap
															gradient
															onClick={() => {
																if (postNow.item.length < 1) {
																	alert('등록된 상품이 없습니다.');
																} else {
																	this.setState({
																		productListOn: true,
																	});
																}
															}}
														>
															<PriceTagIcon />
															<Badge>{postNow.item.length}</Badge>
														</IconWrap>
													</div>
												</div>
											)}
										</PostInfoWrap>
										{founding && <SpinnerCurtain fullSize />}
										<Footer />
									</>
								</CSSTransition>
							</SwiperWrap>
							<ProductList
								on={this.state.productListOn}
								onClose={() => this.setState({ productListOn: false })}
								items={postNow.item}
							/>
						</Wrap>
					</div>
					<div className="swiper-slide">
						{this.state.swipingToUserProfileCompleted ? (
							<UserProfilePage
								id={this.state.profileId}
								goBack={() => {
									this.swiperH.slidePrev();
								}}
								onClickItem={({ index }) => {
									// 클릭시에만 넘어갈 수 있게 풀어준다.
									this.setState({
										showHomeOfUser: true,
										homeOfAnUserIndex: index,
									});
									this.swiperH.slideNext();
								}}
							/>
						) : (
							<SpinnerCurtain fullSize />
						)}
					</div>
					<div className="swiper-slide">
						<HomeOfAnUser
							goBack={() => this.swiperH.slidePrev()}
							index={this.state.homeOfAnUserIndex}
							handleIndex={homeOfAnUserIndex =>
								this.setState({ homeOfAnUserIndex })
							}
							show={this.state.showHomeOfUser}
							profileId={this.state.profileId}
						/>
					</div>
				</div>
				{!this.state.initSwiperH && <SpinnerCurtain fullSize />}
			</div>
		);
	}
}
export default Home;
