import React, { Fragment } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Grid } from 'antd-mobile';
import LikeBtn from 'components/likeBtn';
import { openNewWindow } from 'utils/history';
import numberComma from 'utils/numberComma';

const emerge = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const ItemCard = styled.div`
	position: relative;
	z-index: 0;
	cursor: pointer;
	height: 222px;
	border-radius: 10px;
	overflow: hidden;
	background-size: cover;
	background-position: center;
	animation: ${emerge} 0.5s;
	animation-fill-mode: forwards;
	:after {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0);
		content: '';
		z-index: -1;
	}
`;
const LikeBtnWrap = styled.div`
	position: absolute;
	top: 10px;
	right: 10px;
`;
const Title = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 13px;
	text-align: left;
	padding-left: 4px;
	padding-top: 1px;
	font-weight: 500;
	height: 24px;
	line-height: 24px;
`;
const PriceInfo = styled.div`
	color: #333333;
	text-align: left;
	border: 1px solid rgba(0, 0, 0, 0);
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 13px;
	font-weight: 700;
	line-height: 29px;
	padding-left: 4px;
	margin-top: -8px;
`;
const ItemWrap = styled(Grid)`
	${props => css`
		display: block;
		padding: 0px 0px 110px;
		overflow: hidden;
		align-items: center;
		justify-content: center;
		background-color: rgb(248, 248, 248);

		div.am-flexbox-item.am-grid-item:nth-child(odd) {
			margin-right: 3%;
		}
	`}
`;
const GradientFilter = styled.div`
	position: absolute;
	left: 0px;
	width: 100%;
	height: 19%;
	${props =>
		props.top &&
		css`
			top: 0px;
			border-radius: 10px 10px 0px 0px;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.2) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		`}

	${props =>
		props.bottom &&
		css`
			bottom: 0px;
			border-radius: 0px 0px 10px 10px;
			background-image: linear-gradient(
				to top,
				rgba(0, 0, 0, 0.2) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		`}
`;
export default class LikableItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		let data;
		if (Array.isArray(this.props.data)) {
			if (this.props.mode == 'postDetail') {
				data = this.props.data.slice(0, 6);
			} else {
				data = this.props.data;
			}
		}
		return (
			<ItemWrap
				backgroundColor={this.props.backgroundColor}
				data={data}
				columnNum={2}
				hasLine={false}
				activeStyle={false}
				itemStyle={{
					height: this.props.height ? this.props.height : 280,
					marginTop: this.props.marginTop ? this.props.marginTop : 12,
				}}
				renderItem={(data, index) => {
					let image;
					if (Array.isArray(data.image)) {
						image = data.image[0].image;
					} else if (data.image == null) {
						image = '';
					} else {
						image = data.image.image;
					}
					return (
						<Fragment>
							<ItemCard
								style={{ backgroundImage: 'url(' + image + ')' }}
								onClick={() => {
									openNewWindow(
										data.shopLink && data.shopLink !== ''
											? data.shopLink
											: data.link,
									);
								}}
							>
								<GradientFilter top />
							</ItemCard>
							<LikeBtnWrap>
								<LikeBtn
									marginLeft="4px"
									noClickedColor="rgb(255,255,255)"
									is_liked={data.like.is_liked}
									option="item"
									id={data.id}
								/>
							</LikeBtnWrap>
							<Title> {data.name} </Title>
							<PriceInfo>
								{data.price == 0 ? '업데이트중' : numberComma(data.price)}{' '}
							</PriceInfo>
						</Fragment>
					);
				}}
			></ItemWrap>
		);
	}
}
