import React, { Component } from "react";

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="40"
    viewBox="0 0 16 20"
    {...props}
  >
    <g>
      <g>
        <path d="M7.529 17.06c-1.738 0-3.144-1.37-3.144-3.073 0-1.585 1.025-2.7 2.744-3.053 1.435-.294 2.91-.91 3.935-1.88.273-.254.723-.136.8.226.225.998.343 2.035.343 3.083a4.68 4.68 0 0 1-4.678 4.698zm5.254-14.132a17.293 17.293 0 0 0-2.344-2.79c-.342-.332-.918-.019-.82.45.185.92.38 2.134.38 3.22C10 5.826 8.682 7.46 6.67 7.46c-1.503 0-2.734-.91-3.27-2.212a2.445 2.445 0 0 1-.196-.528c-.107-.411-.645-.538-.879-.176-.176.264-.342.528-.498.812A13.502 13.502 0 0 0 0 12.167c0 4.326 3.496 7.83 7.812 7.83 4.316 0 7.812-3.504 7.812-7.83 0-3.416-1.054-6.587-2.841-9.24z" />
      </g>
    </g>
  </svg>
);
