import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { ClipLoader, PropagateLoader } from "react-spinners";

import * as postAPI from "axios/postAPI";
import alertIcon from "images/add_link_alert.png";
import { ReactComponent as CautionIcon } from "images/add_link_note.svg";

const UpdateInput = styled.input`
  width: 100%;
  height: 44px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  border: none;
  margin-bottom: 12px;
  padding: 0px 16px;
  padding-right: 40px;
  color: #333333;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
`;

const AlertIcon = styled.div`
  background-size: cover;
  background-position: center;
  width: 16px;
  height: 15px;
  position: absolute !important;
  top: 14px;
  right: 12px;
`;

const Note = styled.p`
  margin-left: 8px;
  margin-top: -8px;
  margin-bottom: 12px;
  ${(props) =>
    props.type == "caution" &&
    css`
      color: #828ff7;
      font-family: "Noto Sans CJK KR";
      font-size: 12px;
      font-weight: 500;
    `}
  ${(props) =>
    props.type == "alert" &&
    css`
      color: #f85e3a;
      font-family: "Noto Sans CJK KR";
      font-size: 12px;
      font-weight: 500;
    `}
`;

function testUrlRegex(url) {
  let exp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  let regex = new RegExp(exp);
  return url.match(regex);
}

@inject("cacheStore")
@inject("postStore")
@observer
class ItemLinkInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alarm: undefined,
      alarmInfo: "",
      loading: false,
      link: "",
    };
  }
  componentWillMount() {
    const { cacheStore } = this.props;
    let link = "";
    if (cacheStore.postEditData) {
      link = cacheStore.postEditData.linkArr[this.props.number]
        ? cacheStore.postEditData.linkArr[this.props.number].data
        : "";
    }
    this.setState({
      link: link,
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isGetLink != this.props.isGetLink && this.props.isGetLink) {
      const { cacheStore } = this.props;
      let link = "";
      if (cacheStore.postEditData) {
        link = cacheStore.postEditData.linkArr[this.props.number]
          ? cacheStore.postEditData.linkArr[this.props.number].data
          : "";
      }
      this.setState({
        link: link,
      });
    }
  }
  handleAlarm = (alarm) => {
    if (alarm == 0) {
      this.props.postStore.setLinkLoading(true);
      this.setState(
        {
          alarm: undefined,
          alarmInfo: "",
          loading: true,
        },
        async () => {
          const linkStat = await postAPI
            .checkItemLink(this.state.link)
            .catch((error) => {
              this.handleAlarm(1);
              this.props.handleRemoveLinkArr(this.props.number);
            });
          setTimeout(() => {
            if (linkStat) {
              if (linkStat.status == 204) {
                this.handleAlarm(2);
              } else if (linkStat.status == 200) {
                this.props.postStore.setLinkLoading(false);
                this.setState({
                  loading: false,
                  alarm: undefined,
                  alarmInfo: "",
                });
              }
              this.props.handleAddLinkArr(this.props.number, this.state.link);
            }
          }, 500);
        }
      );
    } else if (alarm == 1) {
      this.props.postStore.setLinkLoading(false);
      this.setState({
        alarm: "alert",
        alarmInfo: "존재하지 않는 링크입니다.",
        loading: false,
      });
    } else if (alarm == 2) {
      this.props.postStore.setLinkLoading(false);
      this.setState({
        alarm: "caution",
        alarmInfo:
          "해당 쇼핑몰의 상품 정보는 하루 정도 소요됩니다. (링크는 적용됨)",
        loading: false,
      });
    }
  };
  handleInput = (event) => {
    if (!testUrlRegex(event.target.value)) this.handleAlarm(1);
    else this.handleAlarm(0);
    this.setState({ [event.target.name]: event.target.value }, () => {
      scroller.scrollTo("link-input", {
        duration: 0,
        delay: 0,
        smooth: false,
        offset: 20,
      });
    });
  };
  handleDelete = () => {
    if (this.props.number == 0) {
      this.setState(
        {
          link: "",
          alarm: undefined,
          alarmInfo: "",
          loading: false,
        },
        () => {
          this.props.handleRemoveLinkArr(this.props.number);
        }
      );
    } else {
      this.setState(
        {
          link: "",
          removeProps: this.props.handleRemoveLinkArr(this.props.number),
        },
        () => {
          this.element.remove();
        }
      );
    }
  };
  render() {
    return (
      <div ref={(ref) => (this.element = ref)}>
        <Element name="link-input">
          <UpdateInput
            ref={(link) => (this.link = link)}
            name="link"
            value={this.state.link}
            onChange={this.handleInput}
            placeholder="http://"
          />
        </Element>
        {this.state.loading == true ? (
          <div style={{ position: "absolute", right: 11, top: 13 }}>
            <ClipLoader
              sizeUnit={"px"}
              size={15}
              color={"rgb(130, 143, 247)"}
              loading={true}
            />
          </div>
        ) : null}
        {this.props.deleteMode ? (
          <svg
            style={{ position: "absolute", right: "14px", top: "16px" }}
            onClick={this.handleDelete}
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 14 14"
          >
            <path
              fill="#333333"
              d="M11.24.769a.828.828 0 0 0-1.173 0L6 4.827 1.933.76A.828.828 0 1 0 .76 1.933L4.827 6 .76 10.067a.828.828 0 1 0 1.173 1.173L6 7.173l4.067 4.067a.828.828 0 1 0 1.173-1.173L7.173 6l4.067-4.067a.834.834 0 0 0 0-1.164z"
            />
          </svg>
        ) : (
          <Fragment>
            {this.state.alarm == "alert" ? (
              <AlertIcon style={{ backgroundImage: `url('${alertIcon}')` }} />
            ) : null}
            {this.state.alarm == "caution" ? (
              <i style={{ position: "absolute", right: 11, top: 13 }}>
                <CautionIcon />
              </i>
            ) : null}
            {this.state.alarm ? (
              <Note type={this.state.alarm}> {this.state.alarmInfo} </Note>
            ) : null}
          </Fragment>
        )}
      </div>
    );
  }
}
export default ItemLinkInput;
