import { createBrowserHistory } from "history";

export default createBrowserHistory();

export function openNewWindow(link) {
  if (link) {
    window.open(link, "_blank");
    return true;
  } else {
    return false;
  }
}
