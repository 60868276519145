import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import styled, { css } from 'styled-components';
import DetailCommentIcon from 'components/myIcons/detailCommentIcon';
import PriceTagIcon from 'components/myIcons/priceTagIcon';
import DetailMoreIcon from 'components/myIcons/detailMoreIcon';
import { Wrap } from '../components/style/custom-styled-components';
import PostDetail from 'pages/postDetail';
import default_profile from 'images/default_profile.png';
import LikeBtn from 'components/likeBtn';
import MutedIcon from 'components/myIcons/mutedIcon';
import SpinnerCurtain from 'components/loadingSpinner/spinnerCurtain';
import PostMoreInfoModal from 'components/popModal/postMoreInfoModal';
import { CSSTransition } from 'react-transition-group';
import ProductList from 'containers/productList';
import CommentModal from 'components/popModal/commentModal';
import ItemLinkRequiredModal from 'components/popModal/itemLinkRequiredModal';
import MyFilterModal from 'components/popModal/myFilterModal';
import ReportModal from 'components/popModal/reportModal';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import * as userAPI from 'axios/userAPI';

const PostInfoWrap = styled.div`
  position:absolute;
  z-index: 1000;
  display:flex;
  align-items: flex-end;
  width:100%;
  padding-left:16px;
  padding-right:16px
  bottom:60px;
  left:0px;
	@media (min-width: 1024px) {
		position: absolute;
	}
`;
const ProfileImage = styled.div`
	width: 42px;
	height: 42px;
	border: 1px solid #ffffff;
	flex: 0 0 auto;
	margin-right: 8px;
	border-radius: 50%;
	display: flex;
	${props =>
		props.mode == 'card' &&
		css`
			width: 36px;
			height: 36px;
		`}
	${props =>
		props.isDefaultProfileImg &&
		css`
			background-color: rgb(255, 255, 255);
		`}
  div {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		${props =>
			props.isDefaultProfileImg &&
			css`
				margin: auto;
				width: 21px;
				height: 21px;
				border-radius: 0;
				${props =>
					props.mode == 'card' &&
					css`
						width: 18px;
						height: 18px;
					`}
			`}
	}
`;
const PostTags = styled.p`
	margin: 0px;
	span {
		text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		color: #ffffff;
		font-family: 'Noto Sans CJK KR';
		font-size: 14px;
		font-weight: 500;
	}
	span.post-tag {
		cursor: pointer;
		:hover {
			opacity: 0.8;
		}
	}
`;
const PostAuthor = styled.p`
	margin: 0px;
	margin-bottom: 8px;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 12px;
	font-weight: 400;
`;
const PostTitle = styled.p`
	margin: 0px;
	margin-bottom: 8px;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 18px;
	font-weight: 700;
`;
const IconWrap = styled.div`
	flex: 0 0 auto;
	width: 40px;
	height: 40px;
	margin: auto;
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.2);
	${props =>
		props.gradient &&
		css`
			box-shadow: 0 2px 10px rgba(138, 99, 223, 0.5);
			background-image: linear-gradient(100deg, #828ff7 0%, #b08df7 100%);
		`}
`;
const IconContainer = styled.div`
	text-align: center;
`;
const IconText = styled.span`
	color: white;
`;
const Detail = styled.span`
	position: absolute;
	right: 16px;
`;
const ProgressBarWrap = styled.div`
	position: absolute;
	z-index: 10;
	display: flex;
	top: 6px;
	left: 6px;
	right: 6px;
	height: 3px;
	background-color: transparent;
	width: calc(100% - 12px);
`;
const ProgressBar = styled.div`
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.2);
	position: relative;
`;
const BarSpace = styled.div`
	width: 5px;
	height: 100%;
	background-color: transparent;
`;
const ProgressFilter = styled.div`
	height: 100%;
	width: 0;
	background-color: rgba(255, 255, 255, 0.6);
	position: absolute;
	top: 0px;
	left: 0px;
	transition: width 10s linear;
`;
const SwiperWrap = styled.div`
	background: black;
	position: fixed;
	bottom: 0;
	top: 0;
	width: 100%;
	@media (min-width: 1024px) {
		position: static;
	}
`;
const GradientFilter = styled.div`
	z-index: 1;
	position: absolute;
	left: 0px;
	width: 100%;
	height: 25%;

	${props =>
		props.top &&
		css`
			top: 0px;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.3) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		`}

	${props =>
		props.bottom &&
		css`
			bottom: 0px;
			background-image: linear-gradient(
				to top,
				rgba(0, 0, 0, 0.3) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		`}
`;
const RecommendationOrFollowing = styled.div`
	position: absolute;
	z-index: 10;
	top: 22px;
	width: 100%;
	color: white;
	text-align: center;
`;
const Button = styled.button`
	background-color: transparent;
	border: none;
	color: white;
	opacity: 0.6;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	padding-right: 4px;
	padding-left: 4px;
	${props =>
		props.selected &&
		css`
			opacity: 1;
		`}
`;
const Badge = styled.span`
	background-color: white;
	border-radius: 50%;
	width: 14px;
	height: 14px;
	display: flex;
	font-size: 10px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 14px;
	margin-top: -14px;
	position: absolute;
`;
const ProfilePic = styled.div`
	// transition: background-image 0.5s ease;
`;
@inject('userStore')
@inject('postStore')
@observer
class HomeCopied extends React.Component {
	constructor(props) {
		super(props);

		// 복사된 URL 통해서 들어왔을 경우 copiedId에 id 저장
		// 1개만 로딩할 수 있도록 하기 위함
		const { match } = props;
		let copiedId;
		if (match.params && match.params.id && match.path.includes('/single/')) {
			copiedId = match.params.id;
		}

		this.state = {
			postMoreInfoData: false,
			postIdForComment: false,
			profile: false,
			postsMap: {},
			prgBarWidth: [
				{
					id: 0,
					width: '0%',
					currentDuration: 0,
				},
			],
			copiedId,
		};

		// 뮤트
		this.state.muted = true;
	}
	async componentDidMount() {
		// 프로필 초기화
		const accessToken = this.props.userStore.auth_token;
		if (accessToken) {
			const profileReq = {
				headers: accessToken
					? {
							Authorization: `Bearer ${accessToken}`,
					  }
					: false,
			};
			const profile = await userAPI
				.getMyUserProfile(profileReq)
				.then(res => {
					return res.data;
				})
				.catch(e => {
					console.error(e);
				});
			this.setState({ profile });
		}
	}
	componentWillUnmount() {
		this.props.postStore.clearAllToggle();
		this.props.userStore.clearAllToggle();
	}
	initPrgBarWidth = length => {
		let arr = [];
		for (var i = 0; i < length; i++) {
			arr.push({
				id: i,
				width: '0%',
				currentDuration: 0,
			});
		}
		this.setState({
			prgBarWidth: arr,
		});
	};
	setPrgBarWidth = (id, currentDuration, width) => {
		this.setState({
			prgBarWidth: this.state.prgBarWidth.map(prg =>
				id == prg.id
					? { ...prg, currentDuration: currentDuration, width: width }
					: prg,
			),
		});
	};
	updatePostsMap = (id, data) => {
		this.setState({
			postsMap: { ...this.state.postsMap, [id]: data },
		});
	};
	handleMoreInfo = post => {
		const { userStore } = this.props;
		let isMine = false;
		if (userStore.auth_token && post.profile) {
			if (post.profile.id == this.state.profile.id) {
				isMine = true;
			}
		}
		this.setState({
			postMoreInfoData: post,
			isMine: isMine,
		});
		this.props.postStore.toggleMoreInfo();
	};
	handleComment = postId => {
		this.setState({
			postIdForComment: postId,
		});
		this.props.postStore.toggleComment();
	};
	handleSound = () => {
		const nextValue = !this.state.muted;
		this.setState({
			muted: nextValue,
		});
		const slideId = `video_${this.state.copiedId}`;

		// DOM Element 찾기
		const slideEle = slideId && document.getElementById(slideId);

		// 위와 아래의 것의 muted를 푼다.
		if (slideEle) {
			slideEle.muted = nextValue;
		}
	};
	render() {
		const postNow = this.state.postsMap[this.state.copiedId];
		const isLoaded = postNow && postNow.title;
		const isMine =
			this.props.userStore.auth_token &&
			this.state.profile &&
			postNow &&
			postNow.profile &&
			postNow.profile.id == this.state.profile.id;

		return (
			<Wrap>
				<PostMoreInfoModal
					data={this.state.postMoreInfoData}
					bottom={'0px'}
					isMine={this.state.isMine}
				/>
				<CommentModal postId={this.state.postIdForComment} bottom={'0px'} />
				<ItemLinkRequiredModal bottom={'0px'} />
				<MyFilterModal
					bottom={'0px'}
					is_applied={
						this.state.profile ? this.state.profile.is_applied : undefined
					}
					profile={this.state.profile}
				/>
				<ReportModal bottom={'0px'} />
				<LoginRequiredModal bottom={'0px'} />
				<PostDetail
					{...this.props}
					params={{ id: this.state.copiedId }}
					active
					updatePostsMap={this.updatePostsMap}
					setPrgBarWidth={this.setPrgBarWidth}
					muted={this.state.muted}
				/>
				{!isLoaded && <SpinnerCurtain />}
				{isLoaded && (
					<ProgressBarWrap>
						{postNow.video.map((video, index) => {
							return (
								<Fragment key={index}>
									<ProgressBar>
										<ProgressFilter
											style={{
												width: this.state.prgBarWidth[index]
													? this.state.prgBarWidth[index].width
													: 0,
												transition: `width ${
													this.state.prgBarWidth[index]
														? this.state.prgBarWidth[index].currentDuration
														: 0
												}s linear`,
											}}
										/>
									</ProgressBar>
								</Fragment>
							);
						})}
					</ProgressBarWrap>
				)}
				{isLoaded && (
					<Fragment>
						<GradientFilter top />
						<GradientFilter bottom />
						<RecommendationOrFollowing>
							<Detail onClick={() => isLoaded && this.handleMoreInfo(postNow)}>
								<DetailMoreIcon />
							</Detail>
						</RecommendationOrFollowing>
						<CSSTransition
							in={!this.state.productListOn}
							timeout={300}
							classNames="example"
							unmountOnExit
						>
							<>
								<PostInfoWrap>
									<div style={{ flex: '1 1 auto', width: '100%' }}>
										{isLoaded && (
											<>
												<PostAuthor>
													<span style={{ fontStyle: 'italic', marginRight: 4 }}>
														by
													</span>
													{postNow.profile.nickname}
													{'·'}
													{postNow.edited_at}
												</PostAuthor>
												<PostTitle>{postNow.title}</PostTitle>
											</>
										)}

										<PostTags>
											{postNow.tag.length
												? postNow.tag.map((tag, index) => {
														return (
															<span
																key={index}
																className="post-tag"
																role="button"
																onClick={() => {
																	window.location.href = `/search?tag=${tag.name}`;
																}}
															>
																{' '}
																{`#${tag.name}`}{' '}
															</span>
														);
												  })
												: null}
										</PostTags>
									</div>
									{isLoaded && (
										<div
											style={{
												flex: '0 0 auto',
												width: '40px',
												marginLeft: 24,
												height: '100%',
											}}
										>
											<div>
												<ProfileImage
													isDefaultProfileImg={
														postNow.profile.image ? false : true
													}
													onClick={() => {
														window.history.back();
													}}
												>
													<ProfilePic
														style={{
															backgroundImage: `url('${
																postNow.profile.image
																	? this.state.minimizedProfileImgUrl
																		? this.state.minimizedProfileImgUrl
																		: postNow.profile.image
																	: // ? postNow.profile.image
																	  default_profile
															}')`,
														}}
													/>
												</ProfileImage>
												{!isMine ? (
													<IconContainer>
														<IconWrap>
															<LikeBtn
																noClickedColor="rgb(255,255,255)"
																fontSize="24px"
																margin="auto"
																height="auto"
																marginTop="0px"
																is_liked={postNow.like.is_liked}
																id={postNow.id}
																option="post"
															/>
														</IconWrap>
														<IconText>{postNow.like.count}</IconText>
													</IconContainer>
												) : null}
												<IconContainer>
													<IconWrap
														onClick={() =>
															isLoaded && this.handleComment(postNow.id)
														}
													>
														<DetailCommentIcon />
													</IconWrap>
													<IconText>{postNow.comment.count}</IconText>
													<IconWrap
														ref={ref => (this.muteBtn = ref)}
														onClick={this.handleSound}
													>
														<MutedIcon muted={this.state.muted} />
													</IconWrap>
												</IconContainer>
												<IconWrap
													gradient
													onClick={() => {
														if (postNow.item.length < 1) {
															alert('등록된 상품이 없습니다.');
														} else {
															this.setState({
																productListOn: true,
															});
														}
													}}
												>
													<PriceTagIcon />
													<Badge>{postNow.item.length}</Badge>
												</IconWrap>
											</div>
										</div>
									)}
								</PostInfoWrap>
							</>
						</CSSTransition>
					</Fragment>
				)}

				{postNow && (
					<ProductList
						on={this.state.productListOn}
						onClose={() => this.setState({ productListOn: false })}
						items={postNow.item}
					/>
				)}
			</Wrap>
		);
	}
}
export default HomeCopied;
