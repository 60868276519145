const movePage = ({ title, url }) => {
  const msg = {
    name: 'movePage',
    value: {
      title,
      url,
    },
  };
  window.ReactNativeWebView.postMessage(JSON.stringify(msg));
};
export default movePage;
