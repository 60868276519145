import React, { Component } from 'react';

class DetailCommentIcon extends Component {
	constructor() {
		super();
	}

	render() {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
			>
				<g>
					<g>
						<path
							fill="#fff"
							d="M4.677 6.771a2.094 2.094 0 1 1 0-4.188 2.094 2.094 0 0 1 0 4.188zM19.41 9.58l-9-9C10.05.22 9.55 0 9 0H2C.9 0 0 .9 0 2v7c0 .55.22 1.05.59 1.42l9 9c.36.359.86.58 1.41.58.55 0 1.05-.221 1.41-.59l7-7c.369-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42z"
						/>
					</g>
				</g>
			</svg>
		);
	}
}

export default DetailCommentIcon;
