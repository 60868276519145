import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { Grid } from "antd-mobile";
import { observer, inject } from "mobx-react";

import {
  Wrap,
  IndexWrap,
  MainEventWrap
} from "components/style/custom-styled-components";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";
import StyleBtn from "components/signup/styleBtn";

import * as userAPI from "axios/userAPI";
import history from "utils/history";

import back_ico from "images/back_ico_b.svg";

const NavBar = styled.div`
  width: 100%;
  height: 45px;
  padding: 15px 19px;
`;
const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SubmitBtn = styled.div`
  width: 100%;
  height: 44px;
  margin: auto;
  box-shadow: 0 2px 5px rgba(176, 141, 247, 0.5);
  border-radius: 68px;
  background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
  text-align: center;
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
  line-height: 44px;
  cursor: pointer;
`;
const StyleWrap = styled(Grid)`
  padding: 0px 26px;
  padding-bottom: 131px;
`;
const SubmitWrap = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 0px;
  padding: 16px;
  width: 100%;
  background-image: linear-gradient(
    to top,
    rgba(248, 248, 248, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;

@inject("userStore")
@observer
class SignupStyle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: this.props.userStore.auth_token,
      initData: false,
      myTags: []
    };
  }
  componentDidMount() {
    const initData = async () => {
      const styleTags = await Promise.all([
        userAPI.getStyleList().then(res => {
          return res.data;
        })
      ]);
      this.setState({
        initData: styleTags[0]
      });
    };
    initData();
  }
  isValidForm = () => {
    if (this.state.myTags.length > 0) return true;
    else {
      alert("스타일을 하나 이상 선택해주세요");
      return false;
    }
  };
  formSubmit = async () => {
    if (this.isValidForm()) {
      const req = {
        headers: {
          Authorization: `Bearer ${this.state.accessToken}`
        },
        data: {
          style: this.state.myTags
        }
      };
      const result = await Promise.all([
        userAPI
          .patchUserProfile(req)
          .then(res => {
            return res.data;
          })
          .catch(e => {
            alert(e);
            return false;
          })
      ]);
      if (result[0]) history.push("/signup/filter");
    }
  };
  handleStyleBtn = tag => {
    let array = this.state.myTags;
    var index = array.indexOf(tag);
    if (index !== -1) array.splice(index, 1);
    else array.push(tag);
    this.setState({
      myTags: array
    });
  };
  render() {
    if (this.state.accessToken) {
      if (this.state.initData) {
        return (
          <Wrap
            style={{
              paddingBottom: 0,
              backgroundColor: "#f8f8f8",
              minHeight: "100vh"
            }}
          >
            <NavBar>
              <img
                src={back_ico}
                onClick={() => {
                  history.goBack();
                }}
                style={{
                  fontSize: 19,
                  verticalAlign: "super",
                  cursor: "pointer"
                }}
              />
            </NavBar>
            <p
              style={{
                margin: 0,
                padding: "8px 16px",
                paddingTop: "16px",
                color: "#333333",
                fontFamily: "Noto Sans CJK KR",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "32px"
              }}
            >
              좋아하는 스타일을 <br /> 설정해주세요
            </p>
            <p
              style={{
                marginBottom: 36,
                padding: "2px 16px",
                color: "#999999",
                fontFamily: "Noto Sans CJK KR",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "24px"
              }}
            >
              회원님 취향에 맞는 컨텐츠를 추천해드릴게요
            </p>
            <FormWrap>
              <StyleWrap
                data={this.state.initData}
                columnNum={2}
                hasLine={false}
                activeStyle={false}
                itemStyle={{
                  height: this.props.height ? this.props.height : 56
                }}
                renderItem={(tag, index) => {
                  return (
                    <StyleBtn
                      key={index}
                      handleStyleBtn={this.handleStyleBtn}
                      data={tag}
                    />
                  );
                }}
              ></StyleWrap>
              <SubmitWrap>
                <SubmitBtn onClick={this.formSubmit}> 저장하기 </SubmitBtn>
              </SubmitWrap>
            </FormWrap>
          </Wrap>
        );
      } else {
        return <LoadingSpinner />;
      }
    } else {
      history.push("/login");
      return <Fragment />;
    }
  }
}
export default SignupStyle;
