import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { Icon } from "antd";

import history, { openNewWindow } from "utils/history";

const Wrapper = styled.div`
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(99deg, #8ea0fb 0%, #82dbfa 100%);
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 0 10px 10px;
  cursor: pointer;
  ${props =>
    props.mode == "event" &&
    css`
      border-radius: 10px;
    `}
`;
const SubWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  ${props =>
    props.mode == "event" &&
    css`
      top: 25px;
    `}
`;
const EventImg = styled.div`
  display: inline-block;
  width: 44px;
  height: 44px;
  margin: 18px 0px;
  margin-left: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  background-size: cover;
  border-radius: 50%;
`;
const Content = styled.div`
  position: absolute;
  top: 19px;
  left: 70px;
`;
const Title = styled.p`
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 15px;
  font-weight: 700;
  max-width: 140px;
  text-overflow: ellipsis;
  word-break: keep-all;
  overflow: hidden;
  white-space: nowrap;
  margin: 0px;
  margin-bottom: 2px;
`;
const SubTitle = styled.p`
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
  max-width: 200px;
  text-overflow: ellipsis;
  word-break: keep-all;
  overflow: hidden;
  white-space: nowrap;
  margin: 0px;
`;
const MoreBtn = styled.div`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 20px;
  text-align: center;
  width: 52px;
  height: 20px;
  line-height: 20px;
  border-radius: 14px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  color: rgb(255, 255, 255);
  font-family: "Noto Sans CJK KR";
  font-size: 10px;
  font-weight: 500;
`;

export default class MainEventCard extends React.Component {
  render() {
    const data = this.props.data;
    if (data.type == 1) {
      // 이미지 긴배너
      return (
        <Wrapper
          mode={this.props.mode}
          style={{ backgroundImage: "url(" + data.image + ")" }}
          onClick={() => {
            openNewWindow(data.link);
          }}
        >
          {/*
            		<EventImg style={{ backgroundImage:'url('+this.props.img+')' }}> </EventImg>
                    <div>

                    </div>
                */}
        </Wrapper>
      );
    } else if (data.type == 2) {
      // 이미지 박스배너
      return (
        <Wrapper
          mode={this.props.mode}
          style={{ backgroundImage: "url(" + data.image + ")" }}
          onClick={() => {
            openNewWindow(data.link);
          }}
        >
          {/*
                    <EventImg style={{ backgroundImage:'url('+this.props.img+')' }}> </EventImg>
                    <div>

                    </div>
                */}
        </Wrapper>
      );
    } else if (data.type == 3) {
      // 그라데이션 이벤트배너
      return (
        <Wrapper
          mode={this.props.mode}
          onClick={() => {
            history.push(`/event/${data.event.id}`);
          }}
          style={{
            backgroundImage: `linear-gradient(${data.color_angle}, #${data.color_1} ${data.color_percentage_1}%, #${data.color_2} ${data.color_percentage_2}%)`
          }}
        >
          <SubWrap mode={this.props.mode}>
            <EventImg
              style={{ backgroundImage: `url('${data.event.store.image}')` }}
            >
              {" "}
            </EventImg>
            <Content>
              <Title> {data.event.title} </Title>
              <SubTitle> {data.event.content} </SubTitle>
            </Content>
            <MoreBtn>
              <span>자세히</span> <Icon type="right" />
            </MoreBtn>
          </SubWrap>
        </Wrapper>
      );
    } else {
      return <Fragment />;
    }
  }
}
