import React from 'react';
import axios from 'axios';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import Loader from 'components/loadingSpinner/spinnerCurtain';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import clap from 'images/clap.png';
import * as userAPI from 'axios/userAPI';
import * as postAPI from 'axios/postAPI';
import history from 'utils/history';
import moment from 'moment';
import clsx from 'clsx';
import back_ico from 'images/back_ico_w.svg';
import Select from 'components/Select';
import Avatars from 'components/Avatars';
import UserList from 'components/UserList';
import EllipsisText from 'components/EllipsisText';
import getMidnightMoment from 'utils/getMidnightMoment';

const NavWrap = styled.div`
  padding-top: 34px;
  z-index: 10;
  background: #ff854f;
  width: 100%;
  max-width: 1024px;
  // transition: 0.5s;
  ${(props) =>
    props.toggle &&
    css`
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    `}
`;
const FilterText = styled.span`
  color: #bebebe;
  font-family: 'Noto Sans CJK KR';
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.name == props.clicked &&
    css`
      color: #333333;
      font-family: 'Noto Sans CJK KR';
      font-size: 15px;
      font-weight: 700;
    `}
`;

const IconWrap = styled.div`
  color: white;
  fill: white;
  cursor: pointer;
  margin-bottom: 10px;
  :hover {
    opacity: 0.8;
  }
`;

const base = 'https://star-api.lay-er.me';

const INIT = 'INIT';
@inject('cacheStore')
@inject('userStore')
@inject('postStore')
@observer
class MyPage extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      initData: this.props.cacheStore.myProfileData,
      eventBanner: this.props.cacheStore.myEventBanner,
      accessToken: this.props.userStore.auth_token,
      changed: false,
      productCategoryModalOpen: false,
      posts: [],
      next: INIT,
      page: 0,
      mission: [],
      like: 0,
      updating: false,
      dDaySelectOptions: [],
      selectedDday: undefined,
      userListModalOn: false,
    };
  }
  async componentDidMount() {
    // 토픽 정보 요청 시작
    const { search } = window.location;
    let missionTitle;
    let mission;
    let firstMission;
    const missionTitleRegExp = /title=(.+?)(?=(\s|&|$))/.exec(search);
    if (missionTitleRegExp) {
      missionTitle = missionTitleRegExp[1];
      try {
        const missionReq = await axios.get(
          `${base}/missions?title=${missionTitle}`
        );
        mission = missionReq.data;
        firstMission = mission.length > 0 ? mission[0] : null;
        const reg = /scroll=(\d+)/.exec(window.location.search);
        if (reg) {
          const scroll = Number(reg[1]);
          setTimeout(() => {
            this.scrollTo(scroll);
          }, 300);
        }
      } catch (e) {
        console.error(e);
        window.location.href = '/';
      }
    }
    const reversed = [...mission].reverse();

    let startDate, elapsedDays;

    const firstMissionCreatedAt = getMidnightMoment(firstMission.created_at);
    startDate = new Date(
      firstMissionCreatedAt.years(),
      firstMissionCreatedAt.month(),
      firstMissionCreatedAt.dates()
    );
    const today = getMidnightMoment(new Date());

    elapsedDays = moment(today).diff(startDate, 'days');
    const missionOver = elapsedDays > firstMission.days;

    if (missionOver) elapsedDays = firstMission.days;

    const dDaySelectOptions = Array.from(
      { length: elapsedDays + 1 },
      (_, i) => firstMission.days - i
    ).map((day, i) => ({
      text: i === 0 ? 'free-day' : `D-${day + 1}`,
      value: day,
    }));

    const dDay = `D-${firstMission.days - elapsedDays + 1}`;

    const posts = await this.getPostList({ mission });

    let user;
    try {
      user = await userAPI
        .getMyUserProfile({
          mode: 'main',
          headers: {
            Authorization: `Bearer ${this.props.userStore.auth_token}`,
          },
        })
        .then((res) => {
          return res.data;
        });
    } catch (e) {}
    const firstDdate = getMidnightMoment(firstMission.created_at);
    firstDdate.add(1, 'days');

    let yesterday = getMidnightMoment();
    yesterday.subtract(1, 'days');

    const userHasUploadedContent = user
      ? posts.find(({ profile }) => profile && profile.id === user.id)
      : false;

    this.setState({
      like: mission ? mission[0].like : 0,
      mission: reversed,

      dDaySelectOptions,
      selectedDday:
        new URL(window.location.href).searchParams.get('selectedDday') ||
        dDaySelectOptions[dDaySelectOptions.length - 1].value,
      disabled: missionOver,
      message: missionOver
        ? '미션이 종료되었습니다.'
        : dDaySelectOptions.length === 1
        ? 'Free day는 미션 시작 전 자유롭게 포스팅을 하는 날입니다! 내일부터 미션이 시작됩니다!'
        : dDaySelectOptions.length === 2
        ? `두근두근 미션 첫날! 포기하지 말고 끝까지 성공하세요! 화이팅!👍`
        : userHasUploadedContent
        ? `오늘은 ${dDay}입니다.`
        : `D-${dDay} 늦지 않았어요! 지금 참여해 보세요!`,
    });
    // 토픽 정보 요청 종료

    this.scrollEvent = this.getScrollEle().addEventListener(
      'scroll',
      this.scrollHandler
    );
  }
  getFirstMission = () => {
    const { mission } = this.state;
    const firstMission =
      mission.length > 0 ? mission[mission.length - 1] : null;
    return firstMission;
  };
  getRemainedDate = () => {
    const firstMission = this.getFirstMission();
    if (firstMission) {
      const firstDate = getMidnightMoment(firstMission.created_at);
      const today = getMidnightMoment();
      console.log(firstDate, today);
      const days = firstMission.days;

      if (today.diff(firstDate, 'days') > days) return 0;
      return days - today.diff(firstDate, 'days');
    } else {
      return 0;
    }
  };
  getSelectedDdayDate = () => {
    const { selectedDday } = this.state;
    const firstMission = this.getFirstMission();
    if (firstMission) {
      const firstDate = getMidnightMoment(firstMission.created_at);
      const elapsed = firstMission.days - selectedDday;
      firstDate.add(elapsed, 'days');
      return firstDate;
    }
    return null;
  };

  toSns = (url) => {
    if (url) window.open(url, '_blank');
  };
  componentWillUnmount() {
    this.getScrollEle().removeEventListener('scroll', this.scrollHandler);
    this._isMounted = false;
    this.props.cacheStore.setMyProfileData(this.state.initData);
    this.props.cacheStore.setMyEventBanner(this.state.eventBanner);
    this.props.cacheStore.setUserProfileData(this.state.initData);
  }
  scrollHandler = () => {
    const { scrollHeight, scrollTop, offsetHeight } =
      document.getElementById('SCROLL_WRAP');
    const maxScroll = scrollHeight - offsetHeight;
    if (maxScroll - scrollTop < 100) {
      this.getPostList({ mission: this.state.mission });
    }
  };
  handleClickAdd = () => {
    this.setState({
      productCategoryModalOpen: true,
    });
  };
  getScrollEle = () => document.getElementById('SCROLL_WRAP');
  scrollTo = (number) => (this.getScrollEle().scrollTop = number);
  getScrollY = () => {
    const ele = this.getScrollEle();
    return ele.scrollTop;
  };
  getPostList = async ({ mission, renew } = { mission: [], renew: false }) => {
    // 포스트를 요청하고 상태를 업데이트 한다.
    // 프로필 Id로 필터링한다.
    if (!this.state.loading) {
      this.setState({ loading: true });
      const userStore = this.props;

      const Authorization = `Bearer ${userStore.auth_token}`;
      const headers = userStore.auth_token ? { Authorization } : undefined;
      const posts = (
        await Promise.all(
          mission.map(async ({ postId }) => {
            if (postId) {
              try {
                const req = {
                  id: postId,
                  headers,
                };
                const postData = await postAPI
                  .getPost(req)
                  .then((res) => {
                    return res.data;
                  })
                  .catch((e) => {
                    console.error(e);
                  });
                return postData;
              } catch (e) {}
            }
          })
        )
      ).filter((o) => o);
      this.setState({ loading: false, posts });
      return posts;
    }
  };
  handleLike = async () => {
    if (this.props.userStore.getTokenOrToggleLogin() && !this.state.updating) {
      this.setState({ updating: true });
      const id = this.state.mission[0].id;
      const prev = await axios.get(`${base}/missions/${id}`);
      const next = prev.data.like + 1;
      this.setState({
        like: next,
      });
      await axios.put(`${base}/missions/${id}`, { like: next });
      this.setState({ updating: false });
    }
  };
  isSameDate = (dateA, dateB) =>
    new Set(
      [new Date(dateA), dateB].map((date) => moment(date).format('YYYYMMDD'))
    ).size === 1;
  render() {
    const { classes } = this.props;

    const firstMission = this.getFirstMission();
    let missionName, missionDescription, missionDays;
    if (firstMission) {
      missionName = firstMission.title;
      missionDescription = firstMission.description;
      missionDays = firstMission.days;
    }
    const missionLike = this.state.like;
    const notLogin = !this.props.userStore.auth_token;

    if (!this.state.mission) return <div />;

    const profiles = this.state.posts
      .filter((post) =>
        this.isSameDate(post.created_at, this.getSelectedDdayDate())
      )
      .map(({ profile }) => profile)
      .reduce((acc, val) => {
        if (!acc.find((item) => item.id === val.id)) acc.push(val);
        return acc;
      }, []);
    console.log(profiles);
    return (
      <Wrap
        style={{
          paddingBottom: 0,
          height: '-webkit-fill-available',
          // backgroundColor: "rgb(248,248,248)",
          overflowY: 'hidden',
        }}
      >
        <LoginRequiredModal bottom={'0px'} />
        <IndexWrap
          id="SCROLL_WRAP"
          style={{
            paddingTop: 0,
            backgroundColor: '#f8f8f8',
          }}
        >
          <NavWrap>
            <div style={{ paddingLeft: 22 }}>
              <IconWrap key="iconback" onClick={() => window.history.back()}>
                <img
                  src={back_ico}
                  style={{
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                  }}
                />
              </IconWrap>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, paddingTop: 22, paddingBottom: 16 }}>
                  <div style={{ fontSize: 18, color: 'white' }}>
                    <strong>{missionName}</strong>
                  </div>
                  <div style={{ color: 'white', fontSize: 13 }}>
                    <p>
                      {this.getRemainedDate()}일 남음 / {missionDays}일
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: 24,
                  }}
                  role="button"
                  onClick={this.handleLike}
                >
                  <img src={clap} style={{ width: 40, height: 40 }} />
                  <p style={{ color: 'white', fontSize: 13 }}>
                    <strong>{missionLike}</strong> 응원
                  </p>
                </div>
              </div>
              {missionDescription && missionDescription !== '' ? (
                <EllipsisText
                  text={missionDescription}
                  maxLines={3}
                  backgroundColor="#ff854f"
                  style={{ paddingRight: 22, marginBottom: 12 }}
                />
              ) : null}
            </div>
            {this.state.dDaySelectOptions && (
              <div
                style={{
                  display: 'flex',
                  padding: '2px 8px',
                  fontSize: 14,
                  color: '#959dad',
                  alignItems: 'center',
                  background: 'white',
                }}
              >
                <Avatars
                  style={{ flex: 1 }}
                  profiles={profiles}
                  onClick={() => {
                    this.setState({
                      userListModalOn: true,
                    });
                  }}
                />

                <Select
                  items={this.state.dDaySelectOptions}
                  value={this.state.selectedDday}
                  onClick={(value) => {
                    this.setState({
                      selectedDday: value,
                    });

                    const url = new URL(window.location.href);
                    url.searchParams.set('selectedDday', value);

                    history.replace(`${window.location.pathname}${url.search}`);
                  }}
                />
              </div>
            )}
          </NavWrap>
          <div style={{ overflowY: 'scroll' }}>
            <div className={classes.grid}>
              {this.state.posts &&
                this.state.posts
                  .filter(({ created_at }) => {
                    const selectedDdate = this.getSelectedDdayDate();
                    if (selectedDdate) {
                      return (
                        getMidnightMoment(created_at).date() ===
                        getMidnightMoment(selectedDdate).date()
                      );
                    } else return false;
                  })
                  .map(({ id, thumbnail_image, profile, ...rest }, i) => {
                    return (
                      <div
                        key={`${id}-${i}`}
                        className={clsx(classes.imgWrapper, {
                          [classes.noBorderImgWrapper]: i % 3 === 2,
                        })}
                      >
                        <a
                          onClick={() => {
                            let prevSearch = window.location.search.replace(
                              /&scroll=\d+/g,
                              ''
                            );
                            prevSearch = prevSearch.replace(/scroll=\d+/g, '');
                            let search =
                              window.location.search === ''
                                ? '?'
                                : `${prevSearch}&`;
                            history.replace(
                              window.location.pathname +
                                search +
                                `scroll=${this.getScrollY()}`
                            );
                            history.push(`/post/${id}`);
                          }}
                        >
                          <img src={thumbnail_image} className={classes.img} />
                          {profile ? (
                            <div className={classes.overlay}>
                              <div className={classes.overlaiedNickname}>
                                {profile.nickname}
                              </div>
                            </div>
                          ) : null}
                        </a>
                      </div>
                    );
                  })}
            </div>
          </div>
          {this.state.loading && <Loader />}
        </IndexWrap>
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="add"
          disabled={this.state.disabled}
          onClick={() => {
            if (notLogin) {
              history.push('/login');
            } else {
              const title = this.state.mission && this.state.mission[0].title;
              const days = this.state.mission && this.state.mission[0].days;
              let regSumId = /sumId=(\d+)(?=\s|&|$)/.exec(
                window.location.search
              );
              if (regSumId) {
                window.location.href = `/new-post?sum=${
                  regSumId[1]
                }&mission=${encodeURIComponent(
                  title
                )}&days=${encodeURIComponent(days)}`;
              }
            }
          }}
        >
          <AddIcon />
        </Fab>

        {this.state.message === '' ? null : (
          <div className={classes.message}>
            <span>{this.state.message}</span>
          </div>
        )}
        {this.state.userListModalOn && (
          <UserList
            onClose={() => {
              this.setState({ userListModalOn: false });
            }}
            profiles={profiles}
          />
        )}
      </Wrap>
    );
  }
}
export default withStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: 48,
    right: theme.spacing(2),
  },
  grid: { display: 'flex', flexFlow: 'row wrap' },
  imgWrapper: {
    width: '33.333333%',
    paddingTop: '33.333333%',
    position: 'relative',
    borderBottom: '1px solid white',
    borderRight: '1px solid white',
  },
  overlay: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    fontSize: 14,
    fontWeight: 500,
  },
  overlaiedNickname: {
    backgroundImage:
      'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%)',
    width: '100%',
    height: 36,
    color: 'white',
    paddingLeft: 16,
  },
  noBorderImgWrapper: {
    borderRight: 'none',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  intro: {
    padding: 16,
    paddingBottom: 16,
    borderBottom: '1px solid #f2f2f2',
  },
  tabWrapper: {
    flexGrow: 1,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabs: {
    width: '100%',
  },
  tab: {
    width: '50%',
    fontSize: 16,
    fontWeight: 700,
  },
  message: {
    display: 'inline-block',
    position: 'absolute',
    background: '#efefef',
    borderRadius: '8px 8px 0px 8px',
    bottom: 84,
    right: theme.spacing(10),
    padding: '4px 8px',
    maxWidth: '60%',
  },
}))(MyPage);
