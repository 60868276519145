import React, { Fragment } from 'react';
import { Icon } from 'antd';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';

import LikeBtn from 'components/likeBtn';

import * as postAPI from 'axios/postAPI';

import history from 'utils/history';
import default_profile from 'images/default_profile.png';

const CardWrap = styled.div`
  word-break: break-word;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
  ${(props) =>
    props.mode == 'reply' &&
    css`
      margin-top: 16px;
      margin-bottom: 0px;
    `}
  p {
    margin-bottom: 0px !important;
  }
  div.card-text-wrap {
    margin-right: 36px;
    ${(props) =>
      props.mode == 'reply' &&
      css`
        margin-right: 0px;
      `}
    width:100%;
    flex: 1 1 auto;
    p {
      font-size: 14px;
    }
    span.comment-nickname {
      cursor: pointer;
      :hover {
        opacity: 0.8;
      }
    }
    span.comment-small {
      color: #bebebe;
      font-family: 'Noto Sans CJK KR';
      font-size: 12px;
      font-weight: 500;
    }
    span.reply-parent {
      font-size: 14px;
      color: #333333;
    }
  }
  div.card-icon-wrap {
    flex: 0 0 auto;
    height: fit-content;
    position: absolute;
    right: 16px;
    p {
      color: #bebebe;
      font-family: 'Noto Sans CJK KR';
      font-size: 12px;
      font-weight: 500;
      text-align: center;
    }
  }
  div.card-text-wrap.card-reply {
    margin-left: 52px;
  }
`;

const ProfileImage = styled.div`
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  display: flex;
  margin-right: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.isDefaultProfileImg &&
    css`
      background-color: rgb(248, 248, 248);
    `}
  ${(props) => props.mode == 'reply' && css``}
  div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    border: 1px solid #ebebeb;
    ${(props) =>
      props.isDefaultProfileImg &&
      css`
        margin: auto;
        width: 20px;
        height: 20px;
        border-radius: 0;
      `}
    ${(props) =>
      props.isDefaultProfileImg &&
      props.mode == 'reply' &&
      css`
        width: 10px;
        height: 10px;
      `}
  }
`;

class CommentReplyCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      childData: false,
      replyShow: false,
    };
  }
  componentDidMount() {
    this.handleShowReply();
  }
  async componentDidUpdate(prevProps) {
    // if (this.props !== prevProps) {
    //   if (this.props.data.child > 0) {
    //     if (!this.state.replyShow) {
    //       const req = {
    //         parent: this.props.data.id,
    //       };
    //       const childData = await Promise.all([
    //         postAPI.getPostComments(req).then((res) => {
    //           return res.data;
    //         }),
    //       ]);
    //       this.setState({
    //         replyShow: !this.state.replyShow,
    //         childData: childData[0].data,
    //       });
    //     } else {
    //       this.setState({
    //         replyShow: !this.state.replyShow,
    //       });
    //     }
    //   } else {
    //     this.setState({
    //       childData: false,
    //     });
    //   }
    // }
  }
  handleShowReply = async () => {
    if (this.props.data.child > 0) {
      if (!this.state.replyShow) {
        const req = {
          parent: this.props.data.id,
        };
        const childData = await Promise.all([
          postAPI.getPostComments(req).then((res) => {
            return res.data;
          }),
        ]);
        this.setState({
          replyShow: !this.state.replyShow,
          childData: childData[0].data,
        });
      } else {
        this.setState({
          replyShow: !this.state.replyShow,
        });
      }
    } else {
      this.setState({
        childData: false,
      });
    }
  };
  toProfile = (id) => {
    history.push(`/profile/${id}`);
  };
  render() {
    const { data, parent, profile, handleRemove, voteData } = this.props;
    // console.log(data);
    const isMine = profile && data.profile && data.profile.id === profile.id;

    const [pollA, pollB] = voteData
      ? [voteData.aUsers, voteData.bUsers].map((users, i) => {
          let option = i === 0 ? voteData.optionA : voteData.optionB;
          if (users.includes(data.profile.id)) {
            return option;
          }
        })
      : [];
    return (
      <Fragment>
        <CardWrap mode={this.props.mode}>
          <ProfileImage
            onClick={() => this.toProfile(data.profile.id)}
            mode={this.props.mode}
            isDefaultProfileImg={data.profile.image ? false : true}
          >
            <div
              style={{
                backgroundImage: `url('${
                  data.profile.image ? data.profile.image : default_profile
                }')`,
              }}
            />
          </ProfileImage>
          <div className="card-text-wrap">
            <p>
              {' '}
              <span
                className="comment-nickname"
                onClick={() => this.toProfile(data.profile.id)}
              >
                {' '}
                {data.profile.nickname}{' '}
              </span>{' '}
              {pollA || pollB ? (
                <span
                  style={{
                    width: 100,
                    textOverflow: 'ellipsis',
                    color: pollA ? '#5574f7' : '#e56e85',
                  }}
                >
                  {pollA || pollB}
                </span>
              ) : null}
              <span
                className="comment-small"
                style={{ marginLeft: '5px', fontSize: '10px' }}
              >
                {data.edited_at}
              </span>
            </p>
            <p
              style={{ fontWeight: 500 }}
              onClick={() => {
                this.props.handleReply(data);
              }}
            >
              <span className="reply-parent">@{parent.profile.nickname}</span>{' '}
              {data.is_blocked ? '삭제된 댓글입니다.' : data.content}
            </p>
            <p>
              {' '}
              <span
                className="comment-small"
                style={{ marginRight: '14px' }}
                onClick={this.handleShowReply}
              >{`답글 ${data.child}개`}</span>{' '}
              {!data.is_blocked && (
                <>
                  <span
                    className="comment-small"
                    onClick={() => {
                      this.props.handleReply(data);
                    }}
                    style={{ marginRight: '14px', cursor: 'pointer' }}
                  >
                    답글달기
                  </span>{' '}
                  {isMine && handleRemove && (
                    <span
                      className="comment-small"
                      onClick={() => {
                        this.props.handleRemove(data);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      삭제
                    </span>
                  )}
                </>
              )}
            </p>
          </div>
          <div className="card-icon-wrap">
            <LikeBtn
              is_liked={data.like.is_liked}
              mode="comment"
              id={data.id}
              option="comment"
              marginLeft="-4px"
              textMarginTop="-14px"
              height="29px"
              indicate={true}
              count={data.like.count}
            />
          </div>
        </CardWrap>
        {this.state.replyShow && (
          <Fragment>
            {this.state.childData.map((child, index) => {
              return (
                <CommentReplyCard
                  key={child.id}
                  data={child}
                  profile={profile}
                  voteData={voteData}
                  mode="reply"
                  handleRemove={handleRemove}
                  handleReply={this.props.handleReply}
                  parent={data}
                />
              );
            })}
          </Fragment>
        )}
      </Fragment>
    );
  }
}
export default CommentReplyCard;
