import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  layout: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 10000,
  },
  root: {
    background: 'white',
    width: '100%',
    maxWidth: '90%',
    borderRadius: 8,
  },
  title: {
    fontSize: 16,
    fontWeight: 900,
    textAlign: 'center',
    marginTop: 32,
    marginBottom: 12,
  },
  white: {
    color: 'white',
  },
  textField: {
    paddingTop: 4,
    borderRadius: 8,
    background: 'rgba(120, 132, 158, 0.08)',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
    '& input': {
      paddingTop: 12,
      paddingBottom: 18,
      textAlign: 'center',
    },
  },
  fieldSet: {
    border: 'none !important',
  },
  pollFieldRoot: {
    width: '47%',
    '& fieldset': {
      border: 'none',
    },
    background: 'white',
  },
  pollField: {
    padding: 0,
    width: '100%',
    height: 100,
    textAlign: 'center',
  },
  votedToField: {
    background: '#1f75fe',
    borderRadius: 8,
    color: 'white !important',
  },
  body: {
    paddingTop: 24,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 32,

    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonNegative: {
    color: 'black',
    background: '#efefef',
    height: 48,
    width: '50%',
    borderRadius: 8,
  },
  button: {
    color: 'white',
    background: '#1f75fe',
    height: 48,
    width: '50%',
    borderRadius: 8,
    '&:hover': {
      background: '#1f75fe',
    },
  },
  vs: {
    marginTop: -20,
    background: 'black',
    borderRadius: '100%',
    color: 'white',
    position: 'absolute',
    left: 0,
    right: 0,
    width: 44,
    margin: 'auto',
    height: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 'bold',
  },
  disabled: {
    color: 'black',
    fontWeight: 'bold',
  },
  box: {
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
  },
});

const Modal = ({
  onList,
  open,
  onClose,
  value,
  title,
  onTitleChange,
  pollOptionA,
  onPollOptionAChange,
  pollOptionB,
  onPollOptionBChange,
  onSubmit,
  onRemove,
  input,
  onVote,
  votedTo,
  result,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!open) {
      onClose();
    }
  }, [open]);
  if (!open) return null;
  if (onList) {
    return (
      <div className={classes.layout}>
        <div className={clsx(classes.title, classes.white)}>{title}</div>
        <div className={classes.body}>
          <div
            className={clsx(
              classes.pollFieldRoot,
              classes.textField,
              classes.disabled,
              votedTo === 'a' && classes.votedToField,
              classes.box
            )}
            onClick={() => onVote('a')}
          >
            <div>{pollOptionA}</div>
            {result && <div>{result.a}%</div>}
          </div>
          <div
            className={clsx(
              classes.pollFieldRoot,
              classes.textField,
              classes.disabled,
              votedTo === 'b' && classes.votedToField,
              classes.box
            )}
            onClick={() => onVote('b')}
          >
            <div>{pollOptionB}</div>
            {result && <div>{result.b}%</div>}
          </div>
          <div className={classes.vs}>VS</div>
        </div>
      </div>
    );
  }
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.root }}>
      <div>
        <div className={classes.title}>
          {input ? (
            <TextField
              className={classes.textField}
              value={title}
              fullWidth
              variant="outlined"
              placeholder="투표 주제 입력 (최대 20자)"
              onChange={(e) =>
                e.target.value.length > 20 ? () => {} : onTitleChange(e)
              }
            />
          ) : (
            title
          )}
        </div>
        <div className={classes.body}>
          {input ? (
            <>
              <TextField
                classes={{
                  root: clsx(classes.pollFieldRoot, classes.textField),
                  fieldSet: classes.fieldSet,
                }}
                multiline
                inputProps={{
                  className: clsx(
                    classes.pollField,
                    classes.disabled,
                    votedTo === 'a' && classes.votedToField
                  ),
                }}
                value={pollOptionA}
                variant="outlined"
                placeholder="투표안 1 입력"
                onChange={(e) =>
                  e.target.value.length > 16 ? () => {} : onPollOptionAChange(e)
                }
                disabled={!input}
                onClick={() => onVote('a')}
              />
              <TextField
                classes={{
                  root: clsx(classes.pollFieldRoot, classes.textField),
                  fieldSet: classes.fieldSet,
                }}
                multiline
                inputProps={{
                  className: clsx(
                    classes.pollField,
                    classes.disabled,
                    votedTo === 'b' && classes.votedToField
                  ),
                }}
                value={pollOptionB}
                variant="outlined"
                placeholder="투표안 2 입력"
                onChange={(e) =>
                  e.target.value.length > 16 ? () => {} : onPollOptionBChange(e)
                }
                disabled={!input}
                onClick={() => onVote('b')}
              />
            </>
          ) : (
            <>
              <div
                className={clsx(
                  classes.pollFieldRoot,
                  classes.textField,
                  classes.disabled,
                  votedTo === 'a' && classes.votedToField,
                  classes.box
                )}
                onClick={() => onVote('a')}
              >
                <div>{pollOptionA}</div>
                {result && <div>{result.a}%</div>}
              </div>
              <div
                className={clsx(
                  classes.pollFieldRoot,
                  classes.textField,
                  classes.disabled,
                  votedTo === 'b' && classes.votedToField,
                  classes.box
                )}
                onClick={() => onVote('b')}
              >
                <div>{pollOptionB}</div>
                {result && <div>{result.b}%</div>}
              </div>
            </>
          )}
          <div className={classes.vs}>VS</div>
        </div>

        {input && (
          <>
            <Button
              className={classes.buttonNegative}
              onClick={() => {
                onRemove();
              }}
            >
              삭제하기
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              onClick={onSubmit}
            >
              저장하기
            </Button>
          </>
        )}
      </div>
    </Dialog>
  );
};
export default Modal;
