import React from 'react';
import styled, { css } from 'styled-components';
import Product from './Product';

const Layout = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;
export default ({ likeList, onlyLike, list, onClick, amount, onLike }) => {
	return (
		<Layout>
			{list
				.filter((item) => (onlyLike ? likeList.includes(item.id) : item))
				.map((item) => (
					<Product
						key={item.id}
						item={item}
						onClick={() => onClick(item)}
						disabled={amount < item.CPN_PRICE}
						onLike={() => onLike(item.id)}
						like={likeList.includes(item.id)}
					/>
				))}
		</Layout>
	);
};
