import back from './가방.png';
import etc from './기타.png';
import pant from './바지.png';
import upper from './상의.png';
import skirt from './스커트.png';
import shoe from './신발.png';
import outer from './아우터.png';
import acc from './악세서리.png';
import one from './원피스.png';


export default {
	back,
	etc,
	pant,
	upper,
	skirt,
	shoe,
	outer,
	acc,
	one,
};
