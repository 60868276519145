import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";
import { Wrap, IndexWrap } from "components/style/custom-styled-components";
import ProductCategoryModal from "components/popModal/productCategoryModal";

import Footer from "components/footer";

import * as userAPI from "axios/userAPI";
import * as eventAPI from "axios/eventAPI";
import history from "utils/history";
import UserProfilePage from "./userProfilePage";

@inject("cacheStore")
@inject("userStore")
@observer
class MyPage extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      userId: null,
      initData: this.props.cacheStore.myProfileData,
      eventBanner: this.props.cacheStore.myEventBanner,
      accessToken: this.props.userStore.auth_token,
      changed: false,
      productCategoryModalOpen: false,
    };
  }
  componentWillMount() {
    if (!this.props.userStore.auth_token) {
      history.push("/login");
    }
  }
  async componentDidMount() {
    this._isMounted = true;
    const headers = {
      Authorization: `Bearer ${this.state.accessToken}`,
    };
    const req = {
      mode: "main",
      headers: headers,
    };
    const initData = await Promise.all([
      userAPI.getMyUserProfile(req).then((res) => {
        return res.data;
      }),
    ]);

    if (initData.length && initData[0].id)
      this.setState({
        userId: initData[0].id,
      });
    const eventBanner = await Promise.all([
      eventAPI.getEventBanner(req).then((res) => {
        return res.data;
      }),
    ]);
    if (this._isMounted) {
      this.setState(
        {
          initData: initData[0],
          eventBanner: eventBanner,
          changed: true,
        },
        () => {
          this.props.cacheStore.setMyProfileData(this.state.initData);
        }
      );
    }
  }

  toSns = (url) => {
    if (url) window.open(url, "_blank");
  };
  componentWillUnmount() {
    this._isMounted = false;
    this.props.cacheStore.setMyProfileData(this.state.initData);
    this.props.cacheStore.setMyEventBanner(this.state.eventBanner);
    this.props.cacheStore.setUserProfileData(this.state.initData);
  }
  handleClickAdd = () => {
    this.setState({
      productCategoryModalOpen: true,
    });
  };
  render() {
    return (
      <Wrap
        style={{
          paddingBottom: 0,
          height: "-webkit-fill-available",
          backgroundColor: "rgb(248,248,248)",
          overflowY: "hidden",
        }}
      >
        <UserProfilePage
          // onpopstate는 search에서만 사용
          postsSwiperClass={"myPage-posts-swiper"}
          id={this.state.userId}
          mine
        />
        <Footer onClickAdd={this.handleClickAdd} />
      </Wrap>
    );
  }
}
export default MyPage;
