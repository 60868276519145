import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { Icon } from "antd";
import { Grid, Tabs, WhiteSpace } from "antd-mobile";
import { observer, inject } from "mobx-react";

import { Wrap, IndexWrap } from "components/style/custom-styled-components";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";
import MySwitch from "components/mySwitch";
import NotificationTabs from "components/myNotification/notificationTabs";
import NotOnServiceModal from "components/popModal/notOnServiceModal";

import Footer from "components/footer";
import * as userAPI from "axios/userAPI";
import history from "utils/history";
import back_ico from "images/back_ico_b.svg";
import search_ico from "images/search_ico.svg";

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  line-height: 44px;
  height: 44px;
  width: 100%;
  max-width: 1024px;
  z-index: 3;
  top: 0;
  background: rgb(248, 248, 248);
`;
const ContentWrap = styled.div`
  color: #333333;
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  p {
    margin-bottom: 10px;
  }
`;
const SettingBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  margin-bottom: 24px;
  min-height: 104px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  color: #333333;
  font-family: "Noto Sans CJK KR";
  font-size: 15px;
  font-weight: 500;
  span {
    flex: 1 1 auto;
    display: inline-block;
    width: 100%;
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
  }
  div:last-child {
    margin-bottom: 0px;
  }
`;

@inject("userStore")
@observer
class MyNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: this.props.userStore.auth_token,
    };
  }
  componentWillMount() {
    if (!this.props.userStore.auth_token) {
      history.push("/login");
    }
  }
  componentWillUnmount() {
    this.props.userStore.clearAllToggle();
  }
  render() {
    return (
      <Wrap
        style={{
          paddingBottom: 0,
          height: "-webkit-fill-available",
          backgroundColor: "rgb(248,248,248)",
        }}
      >
        <IndexWrap style={{ paddingTop: 44, backgroundColor: "#f8f8f8" }}>
          <NotOnServiceModal bottom="0px" />
          <NavBar>
            <div style={{ width: "50%" }}>
              <img
                src={back_ico}
                onClick={() => {
                  history.goBack();
                }}
                style={{
                  marginLeft: 19,
                  width: 19,
                  verticalAlign: "middle",
                  cursor: "pointer",
                }}
              />
            </div>
          </NavBar>
          <ContentWrap>
            <NotificationTabs />
          </ContentWrap>
        </IndexWrap>
        <Footer />
      </Wrap>
    );
  }
}
export default MyNotification;
