import React from 'react';
import styled from 'styled-components';
import AllImg from 'images/categoryFilter/all.png';
import BackgroundImg from 'images/categoryFilter/background.png';
import BeautyImg from 'images/categoryFilter/beauty.png';
import HairImg from 'images/categoryFilter/hair.png';
import FashionImg from 'images/categoryFilter/fashion.png';

const Layout = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 32px;
	padding-right: 32px;
	padding-bottom: 12px;
	padding-top: 16px;
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 25%;
`;
const ImgWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 66px;
	height: 66px;
	${props => props.selected && `background-image: url("${BackgroundImg}");`}
	background-repeat: no-repeat;
	background-size: 66px 66px;
`;
const Img = styled.img`
	width: 60px;
	height: 60px;
`;
const Label = styled.span`
	color: #1e1e1e;
	font-family: 'Noto Sans CJK KR';
	font-size: 12px;
	font-weight: 700;
	margin-top: 4px;
`;
export default ({ selected, onClick }) => {
	return (
		<Layout>
			<Wrapper>
				<ImgWrapper
					selected={selected === null}
					role="button"
					onClick={() => onClick(null)}
				>
					<Img src={AllImg} />
				</ImgWrapper>
				<Label>모두</Label>
			</Wrapper>
			<Wrapper>
				<ImgWrapper
					selected={selected === 'fashion'}
					role="button"
					onClick={() => onClick('fashion')}
				>
					<Img src={FashionImg} />
				</ImgWrapper>
				<Label>패션</Label>
			</Wrapper>
			<Wrapper>
				<ImgWrapper
					selected={selected === 'beauty'}
					role="button"
					onClick={() => onClick('beauty')}
				>
					<Img src={BeautyImg} />
				</ImgWrapper>
				<Label>뷰티</Label>
			</Wrapper>
			<Wrapper>
				<ImgWrapper
					selected={selected === 'hair'}
					role="button"
					onClick={() => onClick('hair')}
				>
					<Img src={HairImg} />
				</ImgWrapper>
				<Label>헤어</Label>
			</Wrapper>
		</Layout>
	);
};
