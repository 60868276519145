import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from 'containers/layout';
import AddIcon from 'images/circleAdd.png';
import TextField from '@material-ui/core/TextField';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import clsx from 'clsx';
import Sum from 'components/sum';
import Swiper from 'react-id-swiper';
import history from 'utils/history';
import axios from 'axios';

const icon = () => <img style={{ width: 24, height: 24 }} src={AddIcon} />;
const useStyles = makeStyles((theme) => ({
  layout: {
    paddingTop: 20,
    paddingBottom: 20,
    overflowX: 'hidden',
  },
  padding: {
    paddingLeft: 28,
    paddingRight: 28,
  },
  textField: {
    paddingTop: 4,
    borderRadius: 12,
    background: '#eaeaea',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
    '& input': {
      paddingTop: 12,
      paddingBottom: 18,
    },
  },
  tabWrapper: {
    flexGrow: 1,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabs: {
    width: '100%',
  },
  tab: {
    width: '50%',
    fontSize: 16,
    fontWeight: 700,
  },
  tabWrapper: {
    borderBottom: '1px solid #ececec',
    marginBottom: 14,
  },
  title: {
    marginTop: 16,
    marginBottom: 16,
    fontSize: 18,
    fontWeight: 700,
  },
  avatar: {
    width: 72,
    height: 72,
    overflow: 'hidden',
    borderRadius: 100,
    background: 'white',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.14)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    width: '100%',
  },
  card: {
    background: 'white',
    borderRadius: 8,
    padding: 16,
    display: 'flex',
    marginBottom: 14,
    boxShadow: '0 2px 48px rgba(0, 0, 0, 0.08)',
  },
  content: {
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'left',
    justifyContent: 'center',
  },
  marginTop: {
    marginTop: 12,
  },
  sumName: { fontSize: 13, fontWeight: 500 },
  tag: { fontSize: 14, fontWeight: 700 },
  people: { textAlign: 'right', fontSize: 12, fontWeight: 700 },
  peopleNumber: { color: theme.palette.primary.main },
  footer: {
    display: 'flex',
  },
  eventDate: {
    flex: 1,
    fontSize: 12,
    fontWeight: 500,
    color: '#848484',
  },
}));
const base = 'https://star-api.lay-er.me';
const Explore = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [sums, setSums] = useState([]);
  const [sortedSums, setSortedSums] = useState([]);
  const [foundSums, setFoundSums] = useState([]);
  const [eventSums, setEventSums] = useState([]);
  const [tabIdx, setTabIdx] = useState(0);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`${base}/sums?_sort=priority:ASC`);
      const sorted = data
        .sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
        .slice(0, 10);
      setSortedSums(sorted);
      setSums(data);
    })();
  }, []);

  useEffect(() => {
    const searchValueIdx = window.location.search.indexOf('search=');

    if (searchValueIdx > -1) {
      const searchValue = window.location.search.slice(
        'search='.length + searchValueIdx
      );
      (async () => {
        const { data } = await axios.get(
          `${base}/sums?tags_contains=${searchValue}`
        );
        const founded = data;
        setFoundSums(founded);
      })();
    }
  }, [window.location.search]);
  const isSearch = window.location.href.includes('search=');
  const isEvent = tabIdx === 1;

  useEffect(() => {
    if (isEvent) {
      (async () => {
        const { data } = await axios.get(`${base}/events?hidden=false`);
        const { data: picks } = await axios.get(`${base}/picks?hidden=false`);
        console.log(data, picks);
        const sorted = picks.sort(
          (a, b) => new Date(b.end).getTime() - new Date(a.end).getTime()
        );
        setEventSums(
          sorted
            .filter(({ sum }) => Boolean(sum))
            .map(({ postId, ...item }) => ({
              ...item,
              link: `/post/${postId}`,
            }))
        );
      })();
    }
  }, [isEvent]);

  return (
    <Layout
      footer
      title="레이어 월드"
      rightIcon={icon}
      onRightIconClick={() =>
        window.open('https://forms.gle/zHftQuk3rT21Ag416')
      }
    >
      <div className={classes.layout}>
        <div className={classes.padding}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (search.length > 0) {
                if (window.location.search.includes('search=')) {
                  history.push('/event-list?search=' + search);
                } else {
                  history.replace('/event-list?search=' + search);
                }
              } else history.push('/event-list');
            }}
          >
            <TextField
              className={classes.textField}
              value={search}
              fullWidth
              variant="outlined"
              placeholder="섬 검색하기"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon style={{ color: 'grey' }} />
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </div>
        {isSearch ? (
          <div className={clsx(classes.padding, classes.marginTop)}>
            {foundSums.map(({ name, id, tags, number, followers, image }) => (
              <div
                key={name}
                className={classes.card}
                role="button"
                onClick={() => (window.location.href = `/sum/${id}`)}
              >
                <div className={classes.avatar}>
                  <img
                    className={classes.img}
                    src={image ? `${base}${image.url}` : null}
                  />
                </div>
                <div className={classes.content}>
                  <div className={classes.sumName}>{name}</div>
                  {tags.length > 0 && (
                    <div className={classes.tag}>
                      {'#' + tags.replace(/\s+/g, ' #')}
                    </div>
                  )}
                  <div className={classes.people}>
                    <span className={classes.peopleNumber}>
                      {followers || 0}
                    </span>{' '}
                    입주
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className={classes.tabWrapper}>
              <Tabs
                className={classes.tabs}
                classes={{ indicator: classes.indicator }}
                value={tabIdx}
                indicatorColor="primary"
                textColor="primary"
                onChange={(_, newValue) => {
                  setTabIdx(newValue);
                }}
                aria-label="disabled tabs example"
                centered
                TabIndicatorProps={{ children: <span /> }}
              >
                <Tab className={classes.tab} label="섬리스트" />
                <Tab className={classes.tab} label="잔치중" />
              </Tabs>
            </div>
            <div className={classes.padding}>
              {isEvent
                ? eventSums.map(({ sum, ...event }) => (
                    <div
                      key={event.id}
                      className={classes.card}
                      role="button"
                      onClick={() => (window.location.href = event.link)}
                    >
                      <div className={classes.avatar}>
                        <img
                          className={classes.img}
                          src={
                            sum && sum.image ? `${base}${sum.image.url}` : null
                          }
                        />
                      </div>
                      <div className={classes.content}>
                        <div className={classes.tag}>{event.name}</div>
                        <div className={classes.sumName}>{sum && sum.name}</div>

                        <div className={classes.footer}>
                          <div className={classes.eventDate}>
                            {event.start && isEvent
                              ? `${moment(event.start).format(
                                  'MM.DD'
                                )} ~ ${moment(event.end).format('MM.DD')}`
                              : null}
                          </div>

                          <div
                            className={classes.people}
                            role="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.location.href = `/population/${sum.id}`;
                            }}
                          >
                            <span className={classes.peopleNumber}>
                              {sum.followers || 0}
                            </span>{' '}
                            입주
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : sums.map(
                    ({ name, id, tags, number, image, followers }, i) => (
                      <div
                        key={`${i}-3`}
                        className={classes.card}
                        role="button"
                        onClick={() => (window.location.href = `/sum/${id}`)}
                      >
                        <div className={classes.avatar}>
                          <img
                            className={classes.img}
                            src={image ? `${base}${image.url}` : null}
                          />
                        </div>
                        <div className={classes.content}>
                          <div className={classes.sumName}>{name}</div>

                          <div className={classes.tag}>
                            {tags && tags.length > 0
                              ? '#' + tags.replace(/\s+/g, ' #')
                              : null}
                          </div>
                          <div className={classes.footer}>
                            {/* 비어있음 */}
                            <div className={classes.eventDate} />

                            <div
                              className={classes.people}
                              role="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                window.location.href = `/population/${id}`;
                              }}
                            >
                              <span className={classes.peopleNumber}>
                                {followers || 0}
                              </span>{' '}
                              입주
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
            </div>
          </>
        )}
      </div>
      <LoginRequiredModal bottom={'0px'} />
    </Layout>
  );
};
export default Explore;
