import { Grid, Tabs, WhiteSpace } from "antd-mobile";
import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { Icon } from "antd";
import { observer, inject } from "mobx-react";

import FollowBtn from "components/followBtn";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";
import default_profile from "images/default_profile.png";

import history from "utils/history";

import * as postAPI from "axios/postAPI";
import * as userAPI from "axios/userAPI";

const NotiCardWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
  p {
    margin-bottom: 0px !important;
  }
  p.noti-content {
    margin-right: 10px;
    color: #333333;
    word-break: keep-all;
    font-family: "Noto Sans CJK KR";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    span.highlight {
      font-family: "Noto Sans CJK KR";
      font-weight: 700;
      cursor: pointer;
      :hover {
        opacity: 0.8;
      }
    }
  }
  p.noti-created-at {
    color: #999999;
    font-family: "Noto Sans CJK KR";
    font-size: 11px;
    font-weight: 500;
  }
`;

const ProfileImage = styled.div`
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  display: flex;
  margin-right: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.isDefaultProfileImg &&
    css`
      background-color: white;
    `}
  div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    border: 1px solid #ebebeb;
    ${(props) =>
      props.isDefaultProfileImg &&
      css`
        margin: auto;
        width: 24px;
        height: 24px;
        border-radius: 0;
      `}
  }
`;
const PostThumbImg = styled.div`
  width: 42px;
  height: 60px;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

@inject("cacheStore")
@inject("userStore")
@observer
class NotiCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {}
  async componentDidMount() {}
  toProfile = (id) => {
    history.push(`/profile/${id}`);
  };
  toPost = async (id) => {
    const req = {
      id: id,
      headers: this.props.userStore.auth_token
        ? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
        : undefined,
    };
    const postData = await postAPI.getPost(req).then((res) => {
      return res.data;
    });
    this.props.cacheStore.setDetailData(postData);
    const myProfile = this.props.cacheStore.myProfileData;
    let isMine = false;
    if (myProfile) {
      if (myProfile.id == postData.profile.id) {
        isMine = true;
      }
    }
    history.push({
      pathname: `/post/${id}`,
      state: { isMine: isMine },
    });
  };
  toEvent = (id) => {
    history.push(`/event/${id}`);
  };
  render() {
    const { data } = this.props;
    if (this.props.data.type == "대댓글")
      return (
        <NotiCardWrap>
          <ProfileImage
            onClick={() => data.sender && this.toProfile(data.sender.id)}
            isDefaultProfileImg={
              data.sender && data.sender.image ? false : true
            }
          >
            <div
              style={{
                backgroundImage: `url('${
                  data.sender && data.sender.image
                    ? data.sender.image
                    : default_profile
                }')`,
              }}
            />
          </ProfileImage>
          <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
            <p className="noti-content">
              {" "}
              <span
                className="highlight"
                onClick={() => this.toProfile(data.sender.id)}
              >
                {" "}
                {`${data.sender ? data.sender.nickname : "삭제된 계정"}`}{" "}
              </span>{" "}
              <span>님이 댓글에 댓글을 달았습니다 : {data.comment}</span>
            </p>
            <p className="noti-created-at">{data.created_at}</p>
          </div>
          {data.post && data.post.thumbnail_image && (
            <div style={{ flex: "0 0 auto" }}>
              <PostThumbImg
                onClick={() => this.toPost(data.post.id)}
                style={
                  data.post
                    ? {
                        backgroundImage: `url('${data.post.thumbnail_image}')`,
                      }
                    : null
                }
              />
            </div>
          )}
        </NotiCardWrap>
      );
    else if (this.props.data.type == "댓글")
      return (
        <NotiCardWrap>
          <ProfileImage
            onClick={() => data.sender && this.toProfile(data.sender.id)}
            isDefaultProfileImg={
              data.sender && data.sender.image ? false : true
            }
          >
            <div
              style={{
                backgroundImage: `url('${
                  data.sender && data.sender.image
                    ? data.sender.image
                    : default_profile
                }')`,
              }}
            />
          </ProfileImage>
          <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
            <p className="noti-content">
              {" "}
              <span
                className="highlight"
                onClick={() => this.toProfile(data.sender.id)}
              >
                {" "}
                {`${data.sender ? data.sender.nickname : "삭제된 계정"}`}{" "}
              </span>{" "}
              <span>님이 댓글을 달았습니다 : {data.comment}</span>
            </p>
            <p className="noti-created-at">{data.created_at}</p>
          </div>
          {data.post && data.post.thumbnail_image && (
            <div style={{ flex: "0 0 auto" }}>
              <PostThumbImg
                onClick={() => this.toPost(data.post.id)}
                style={
                  data.post
                    ? {
                        backgroundImage: `url('${data.post.thumbnail_image}')`,
                      }
                    : null
                }
              />
            </div>
          )}
        </NotiCardWrap>
      );
    else if (this.props.data.type == "팔로잉") {
      return (
        <NotiCardWrap>
          <ProfileImage
            onClick={() => this.toProfile(data.sender.id)}
            isDefaultProfileImg={data && data.sender.image ? false : true}
          >
            <div
              style={{
                backgroundImage: `url('${
                  data && data.sender.image
                    ? data.sender.image
                    : default_profile
                }')`,
              }}
            />
          </ProfileImage>
          <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
            <p className="noti-content">
              {" "}
              <span
                className="highlight"
                onClick={() => this.toProfile(data.sender.id)}
              >
                {" "}
                {`${data.sender.nickname}`}{" "}
              </span>{" "}
              <span>님이 회원님을 팔로우하기 시작했습니다.</span>
            </p>
            <p className="noti-created-at">{data.created_at}</p>
          </div>
          <FollowBtn
            mode="notification"
            accessToken={this.props.accessToken}
            profile={data.sender}
            is_followed={data.is_followed}
          />
        </NotiCardWrap>
      );
    } else if (this.props.data.type == "좋아요") {
      return (
        <NotiCardWrap>
          <ProfileImage
            onClick={() => this.toProfile(data.sender.id)}
            isDefaultProfileImg={data.sender.image ? false : true}
          >
            <div
              style={{
                backgroundImage: `url('${
                  data.sender.image ? data.sender.image : default_profile
                }')`,
              }}
            />
          </ProfileImage>
          <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
            <p className="noti-content">
              {" "}
              <span
                className="highlight"
                onClick={() => this.toProfile(data.sender.id)}
              >
                {" "}
                {`${data.sender.nickname}`}{" "}
              </span>{" "}
              <span>
                {data.count > 0 ? `님 외 ${data.count}명이 ` : `님이 `}회원님의
                {` `}
                {data.comment ? "댓글" : "포스팅"}을 좋아합니다.
              </span>
            </p>
            <p className="noti-created-at">{data.created_at}</p>
          </div>
          {data.post && data.post.thumbnail_image && (
            <div style={{ flex: "0 0 auto" }}>
              <PostThumbImg
                onClick={() => this.toPost(data.post.id)}
                style={{
                  backgroundImage: `url('${data.post.thumbnail_image}')`,
                }}
              />
            </div>
          )}
        </NotiCardWrap>
      );
    } else if (this.props.data.type == "이벤트") {
      return (
        <NotiCardWrap>
          <ProfileImage
            onClick={() => this.toEvent(data.event.id)}
            isDefaultProfileImg={data.event.store.image ? false : true}
          >
            <div
              style={{
                backgroundImage: `url('${
                  data.event.store.image
                    ? data.event.store.image
                    : default_profile
                }')`,
              }}
            />
          </ProfileImage>
          <div style={{ flex: "1 1 auto", overflow: "hidden" }}>
            <p className="noti-content"> {data.event.alarm} </p>
            <p className="noti-created-at">{data.created_at}</p>
          </div>
        </NotiCardWrap>
      );
    } else {
      return <Fragment />;
    }
  }
}

export default NotiCard;
