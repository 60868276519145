import React, { Fragment } from "react";
import "antd/dist/antd.css";
import { Button, WhiteSpace, WingBlank } from "antd-mobile";
import { Icon } from "antd";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";

import LikeBtn from "components/likeBtn";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";

import * as myVideo from "utils/myVideo";
import history from "utils/history";
import getRemainTime from "utils/myCountDown";

import timer_small from "images/timer_small.png";
import like_small_img from "images/like_event_small.png";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 272px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  margin-bottom: 16px;
  cursor: pointer;
  padding-left: 16px;
  padding-top: 16px;
`;

const TitleWrap = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
`;

const StoreImg = styled.div`
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  background-size: cover;
  background-position: center;
`;
const StoreInfo = styled.div`
  margin-left: 12px;
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;

  p.event-title {
    margin: 0px;
    margin-right: 36px;
    color: #333333;
    font-family: "Noto Sans CJK KR";
    font-size: 17px;
    font-weight: 700;
    line-height: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  p.store-name {
    margin: 0px;
    color: #828ff7;
    font-family: "Noto Sans CJK KR";
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
const EventPostWrap = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  height: 124px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none !important;
  }
`;
const EventPostCard = styled.div`
  flex: 0 0 auto;
  position: relative;
  margin-right: 8px;
  width: 85px;
  height: 124px;
  background-size: cover;
  background-position: center;
  background-color: rgb(248, 248, 248);
  border-radius: 8px;
`;

const ExampleTag = styled.div`
  position: absolute;
  text-align: center;
  top: 8px;
  left: 8px;
  width: 30px;
  height: 18px;
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0);
  background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
  letter-spacing: -0.24px;
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 500;
`;
const FooterWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px;
  height: 45px;
  margin-left: -16px;
  margin-top: 16px;
  border-top: solid 1px #eeeeee;
  color: #bebebe;
  font-family: "Noto Sans CJK KR";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.26px;
  span {
    margin: 0px;
    margin-top: -0.02px;
  }
  span.remain-time {
    flex: 0 0 auto;
  }
  span.participant-count {
    flex: 1 1 auto;
    width: 100%;
    text-align: right;
  }
`;
const IconWrap = styled.div`
  flex: 0 0 auto;
  height: fit-content;
  position: absolute;
  right: 18px;
  top: 16px;
  p {
    color: #bebebe;
    font-family: "Noto Sans CJK KR";
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }
`;
const TimerImg = styled.div`
  flex: 0 0 auto;
  margin-right: 2px;
  width: 12px;
  height: 12px;
  display: inline-block;
  background-size: cover;
  background-position: center;
`;

class EventCard extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = true;
    this.duration = getRemainTime(this.props.data.ended_at);
    this.state = {
      remainedTime:
        this.duration.days() <= 2
          ? myVideo.pad(this.duration.days() * 24 + this.duration.hours(), 2) +
            ":" +
            myVideo.pad(this.duration.minutes(), 2) +
            ":" +
            myVideo.pad(this.duration.seconds(), 2)
          : `${this.duration.days()}일`
    };
  }
  componentWillMount() {
    this.setState({
      remainedTimeInterval: setInterval(() => {
        this.duration = getRemainTime(this.props.data.ended_at);
        let remainedTime;

        // 이벤트 마감 d-day 이틀 전 일때
        if (this.duration.days() <= 2) {
          remainedTime =
            myVideo.pad(this.duration.days() * 24 + this.duration.hours(), 2) +
            ":" +
            myVideo.pad(this.duration.minutes(), 2) +
            ":" +
            myVideo.pad(this.duration.seconds(), 2);
        }

        // 이벤트 마감 d-day 이틀 이상일 때
        else {
          remainedTime = `${this.duration.days()}일`;
        }
        if (this._isMounted)
          this.setState({
            remainedTime: remainedTime
          });
      }, 1000)
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.remainedTimeInterval);
  }
  render() {
    const event = this.props.data;
    return (
      <div style={{ position: "relative" }}>
        <Wrapper
          onClick={() => {
            history.push(`/event/${event.id}`);
          }}
        >
          <TitleWrap>
            <StoreImg
              style={{ backgroundImage: `url('${event.store.image}')` }}
            />
            <StoreInfo>
              <p className="event-title"> {event.title} </p>
              <p className="store-name"> WITH {event.store.name} </p>
            </StoreInfo>
            <div style={{ flex: "0 0 auto", width: 16 }} />
          </TitleWrap>
          <EventPostWrap>
            {event.example.map((example, index) => {
              return (
                <EventPostCard
                  key={index}
                  style={{
                    backgroundImage: `url('${example.thumbnail_image}')`
                  }}
                >
                  <ExampleTag>예시</ExampleTag>
                </EventPostCard>
              );
            })}
            {event.post.map((post, index) => {
              if (index < 5) {
                return (
                  <EventPostCard
                    key={index}
                    style={{
                      backgroundImage: `url('${post.thumbnail_image}')`
                    }}
                  />
                );
              }
            })}
          </EventPostWrap>
          <FooterWrap>
            {/* 타이머 */}
            <TimerImg style={{ backgroundImage: `url('${timer_small}')` }} />
            <span className="remain-time">{this.state.remainedTime}</span>

            {/* 좋아요 수 */}
            <span className="participant-count">
              <TimerImg
                style={{
                  height: "11px",
                  backgroundImage: `url('${like_small_img}')`
                }}
              />
              {event.like.count}
            </span>
          </FooterWrap>
        </Wrapper>
        <IconWrap>
          <LikeBtn
            is_liked={event.like.is_liked}
            mode="comment"
            id={event.id}
            option="event"
            marginLeft="4px"
          />
        </IconWrap>
      </div>
    );
  }
}
export default EventCard;
