import React, { createRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const MAX_HEIGHT = 64;
const textRef = createRef();
class EllipsisText extends React.Component {
  state = {
    open: true,
  };
  componentDidMount() {
    if (textRef.current) {
      if (textRef.current.offsetHeight > MAX_HEIGHT) {
        this.setState({ open: false });
      }
    }
  }
  render() {
    const { classes, text, maxLines, backgroundColor, ...rest } = this.props;
    const { open } = this.state;
    return (
      <div className={classes.root}>
        <div
          ref={(ref) => (textRef.current = ref)}
          className={clsx(classes.text, {
            [classes.open]: open,
          })}
          {...rest}
        >
          {text}
        </div>
        {open ? null : (
          <div
            className={classes.more}
            style={{
              background: `linear-gradient(90deg, rgba(0,0,0,0) 0%, ${backgroundColor} 10%, ${backgroundColor} 100%)`,
            }}
            role="button"
            onClick={() => {
              this.setState({ open: true });
            }}
          >
            <span>...더보기</span>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles({
  root: {
    position: 'relative',
  },
  text: {
    wordBreak: 'break-all',
    color: 'white',
    maxHeight: 64,
    overflow: 'hidden',
  },
  open: {
    maxHeight: '100%',
  },
  more: {
    position: 'absolute',
    bottom: 0,
    right: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    color: 'white',
  },
})(EllipsisText);
