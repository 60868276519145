import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";
import { Wrap, IndexWrap } from "components/style/custom-styled-components";
import SpinnerCurtain from "components/loadingSpinner/spinnerCurtain";
import UserProfileModal from "components/popModal/userProfileModal";
import LoginRequiredModal from "components/popModal/loginRequiredModal";
import NotOnServiceModal from "components/popModal/notOnServiceModal";
import ReportModal from "components/popModal/reportModal";
import Modal from "components/modal";
import TopNavBar from "components/nav/navBar";
import Tab from "../components/tab";
import InfiniteList from "./components/infiniteList";
import Profile from "./components/profile";
import Posts from "../posts";
import * as userAPI from "axios/userAPI";
import * as postAPI from "axios/postAPI";
import back_ico from "images/back_ico_b.svg";

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  line-height: 45px;
  height: 45px;
  width: 100%;
  max-width: 1024px;
  z-index: 3;
  top: 0;
  background: rgb(248, 248, 248);
`;

const IconWrap = styled.div`
  width: 50%;
  padding-right: 16px;
  text-align: right;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;
@inject("userStore")
@inject("postStore")
@observer
class UserProfilePage extends React.Component {
  static defaultProps = {
    postsSwiperClass: "swiper-userProfilePage",
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
      myId: null,
      userProfileModalOn: false,
      notOnServiceModalOn: false,
      reportModalOn: false,
      loginRequiredModalOn: false,
      tabIdx: 0,
      postModalOn: false,
      postModalData: -1,
      postModalThumbnail: "",
      myProfileData: null,
    };
  }
  async componentDidMount() {
    const id = this.props.id;
    if (id) {
      this.requestData(this.props);
    }
  }
  async componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id && nextProps.id) {
      this.requestData(nextProps);
    }
    if (!nextProps.id) {
      this.setState({
        loading: true,
        data: {},
        myId: null,
        userProfileModalOn: false,
        notOnServiceModalOn: false,
        reportModalOn: false,
        loginRequiredModalOn: false,
        tabIdx: 0,
        postModalOn: false,
        postModalData: -1,
        postModalThumbnail: "",
        myProfileData: null,
      });
    }
  }

  requestData = async (props) => {
    this.setState({ loading: true });
    let profileData = {};
    const req = {
      profile: props.id,
      headers: props.userStore.auth_token
        ? { Authorization: `Bearer ${props.userStore.auth_token}` }
        : false,
    };
    if (props.userStore.auth_token) {
      profileData = await userAPI.getMyUserProfile(req).then((res) => {
        return res.data;
      });
    }
    const data = await userAPI.getUserProfile(req).then((res) => {
      return res.data;
    });
    setTimeout(() => {
      this.setState({
        data,
        myId: profileData.id,
        profileData: profileData,
        loading: false,
      });
    }, 50);
  };

  closePostModal = () => {
    window.history.back();
  };
  render() {
    const isMine = this.state.myId === this.state.data.id;
    return (
      <>
        <Wrap
          style={{
            paddingBottom: 0,
            backgroundColor: "rgb(248,248,248)",
            height: "-webkit-fill-available",
          }}
        >
          <IndexWrap
            id="SCROLL_WRAP"
            style={{ paddingTop: 0, backgroundColor: "#f8f8f8" }}
          >
            {isMine ? (
              <TopNavBar myPage myProfile={this.state.profileData} />
            ) : (
              <NavBar>
                <div style={{ width: "50%" }}>
                  <img
                    src={back_ico}
                    onClick={this.props.goBack}
                    style={{
                      marginLeft: 19,
                      width: 19,
                      verticalAlign: "middle",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <IconWrap
                  onClick={() =>
                    this.setState({
                      userProfileModalOn: true,
                    })
                  }
                >
                  <svg
                    style={{ verticalAlign: "middle" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="4"
                    viewBox="0 0 16 4"
                  >
                    <path
                      fill="#333333"
                      d="M9.778 1.778c0 .978-.8 1.778-1.778 1.778s-1.778-.8-1.778-1.778S7.022 0 8 0s1.778.8 1.778 1.778zm6.222 0c0 .978-.8 1.778-1.778 1.778s-1.778-.8-1.778-1.778S13.244 0 14.222 0 16 .8 16 1.778zm-12.444 0c0 .978-.8 1.778-1.778 1.778S0 2.756 0 1.778.8 0 1.778 0s1.778.8 1.778 1.778z"
                    />
                  </svg>
                </IconWrap>
              </NavBar>
            )}
            {this.props.id && (
              <Profile
                id={this.props.id}
                data={this.state.data}
                isMine={isMine}
              />
            )}
          </IndexWrap>

          <UserProfileModal
            open={this.state.userProfileModalOn}
            handleClose={() =>
              this.setState({
                userProfileModalOn: false,
              })
            }
            bottom={"0px"}
            profile={this.state.data}
          />
          <NotOnServiceModal
            open={this.state.notOnServiceModalOn}
            handleClose={() =>
              this.setState({
                notOnServiceModalOn: false,
              })
            }
            bottom={"0px"}
          />
          <ReportModal
            open={this.state.reportModalOn}
            handleClose={() =>
              this.setState({
                reportModalOn: false,
              })
            }
            bottom={"0px"}
          />
          <LoginRequiredModal
            open={this.state.loginRequiredModalOn}
            handleClose={() =>
              this.setState({
                loginRequiredModalOn: false,
              })
            }
            bottom={"0px"}
          />
        </Wrap>
      </>
    );
  }
}
export default UserProfilePage;
