import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Truncate from 'react-text-truncate';
import bluePath from 'images/blue_path.png';
import greyPath from 'images/grey_path.png';
import parseLink from 'utils/parseLink';
import removeLink from 'utils/removeLink';
import parser from 'html-react-parser';

const useStyles = makeStyles({
  layout: {
    position: 'relative',
  },
  path: {
    top: -8,
    position: 'absolute',
    width: 24,
  },
  root: {
    fontSize: 14,
    borderRadius: 16,
    // background: "rgba(0,0,0,0.04)",
    // color: "black",
    padding: '6px 6px',
    marginBottom: 12,
    display: 'inline-block',
  },
  basic: {
    fontSize: 14,
    borderRadius: 16,
    background: '#eeeeee',
    color: 'black',
    padding: '6px 16px',
    display: 'inline-block',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  oneLine: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
  },
  primary: {
    fontSize: 14,
    borderRadius: 16,
    background: '#5887F9',
    color: 'white',
    padding: 16,
    '& > span': {
      color: 'white !important',
    },
    '& > span > div > a': {
      color: 'white !important',
    },
  },
  white: {
    '& > span': {
      color: 'white',
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  folded: {
    maxHeight: '64px',
    overflow: 'hidden',
    // line-
  },
  parsed: {
    '& > a': {
      color: 'white',
      fontWeight: 'bold',
    },
  },
  more: {
    position: 'absolute',
    right: 17,
    width: 54,
    bottom: 28,
    background: '#5887F9',
  },
});

const max = 80;
const Comment = ({ writer, text, primary, maxLine, ...props }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const parsed = parser(`<div>${parseLink(text)}</div>`);
  let len = 0;
  if (typeof parsed.props.children === 'string') {
    len = parsed.props.children.length;
  } else {
    len = parsed.props.children.reduce((acc, val) => {
      if (typeof val === 'object') {
        acc += val.props.children.length;
      } else if (typeof val === 'string') {
        acc += val.length;
      }
      return acc;
    }, 0);
  }
  return (
    <div className={clsx(!primary && classes.right, classes.layout)} {...props}>
      <img className={clsx(classes.path)} src={primary ? bluePath : greyPath} />
      {primary ? (
        <>
          <div
            className={clsx(
              classes.root,
              primary ? classes.primary : classes.basic
            )}
          >
            <div className={clsx(classes.parsed, !open && classes.folded)}>
              {parser(`${parseLink(text)}`)}
            </div>
            {len > max && !open && (
              <div
                onClick={() => setOpen(true)}
                className={classes.more}
                style={{ textAlign: 'right' }}
              >
                ...더보기
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          className={clsx(
            classes.root,
            primary ? classes.primary : classes.basic
          )}
        >
          <strong>{writer}</strong>
          {` `}
          {removeLink(text).str}
        </div>
      )}
    </div>
  );
};
export default Comment;
