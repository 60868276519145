const LAST_ENTERED_MOBILE = 'LAST_ENTERED_MOBILE';
export const getLastEnteredMobile = () => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(LAST_ENTERED_MOBILE);
  }
  return null;
};
export const setLastEnteredMobile = (mobile) => {
  if (typeof window !== 'undefined') {
    return window.localStorage.setItem(LAST_ENTERED_MOBILE, mobile);
  }
  return null;
};
