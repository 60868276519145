import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Tabs } from 'antd-mobile';
import { observer, inject } from 'mobx-react';
import axios from 'axios';

import { Wrap, IndexWrap } from 'components/style/custom-styled-components';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import LoginRequiredModal from 'components/popModal/loginRequiredModal';
import FollowBtn from 'components/followBtn';
import SearchBar from 'components/searchBar';
import Dialog from '@material-ui/core/Dialog';

import * as userAPI from 'axios/userAPI';
import history from 'utils/history';
import back_ico from 'images/back_ico_b.svg';
import default_profile from 'images/default_profile.png';

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  line-height: 56px;
  height: 56px;
  width: 100%;
  max-width: 1024px;
  z-index: 3;
  top: 0;
  background: rgb(248, 248, 248);

  div.center-title {
    color: #333333;
    font-family: 'Noto Sans CJK KR';
    font-size: 15px;
    font-weight: 700;
  }
`;
const IconWrap = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
`;

const ProfileWrap = styled.div`
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p.profile-name {
    color: #333333;
    font-family: 'Noto Sans CJK KR';
    font-size: 16px;
    font-weight: 700;
  }
  p.profile-intro {
    color: #999999;
    font-family: 'Noto Sans CJK KR';
    font-size: 14px;
    font-weight: 500;
  }
  span.profile-btn {
    display: inline-block;
    text-align: center;
    width: 64px;
    height: 28px;
    border-radius: 20px;
    vertical-align: middle;
    font-family: 'Noto Sans CJK KR';
    font-size: 13px;
    font-weight: 700;
    line-height: 28px;
    cursor: pointer;
    hover: {
      opacity: 0.8;
    }
  }
  span.profile-follow-btn {
    background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
    color: #ffffff;
  }
  span.profile-following-btn {
    background-color: rgba(130, 143, 247, 0.2);
    color: #828ff7;
  }
`;
const ProfileImage = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  display: flex;
  margin-right: 12px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.isDefaultProfileImg &&
    css`
      background-color: white;
    `}
  div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    ${(props) =>
      props.isDefaultProfileImg &&
      css`
        margin: auto;
        width: 32px;
        height: 32px;
        border-radius: 0;
      `}
  }
`;
const InfoCard = styled.div`
  width: 100%;
  height: 140px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;

  p {
    width: 100%;
    height: 47px;
    padding: 14px 16px;
    border-bottom: solid 1px #eeeeee;
    margin: 0;
    span.profile-grade {
      color: #bebebe;
      font-family: 'Noto Sans CJK KR';
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
    }
    span.profile-grade-data {
      margin-left: 16px;
      color: #828ff7;
      font-family: 'Noto Sans CJK KR';
      font-size: 14px;
      font-weight: 700;
      line-height: 30px;
      text-transform: uppercase;
    }
    span.profile-grade-icon {
      float: right;
    }
  }
`;

@inject('userStore')
@observer
class UserFollow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResult: [],
      data: [],
      search_mode: true,
    };
  }

  userClick = async (id) => {
    history.push(`/profile/${id}`);
  };
  render() {
    const { onClose, profiles } = this.props;

    return (
      <Dialog open onClose={onClose} fullScreen fullWidth>
        <Wrap
          style={{
            paddingBottom: 0,
            backgroundColor: 'rgb(248,248,248)',
            width: '100%',
          }}
        >
          <IndexWrap style={{ paddingTop: 56, backgroundColor: '#f8f8f8' }}>
            <NavBar>
              <div style={{ width: '33.3333%' }}>
                <img
                  src={back_ico}
                  onClick={onClose}
                  style={{
                    marginLeft: 19,
                    width: 19,
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div
                className="center-title"
                style={{ width: '33.3333%', textAlign: 'center' }}
              ></div>
              <div style={{ width: '33.3333%', textAlign: 'right' }} />
            </NavBar>
            <div>
              {profiles.map((profile, index) => (
                <ProfileWrap key={index}>
                  <ProfileImage
                    isDefaultProfileImg={profile.image ? false : true}
                    onClick={() => {
                      this.userClick(profile.id);
                    }}
                  >
                    <div
                      style={{
                        backgroundImage: `url('${
                          profile.image ? profile.image : default_profile
                        }')`,
                      }}
                    />
                  </ProfileImage>
                  <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                    <p className="profile-name"> {profile.nickname} </p>
                  </div>
                </ProfileWrap>
              ))}
            </div>
          </IndexWrap>
        </Wrap>
      </Dialog>
    );
  }
}
export default UserFollow;
