import React, { Component } from "react";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";
import Swipe from "react-easy-swipe";

import * as myVideo from "utils/myVideo";
import { ReactComponent as RightHandle } from "images/video_crop_right_handler.svg";
import { ReactComponent as LeftHandle } from "images/video_crop_left_handler.svg";

const SliderWrapper = styled.div`
  position: absolute !important;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  border-top: 3px solid #828ff7;
  border-bottom: 3px solid #828ff7;
  border-radius: 4px;
  .rangeslider {
    margin: 0px !important;
  }
  .rangeslider-horizontal {
    height: 100% !important;
  }
`;
const LeftSliderHandle = styled.div`
  position: absolute !important;
  left: 0px;
  top: -3px;
  height: 112%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px 0 0 4px;
  border-top: 3px solid rgb(150, 150, 150);
  border-bottom: 3px solid rgb(150, 150, 150);
`;
const RightSliderHandle = styled.div`
  position: absolute !important;
  right: 0px;
  top: -3px;
  height: 112%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0 4px 4px 0;
  border-top: 3px solid rgb(150, 150, 150);
  border-bottom: 3px solid rgb(150, 150, 150);
`;
const LeftIconWrap = styled(Swipe)`
  position: absolute;
  height: 100%;
  left: 0px;
  top: -3px;
  width: 36px;
`;
const RightIconWrap = styled(Swipe)`
  position: absolute;
  height: 100%;
  right: 0px;
  top: -3px;
  width: 36px;
`;
const DurationInfo = styled.div`
  position: absolute !important;
  bottom: -28px;
  text-align: center;
  span.current-duration {
    color: #828ff7;
    font-family: "Noto Sans CJK KR";
    font-size: 14px;
    font-weight: 700;
    background-color: rgb(248, 248, 248);
  }
  span.total-duration {
    color: #bbbbbb;
    font-family: "Noto Sans CJK KR";
    font-size: 14px;
    font-weight: 500;
  }
`;
const constants = {
  orientation: {
    horizontal: {
      dimension: "width",
      direction: "left",
      reverseDirection: "right",
      coordinate: "x"
    },
    vertical: {
      dimension: "height",
      direction: "top",
      reverseDirection: "bottom",
      coordinate: "y"
    }
  }
};

function getPortionFromPos(wrapWidth, pos) {
  return pos / wrapWidth;
}
function getPosOfDuration(start, end) {
  if ((start + end + 24) / 2 - 24 < 0) {
    return 0;
  } else return (start + end + 24) / 2 - 24;
}
function loopVideo(video, startP, endP) {
  if (video && video.currentTime > endP) {
    video.currentTime = startP;
    return true;
  } else return false;
}

@inject("postStore")
@observer
class VideoCropSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      startPos: 0,
      endPos: 0,
      startBasePos: 0,
      endBasePos: 0,
      wrapWidth: 0,
      startPortion: 0,
      endPortion: 1,
      windowInnerWidth: window.innerWidth,
      leftBand: window.innerWidth > 480 ? (window.innerWidth - 480) / 2 : 0,
      rightBand: window.innerWidth > 480 ? (window.innerWidth - 480) / 2 : 0,
      startPoint: 0,
      endPoint: 0
    };
  }
  componentDidMount() {
    const loopVideoInterval = setInterval(() => {
      loopVideo(
        this.props.videoElement,
        this.state.startPoint,
        this.state.endPoint
      );
    }, 2000);
    const startPos = this.props.postStore.editedVideoArr[this.props.index]
      .startPos
      ? this.props.postStore.editedVideoArr[this.props.index].startPos
      : 0;
    const endPos = this.props.postStore.editedVideoArr[this.props.index].endPos
      ? this.props.postStore.editedVideoArr[this.props.index].endPos
      : this.wrap.clientWidth - 24;
    this.setState({
      loopVideoInterval: loopVideoInterval,
      wrapWidth: this.wrap.clientWidth,
      endBasePos: endPos,
      endPos: endPos,
      startBasePos: startPos,
      startPos: startPos,
      startPoint: 0,
      endPoint: this.props.durationVal,
      currentSectionStr: this.props.durationStr
    });
  }
  componentWillUnmount() {
    clearInterval(this.state.loopVideoInterval);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.durationVal != this.props.durationVal) {
      this.setState({
        startPoint: 0,
        endPoint: this.props.durationVal,
        currentSectionStr: this.props.durationStr
      });
    }
    if (
      prevProps.resetClicked != this.props.resetClicked &&
      this.props.resetClicked == true
    ) {
      this.setState(
        {
          startPoint: 0,
          endPoint: this.props.durationVal,
          currentSectionStr: this.props.durationStr,
          startPos: 0,
          startBasePos: 0,
          endBasePos: this.wrap.clientWidth - 24,
          endPos: this.wrap.clientWidth - 24
        },
        () => {
          this.props.afterResetCrop();
          this.props.postStore.editedVideoArr[
            this.props.index
          ].currentDuration = undefined;
          this.props.postStore.editedVideoArr[
            this.props.index
          ].start = undefined;
          this.props.postStore.editedVideoArr[
            this.props.index
          ].startPos = undefined;
          this.props.postStore.editedVideoArr[this.props.index].end = undefined;
          this.props.postStore.editedVideoArr[
            this.props.index
          ].endPos = undefined;
        }
      );
    }
  }
  handleStart = e => {
    document.addEventListener("mousemove", this.onSwipeMove);
    document.addEventListener("mouseup", this.onSwipeEnd);
    this.setState({
      active: true,
      mouse: true
    });
  };
  handleStartR = e => {
    document.addEventListener("mousemove", this.onSwipeMoveR);
    document.addEventListener("mouseup", this.onSwipeEndR);
    this.setState({
      active: true,
      mouse: true
    });
  };

  onSwipeEnd = e => {
    this.setState({
      active: false,
      mouse: false,
      startBasePos: this.state.startPos
    });
    // console.log("시작 부분 핸들러 위치 : " + this.state.startPos)
    // console.log("시작 부분 핸들러 비율 : " + getPortionFromPos(this.state.wrapWidth, this.state.startPos))
    document.removeEventListener("mousemove", this.onSwipeMove);
    document.removeEventListener("mouseup", this.onSwipeEnd);
  };
  onSwipeStart = event => {
    this.setState({
      active: true
    });
  };
  onSwipeMove = (position, event) => {
    let validPos = 0;
    let startPos, startPortion, startPoint, currentSectionStr;
    if (position.x < 0) {
      // only Touch ...because mouse has only positive position
      validPos = this.state.startBasePos + position.x;
      if (validPos < 0) validPos = 0;
      startPos = validPos;
      startPortion = getPortionFromPos(this.state.wrapWidth, validPos);
      startPoint = this.props.durationVal * startPortion;
      currentSectionStr = myVideo.getDurationStr(
        this.state.endPoint - startPoint
      ); // 현재 구간 값 = this.state.endPoint - this.state.startPoint
    } else {
      if (this.state.mouse) {
        validPos =
          position.x - 16 - this.state.leftBand > 0
            ? position.x - 16 - this.state.leftBand
            : 0;
      } else {
        validPos = this.state.startBasePos + position.x;
      }
      validPos =
        validPos > this.state.endBasePos ? this.state.endBasePos : validPos;
      startPos = validPos;
      startPortion = getPortionFromPos(this.state.wrapWidth, validPos);
      startPoint = this.props.durationVal * startPortion;
      currentSectionStr = myVideo.getDurationStr(
        this.state.endPoint - startPoint
      ); // 현재 구간 값 = this.state.endPoint - this.state.startPoint
    }
    this.setState(
      {
        startPos: startPos,
        startPortion: startPortion,
        startPoint: startPoint,
        currentSectionStr: currentSectionStr
      },
      () => {
        this.props.videoElement.currentTime = startPoint;
        this.props.postStore.editedVideoArr[
          this.props.index
        ].currentDuration = currentSectionStr;
        this.props.postStore.editedVideoArr[
          this.props.index
        ].start = myVideo.getHMSFromSeconds(startPoint);
        this.props.postStore.editedVideoArr[
          this.props.index
        ].end = myVideo.getHMSFromSeconds(this.state.endPoint);
        this.props.postStore.editedVideoArr[
          this.props.index
        ].startPos = startPos;
      }
    );
    return true;
  };

  onSwipeEndR = e => {
    this.setState({
      active: false,
      mouse: false,
      endBasePos: this.state.endPos
    });
    // console.log("끝 부분 핸들러 위치 : " + this.state.endPos)
    // console.log("끝 부분 핸들러 비율 : " + getPortionFromPos(this.state.wrapWidth, this.state.endPos+24))
    document.removeEventListener("mousemove", this.onSwipeMoveR);
    document.removeEventListener("mouseup", this.onSwipeEndR);
  };
  onSwipeStartR = event => {
    this.setState({
      active: true
    });
  };
  onSwipeMoveR = (position, event) => {
    let validPos = 0;
    let endPos, endPortion, endPoint, currentSectionStr;
    if (position.x < 0) {
      // only Touch ...because mouse has only positive position
      validPos = this.state.endBasePos + position.x;
      if (validPos < this.state.startBasePos)
        validPos = this.state.startBasePos;
      endPos = validPos;
      endPortion = getPortionFromPos(this.state.wrapWidth, validPos + 24);
      endPoint = this.props.durationVal * endPortion;
      currentSectionStr = myVideo.getDurationStr(
        endPoint - this.state.startPoint
      ); // 현재 구간 값 = this.state.endPoint - this.state.startPoint
    } else {
      if (this.state.mouse) {
        validPos =
          position.x - 24 - this.state.rightBand < this.state.startBasePos
            ? this.state.startBasePos
            : position.x - 24 - this.state.rightBand;
      } else {
        validPos = this.state.endBasePos + position.x;
      }
      validPos =
        validPos > this.state.wrapWidth - 24
          ? this.state.wrapWidth - 24
          : validPos;
      endPos = validPos;
      endPortion = getPortionFromPos(this.state.wrapWidth, validPos + 24);
      endPoint = this.props.durationVal * endPortion;
      currentSectionStr = myVideo.getDurationStr(
        endPoint - this.state.startPoint
      ); // 현재 구간 값 = this.state.endPoint - this.state.startPoint
    }
    this.setState(
      {
        endPos: endPos,
        endPortion: endPortion,
        endPoint: endPoint,
        currentSectionStr: currentSectionStr
      },
      () => {
        this.props.videoElement.currentTime = endPoint;
        this.props.postStore.editedVideoArr[
          this.props.index
        ].currentDuration = currentSectionStr;
        this.props.postStore.editedVideoArr[
          this.props.index
        ].end = myVideo.getHMSFromSeconds(endPoint);
        this.props.postStore.editedVideoArr[
          this.props.index
        ].start = myVideo.getHMSFromSeconds(this.state.startPoint);
        this.props.postStore.editedVideoArr[this.props.index].endPos = endPos;
      }
    );
    return true;
  };

  render() {
    const { durationVal, durationStr, videoElement } = this.props;
    return (
      <SliderWrapper ref={ref => (this.wrap = ref)}>
        <LeftSliderHandle style={{ width: this.state.startPos }}>
          <LeftIconWrap
            onSwipeStart={this.onSwipeStart}
            onSwipeMove={this.onSwipeMove}
            onSwipeEnd={this.onSwipeEnd}
            onMouseDown={this.handleStart}
            style={{ left: this.state.startPos }}
          >
            <LeftHandle />
          </LeftIconWrap>
        </LeftSliderHandle>
        <RightSliderHandle
          style={{
            width:
              this.state.wrapWidth &&
              this.state.wrapWidth - 12 - this.state.endPos
          }}
        >
          <RightIconWrap
            onSwipeStart={this.onSwipeStartR}
            onSwipeMove={this.onSwipeMoveR}
            onSwipeEnd={this.onSwipeEndR}
            onMouseDown={this.handleStartR}
          >
            <RightHandle />
          </RightIconWrap>
        </RightSliderHandle>
        <DurationInfo style={{ right: 0 }}>
          <span className="total-duration"> {durationStr} </span>
        </DurationInfo>
        <DurationInfo
          style={{
            left: getPosOfDuration(this.state.startPos, this.state.endPos)
          }}
        >
          <span className="current-duration">
            {this.props.postStore.editedVideoArr[this.props.index]
              .currentDuration
              ? this.props.postStore.editedVideoArr[this.props.index]
                  .currentDuration
              : this.state.currentSectionStr}
          </span>
        </DurationInfo>
      </SliderWrapper>
    );
  }
}
export default VideoCropSlider;
