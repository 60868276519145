import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import comment_post_small from 'images/comment_post_small.png';
import like_post_small from 'images/like_post_small.png';

const emerge = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const Wrapper = styled.div`
	position: relative;
	z-index: 0;
	cursor: pointer;
	height: 100%;
	border-radius: 10px;
	overflow: hidden;
	background-size: cover;
	background-position: center;
	animation: ${emerge} 0.5s;
	animation-fill-mode: forwards;
	:after {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		border-radius: 10px;
		background: rgba(0, 0, 0, 0);
		content: '';
		z-index: -1;
	}
`;
const Title = styled.div`
	position: absolute;
	line-height: normal !important;
	bottom: 17px;
	left: 0px;
	padding: 10px;
	width: 100%;
	text-align: left;
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 13px;
	font-weight: 400;

	padding-bottom: 0px;
	margin-bottom: 10px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
`;
const IconWrap = styled.div`
	position: absolute;
	line-height: normal !important;
	bottom: -4px;
	left: 0px;
	padding: 10px;
	width: 100%;
	text-align: left;
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 13px;
	font-weight: 400;
`;
const MiniIcon = styled.div`
	display: inline-block;
	width: 10px;
	height: 9px;
	margin-right: 5px;
	vertical-align: super;
	background-size: cover;
	background-position: center;
`;
const CountText = styled.div`
	display: inline-block;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	color: #f8f8f8;
	font-family: 'Noto Sans CJK KR';
	font-size: 12px;
	font-weight: 500;
	vertical-align: super;
`;
const GradientFilter = styled.div`
	position: absolute;
	left: 0px;
	width: 100%;
	height: 25%;
	${props =>
		props.top &&
		css`
			top: 0px;
			border-radius: 10px 10px 0px 0px;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.3) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		`}

	${props =>
		props.bottom &&
		css`
			bottom: 0px;
			border-radius: 0px 0px 10px 10px;
			background-image: linear-gradient(
				to top,
				rgba(0, 0, 0, 0.3) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		`}
`;
class Item extends React.Component {
	render() {
		const { data } = this.props;
		return (
			<Wrapper
				style={{
					backgroundImage: 'url(' + data.thumbnail_image + ')',
				}}
				onClick={this.props.onClick}
			>
				<GradientFilter top />
				<GradientFilter bottom />
				<Title> {data.title} </Title>
				<IconWrap>
					<MiniIcon style={{ backgroundImage: `url('${like_post_small}')` }} />{' '}
					<CountText style={{ marginLeft: -5, marginRight: 10 }}>
						{data.like.count}
					</CountText>
					<MiniIcon
						style={{
							backgroundImage: `url('${comment_post_small}')`,
							marginRight: 4,
						}}
					/>{' '}
					<CountText style={{ marginLeft: -3 }}>
						{data.comment && data.comment.count}
					</CountText>
				</IconWrap>
			</Wrapper>
		);
	}
}
export default Item;
