import React from "react";
import styled from "styled-components";
import TopNavBar from "./nav/navBar";
import { TopNavBarStyle } from "./style/custom-styled-components";

export default class Header extends React.Component {
  render() {
    return (
      <TopWrap>
        <TopNavBar />
      </TopWrap>
    );
  }
}

const TopWrap = styled.div`
  //position:absolute;
  top: 0px;
  left: 0px;
  height: 7%;
  right: 0px;
  overflow: hidden;
  width: 100%;
`;
