import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';

const MAX = 5;
const LEFT = 24;
const Avatars = ({ classes, profiles, onClick, ...rest }) => {
  let images = profiles.map(({ image }) => image);
  return (
    <span className={classes.root} role="button" onClick={onClick} {...rest}>
      {images.slice(0, MAX).map((image, i) => (
        <Avatar
          className={clsx(classes.avatar, i > 0 && classes.absoluteAvatar)}
          style={
            i > 0
              ? {
                  left: LEFT * i,
                }
              : {}
          }
          src={image}
        />
      ))}
      {images.length > MAX ? (
        <span
          className={classes.absoluteAvatar}
          style={{
            left: LEFT * (MAX + 1) - 10,
          }}
        >
          + {images.length - MAX}
        </span>
      ) : null}
    </span>
  );
};

export default withStyles({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    display: 'inline-block',
    margin: 4,
    width: 30,
    height: 30,
  },
  absoluteAvatar: {
    position: 'absolute',
  },
})(Avatars);
