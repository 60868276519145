import React from 'react';
import axios from 'axios';
import { observer, inject } from 'mobx-react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import getUserOrCreate from 'utils/getUserOrCreate';
import history from 'utils/history';
import Drawer from '@material-ui/core/Drawer';
import getAvailableMissions from '../utils/getAvailableMissions';
import Text from '@material-ui/core/Typography';

const styles = {
  drawer: {
    borderRadius: '12px 12px 0px 0px',
  },
  text: {
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
    paddingTop: 16,
    paddingBottom: 8,
  },
  label: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 18,
    marginLeft: 24,
  },
  sums: {
    marginTop: 8,
    paddingBottom: 24,
  },
  sum: {
    width: '25%',
    height: 30,
    fontSize: 12,
    color: '#9b9b9b',
  },
  selected: {
    color: '#1f75fe',
  },
  sumSelect: {
    marginBottom: 0,
  },
};
@inject('userStore')
@observer
class WorldAndSum extends React.Component {
  state = {
    worlds: [],
    worldSelected: '',
    enteredSums: [],
    sums: [],
    sumSelected: '',
    open: false,
  };
  async componentDidMount() {
    const { data } = await axios.get(
      'https://star-api.lay-er.me/sums?_sort=priority:ASC&hidden=false'
    );
    const profile = await getUserOrCreate(this.props.userStore.auth_token);
    if (profile) {
      profile.sums = profile.sums.reverse();
      this.setState({ profile });
    }
    const enteredSums = profile && profile.sums ? profile.sums : [];
    this.setState({
      enteredSums,
      sums: data.filter(
        (sum) => !enteredSums.find((enteredSum) => enteredSum.id === sum.id)
      ),
    });

    const sumIdRegex = /sum=(\d+)/.exec(window.location.href);

    if (sumIdRegex) {
      const sumId = Number(sumIdRegex[1]);

      this.setState({ sumSelected: sumId });
    } else {
      const myFeed = data.find(({ name }) => name === '마이피드');

      const url = new URL(window.location.href);
      url.searchParams.set('sum', myFeed.id);
      history.replace(`${window.location.pathname}${url.search}`);

      this.setState({ sumSelected: myFeed.id });
    }
  }

  render() {
    const { classes, onlySelected, setOnlySelected } = this.props;

    let label = '등록섬 선택';
    if (this.state.sumSelected) {
      let found = this.state.sums.find(
        (sum) => sum.id === this.state.sumSelected
      );
      if (!found) {
        found = this.state.enteredSums.find(
          (sum) => sum.id === this.state.sumSelected
        );
      }
      if (found) {
        label = found.name;
      }
    }

    const SumList = ({ label, sums }) => (
      <>
        <Text className={classes.label}>{label}</Text>
        <div className={classes.sums}>
          {sums.map(({ id, name }) => (
            <Button
              key={id}
              className={clsx(classes.sum, {
                [classes.selected]: this.state.sumSelected === id,
              })}
              onClick={() => {
                const { pathname } = window.location;
                const sumSelected = id;
                this.setState({
                  sumSelected,
                });
                if (sumSelected) {
                  history.replace(`${pathname}?sum=${sumSelected}`);
                } else {
                  history.replace(`${pathname}`);
                }
                this.setState({
                  open: false,
                });
              }}
            >
              {name}
            </Button>
          ))}
        </div>
      </>
    );

    console.log(this.state.sums);
    return (
      <div>
        <Button
          className={classes.sumSelect}
          color="primary"
          onClick={() => {
            this.setState({ open: true });
          }}
        >
          {label}
        </Button>
        <Drawer
          classes={{ paper: classes.drawer }}
          anchor={'bottom'}
          open={this.state.open}
          onClose={() => {
            this.setState({ open: false });
          }}
        >
          <div>
            <Text className={classes.text}>오늘은 어느 섬에 올리실건가요?</Text>

            {this.state.enteredSums.length > 0 && (
              <SumList label="입주섬" sums={this.state.enteredSums} />
            )}
            <SumList label="모든섬" sums={this.state.sums} />
          </div>
        </Drawer>
      </div>
    );
  }
}
export default withStyles(styles)(WorldAndSum);
