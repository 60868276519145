import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";

import * as userAPI from "axios/userAPI";
import history from "utils/history";

const Wrapper = styled.div`
  flex: 0 0 auto;
  span.profile-btn {
    display: inline-block;
    text-align: center;
    width: 64px;
    height: 28px;
    border-radius: 20px;
    vertical-align: middle;
    font-family: "Noto Sans CJK KR";
    font-size: 13px;
    font-weight: 700;
    line-height: 28px;
    cursor: pointer;
    hover: {
      opacity: 0.8;
    }
  }
  span.profile-follow-btn {
    background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
    color: #ffffff;
  }
  span.profile-following-btn {
    background-color: rgba(130, 143, 247, 0.2);
    color: #828ff7;
  }
`;

@inject("userStore")
@observer
class FollowBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: this.props.profile,
      is_followed: this.props.is_followed,
    };
  }
  componentDidMount() {}
  _onClick = async () => {
    const token = this.props.userStore.getTokenOrToggleLogin();
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const req = {
        profile: this.props.profile.id,
        headers: headers,
      };
      const is_followed = await userAPI.patchFollowUpdate(req).then((res) => {
        return res.data;
      });
      this.setState({
        is_followed: !this.state.is_followed,
      });
    }
  };
  render() {
    return (
      <Wrapper
        style={{ width: this.props.mode == "notification" ? "56px" : "64px" }}
      >
        {this.state.is_followed ? (
          <span
            className="profile-btn profile-following-btn"
            onClick={this._onClick}
            style={{
              width: this.props.mode == "notification" ? "56px" : "64px",
              backgroundColor: this.props.followedBackgroundColor
                ? this.props.followedBackgroundColor
                : "rgba(130,143,247,0.2)",
            }}
          >
            {" "}
            팔로잉{" "}
          </span>
        ) : (
          <span
            className="profile-btn profile-follow-btn"
            onClick={this._onClick}
            style={{
              width: this.props.mode == "notification" ? "56px" : "64px",
            }}
          >
            {" "}
            팔로우{" "}
          </span>
        )}
      </Wrapper>
    );
  }
}

export default FollowBtn;
