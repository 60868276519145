import EXIF from "exif-js";
export function getVideoImage(video, path, secs, callback) {
  var me = this;
  video.onloadedmetadata = function() {
    if ("function" === typeof secs) {
      secs = secs(this.duration);
    }
    this.currentTime = Math.min(
      Math.max(0, (secs < 0 ? this.duration : 0) + secs),
      this.duration
    );
  };
  video.onseeked = function(e) {
    var canvas = document.createElement("canvas");
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    var img = new Image();
    img.src = canvas.toDataURL();
    callback.call(me, img, this.currentTime, e);
  };
  video.onerror = function(e) {
    callback.call(me, undefined, undefined, e);
  };
  video.src = path;
}

export function secondsToTime(secs) {
  secs = Math.round(secs);
  var hours = Math.floor(secs / (60 * 60));

  var divisor_for_minutes = secs % (60 * 60);
  var minutes = Math.floor(divisor_for_minutes / 60);

  var divisor_for_seconds = divisor_for_minutes % 60;
  var seconds = Math.ceil(divisor_for_seconds);

  var obj = {
    h: hours,
    m: minutes,
    s: seconds
  };
  return obj;
}

export function pad(n, width) {
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join("0") + n;
}

export function getDurationStr(duration) {
  const refineDuration = secondsToTime(duration);
  const h = pad(refineDuration.h, 2);
  const m = pad(refineDuration.m, 2);
  const s = pad(refineDuration.s, 2);
  return `${m}:${s}`;
}

export function getHMSFromSeconds(seconds) {
  const refineDuration = secondsToTime(seconds);
  const h = pad(refineDuration.h, 2);
  const m = pad(refineDuration.m, 2);
  const s = pad(refineDuration.s, 2);
  return `${h}:${m}:${s}`;
}
