import React, { Component } from "react";
import { createGlobalStyle } from "styled-components";
import { Route, Switch } from "react-router-dom";
import { Provider } from "mobx-react";
import { observer, inject } from "mobx-react";
import { GOOGLE_KEY } from "axios/config";
import * as userAPI from "axios/userAPI";
import * as iosHandler from "utils/iosHandler";
import Home from "pages/home";
import HomeWithAProfile from "pages/homeWithAProfile";
import _Home from "pages/_home";
import HomeWithSingleContentFromMyProfile from "pages/homeWithSingleContentFromMyProfile";
import Event from "pages/event";
import EventDetailRefactor from "pages/eventDetailRefactor";
import Login from "pages/login";
import CreatePostPage from "pages/createPostPage";
import ProductAddPage from "pages/productAddPage";
import PostSamplePage from "pages/postSamplePage";
import PostEditSamplePage from "pages/editPost/postEditSamplePage";
import PostEditPage from "pages/editPost/postEditPage";
import MyPage from "pages/myPage";
import MyPageFollow from "pages/myPageFollow";
import MyPagePosts from "pages/myPagePosts";
import MySetting from "pages/mySetting";
import Star from "pages/star";
import StarUsage from "pages/star/Usage";
import MyLikePage from "pages/myLikePage";
import MyProfileUpdate from "pages/myProfileUpdate";
import MyNotification from "pages/myNotification";
import Signup from "pages/signup";
import SignupStyle from "pages/signupStyle";
import SignupFilter from "pages/signupFilter";
import UserFollow from "pages/userFollow";
import LikeList from "pages/likeList";
import Population from "pages/population";
import VideoEditPage from "pages/videoEditPage";
import EditVideoPage from "pages/editPost/videoEditPage";
import Sums from "pages/sums";
import Explore from "pages/explore";

import IosCreatePostPage from "pages/iosApp/iosCreatePostPage";
import IosPostSamplePage from "pages/iosApp/iosPostSamplePage";
import IosPostEditPage from "pages/iosApp/iosPostEditPage";
import Search from "pages/search";

import PostStore from "stores/postStore";
import CacheStore from "stores/cacheStore";
import EventStore from "stores/eventStore";
import UserStore from "stores/userStore";

import * as config from "axios/config";
import cookie from "js-cookie";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#1f75fe" },
  },
  typography: {
    fontFamily: ["Noto Sans CJK KR"].join(","),
  },
});
const GlobalStyle = createGlobalStyle`
  html,body {
    background-color: #f5f5f5 !important;
    margin:0;
		padding:0;
    #root {
      width:100%;
      // min-height: 100vh;
    }
  }
    .am-navbar-light{
        background-color:rgb(248,248,248) !important;
    }
    .am-tab-bar{
        max-width: 1024px;
        width: 100%;
        height: 62px !important;
        background-color:white !important;
        // position: absolute;
        // bottom: 0;
    }
    .am-tab-bar-tab{
				cursor:pointer;
				height: 100%;
    }
    .am-tabs-default-bar{
        background-color: transparent !important;
    }
    .am-tabs-default-bar-top .am-tabs-default-bar-prevpage, .am-tabs-default-bar-bottom .am-tabs-default-bar-prevpage{
        background: linear-gradient(to right, rgb(248,248,248), rgba(255, 255, 255, 0)) !important;
    }
    .am-tabs-default-bar-top .am-tabs-default-bar-nextpage, .am-tabs-default-bar-bottom .am-tabs-default-bar-nextpage {
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(248,248,248)) !important;
    }
    .am-grid .am-flexbox{
        background-color : transparent !important;
    }
    .am-tabs-tab-bar-wrap {
			padding-bottom: 12px;
			background-color: transparent  !important;
        .am-tab-bar-bar {
					margin-top: 0px;
						border-top : 0;
						height: 58px;
        }
    }
    .am-flexbox-item{
      width:100%;
    }
    .am-grid-item-content{
      padding:0 !important;
    }
   .rmc-picker-popup-header{
        z-index:4 !important;
    }
    .rmc-picker-popup {
        left: 0;
        bottom: 0;
        right: 0;
        position: fixed;
        width: 100%;
        background-color: #fff;
        max-width: 1024px;
        margin: auto;
    }
    .am-tabs-default-bar-underline{
        border: solid 1px #828ff7 !important;
    }
    .am-grid.am-grid-square .am-grid-item:before{
      content: none !important;
    }
    .vjs-control-bar{
        opacity:0;
        z-index:-1;
    }
`;

@inject("userStore")
@observer
class App extends Component {
  componentDidMount() {
    window.test = () => {
      window.alert("테스트");
    };

    window.onGetToken = (msg) => {
      if (msg && msg.result) {
        const req = {
          grant_type: "convert_token",
          client_id: GOOGLE_KEY,
          backend: "google-oauth2",
          token: msg.token,
        };
        this.login(req);
      }
    };
    window.onDeleteToken = (msg) => {
      if (msg && msg.result) {
        window.location.reload();
      }
    };
    if (!this.props.userStore.auth_token) console.log("getToken");
  }
  async login(req) {
    let user = [];
    user = await Promise.all([
      userAPI
        .postConvertToken(req)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return false;
        }),
    ]);
    if (user[0]) {
      //  로그인처리 !!!!
      //  애플 로그인에서 여기대로 처리한다.
      const userId = user[0].profile.id;
      const token = JSON.stringify(user[0].data.access_token);
      localStorage.setItem("ms_auth_token", token);
      iosHandler.loginKey(token);
      iosHandler.setPushKey(userId);
      this.props.userStore.setAccessToken(user[0].data.access_token);
      window.location.reload();
      // 회원가입페이지 이동 (일단 무조건 이동)
    }
  }
  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default App;
