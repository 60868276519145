const getCaret = (el) => {
  if (el.selectionStart) {
    return el.selectionStart;
  } else if (document.selection) {
    el.focus();

    const r = document.selection.createRange();
    if (r === null) {
      return 0;
    }

    const re = el.createTextRange(),
      rc = re.duplicate();
    re.moveToBookmark(r.getBookmark());
    rc.setEndPoint("EndToStart", re);

    return rc.text.length;
  }
  return 0;
};

export default getCaret;
