import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';
import MySwitch from 'components/mySwitch';
import * as userAPI from 'axios/userAPI';
import history from 'utils/history';
import * as switchForClilent from 'utils/switchForClient';

const Wrapper = styled.div`
	width: 100%;
	max-width: 480px;
	height: 100vh;
	top: 0px;
	z-index: -1;
	position: fixed;
	transition: 0.2s background-color ease, z-index 0.2s ease;

	${props =>
		props.active &&
		css`
			z-index: 1002;
			background-color: rgba(0, 0, 0, 0.5);
			transition: 0.2s background-color ease;
		`}
`;
const Modal = styled.div`
	max-width: 480px;
	padding: 16px;
	position: fixed;
	bottom: -400px;
	width: 100%;
	height: 146px;
	background: #ffffff;
	transition: 0.2s ease;
	z-index: 1002;
	${props =>
		props.active &&
		css`
			bottom: ${props.bottom ? props.bottom : '48px'};
			box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
			border-radius: 20px 20px 0 0;
		`}
`;
const OutSideWrap = styled.div`
	width: 100%;
	height: 100%;
	z-index: 1;
	top: 0px;
	position: fixed;
	background: transparent;
`;
const ContentWrap = styled.div`
	color: #333333;
	font-family: 'Noto Sans CJK KR';
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 16px;
	p {
		margin: 0px;
	}
	span {
		color: #bebebe;
		font-family: 'Noto Sans CJK KR';
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
	}
`;
const SwitchWrap = styled.div`
	position: absolute !important;
	right: 16px;
	top: 16px;
`;
const Btn = styled.div`
	width: 100%;
	height: 44px;
	box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
	border-radius: 68px;
	background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
	color: #ffffff;
	font-family: 'Noto Sans CJK KR';
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	line-height: 44px;
	cursor: pointer;
	:hover {
		opacity: 0.8;
	}
`;

@inject('userStore')
@observer
class MyFilterModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			is_applied: this.props.is_applied,
		};
	}
	componentDidUpdate(prevProps) {
		if (prevProps.is_applied != this.props.is_applied) {
			this.setState({
				is_applied: this.props.is_applied,
			});
		}
	}
	handleChange = async () => {
		const req = {
			headers: {
				Authorization: `Bearer ${this.props.userStore.auth_token}`,
			},
			data: {
				is_applied: !this.state.is_applied,
			},
		};
		const result = await userAPI.patchUserProfile(req).then(res => {
			return res.data;
		});
		this.setState({
			is_applied: !this.state.is_applied,
		});
	};
	_onSubmit = () => {
		this.props.userStore.toggleMyFilter();
		history.push('/my-page/update');
	};
	render() {
		let height, size, bottomSize, skin;
		if (this.props.profile) {
			height = this.props.profile.height;
			size = this.props.profile.size;
			bottomSize = this.props.profile.bottomSize;
			skin = this.props.profile.skintype;
		}
		return (
			<Wrapper active={this.props.userStore.myFilterToggle}>
				<OutSideWrap onClick={this.props.userStore.toggleMyFilter} />
				<Modal
					active={this.props.userStore.myFilterToggle}
					bottom={this.props.bottom}
				>
					<ContentWrap>
						<p>마이필터</p>
						<span style={{ display: 'inline-block' }}>
							{' '}
							{switchForClilent.switchProfileHeight(height)},{' '}
							{switchForClilent.switchProfileSize(size)}상의 사이즈,{' '}
							{switchForClilent.switchProfileSize(bottomSize)}하의 사이즈,{' '}
							{switchForClilent.switchProfileSkin(skin)}
						</span>
						<SwitchWrap>
							<MySwitch
								handleChange={this.handleChange}
								checked={this.state.is_applied}
							/>
						</SwitchWrap>
					</ContentWrap>
					<Btn onClick={this._onSubmit}>마이필터 수정하기</Btn>
				</Modal>
			</Wrapper>
		);
	}
}

export default MyFilterModal;
