import React, { Fragment } from 'react';
import { NavBar } from 'antd-mobile';
import styled, { css } from 'styled-components';
import moment from 'moment';
import Up from 'images/starUsage/up.png';
import Down from 'images/starUsage/down.png';

const DateStringWrapper = styled.div`
  color: #bebebe;
  font-family: 'Noto Sans CJK KR';
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 12px;
`;
const DateString = styled.div`
  margin-bottom: 8px;
`;
const Box = styled.div`
  height: 64px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #ffffff;
  text-align: left;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 14px;
`;
const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
`;
const Arrow = styled.img`
  width: 37px;
  height: 37px;
`;
const Content = styled.div`
  flex: 1;
`;
const Title = styled.div`
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 0px;
`;
const Subtitle = styled.div`
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0px;
  flex: 1;
`;
const SubtitleWrapper = styled.div`
  display: flex;
`;
const Number = styled.div`
  color: #828ff7;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 0px;
  margin-right: 16px;
  ${(props) =>
    props.down &&
    css`
      color: #ff4e00;
    `}
`;
export default ({ list }) => {
  const mappedDateList = list.map(({ created_at, ...rest }) => {
    return {
      ...rest,
      date: moment(created_at),
      dateFormatted: moment(created_at).format('MM월 DD일'),
    };
  });
  mappedDateList.sort((a, b) => {
    return moment(b.date) - moment(a.date);
  });
  const dates = mappedDateList.reduce((acc, { dateFormatted, ...rest }) => {
    const toPush = {
      date: dateFormatted,
      data: [rest],
    };
    const prevIdx = acc.findIndex((o) => {
      return o.date === dateFormatted;
    });
    if (prevIdx > -1) {
      acc[prevIdx].data.push(rest);
    } else {
      acc.push(toPush);
    }
    return acc;
  }, []);
  return (
    <div>
      {dates.map((item) => {
        return (
          <DateStringWrapper key={item.date}>
            <DateString>{item.date}</DateString>
            {item.data.map((o) => (
              <Box key={`box-${o.id}`}>
                <ArrowWrapper>
                  <Arrow src={o.amount > 0 ? Down : Up} />
                </ArrowWrapper>
                <Content>
                  <Title>{o.amount > 0 ? '지급' : '사용'}</Title>
                  <SubtitleWrapper>
                    <Subtitle>{o.detail}</Subtitle>
                    <Number down={o.amount < 0}>
                      {o.amount < 0 ? '- ' : '+ '}
                      {Math.abs(o.amount)}
                    </Number>
                  </SubtitleWrapper>
                </Content>
              </Box>
            ))}
          </DateStringWrapper>
        );
      })}
    </div>
  );
};
