import React, { Component, Fragment } from "react";
import styled, { css } from "styled-components";
import { observer, inject } from "mobx-react";

import ClickedChkIcon from "components/myIcons/clickedChkIcon";

import * as userAPI from "axios/userAPI";
import history from "utils/history";

const Wrapper = styled.div`
  width: 100%;
  max-width: 480px;
  height: 100vh;
  top: 0px;
  z-index: -1;
  position: fixed;

  ${(props) =>
    props.active &&
    css`
      z-index: 1002;
    `}
`;
const Modal = styled.div`
  max-width: 480px;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: -350px;
  width: 100%;
  height: 290px;
  background-color: #ffffff;
  z-index: 1002;
  padding: 0px 16px;
  padding-bottom: 16px;
  ${(props) =>
    props.active &&
    css`
      bottom: ${props.bottom ? props.bottom : "48px"};
      border-radius: 20px 20px 0 0;
    `}
  p:not(:last-child) {
    border-bottom: solid 1px #ebebeb;
  }
`;
const ModalItem = styled.p`
  width: 100%;
  padding: 18px 16px;
  text-align: center;
  color: #333333;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  cursor: pointer;
  ${(props) =>
    props.id == props.clicked &&
    css`
      color: #828ff7;
    `}
`;
const OutSideWrap = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0px;
  position: fixed;
  background: transparent;
`;
const Btn = styled.div`
  width: 100%;
  height: 44px;
  box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
  border-radius: 68px;
  background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
  color: #ffffff;
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
  margin: auto;
  :hover {
    opacity: 0.8;
  }
`;
@inject("postStore")
@inject("userStore")
@observer
class ReportModal extends Component {
  constructor() {
    super();
    this.state = {
      clicked: 1,
    };
  }
  handleClick = (id) => {
    this.setState({
      clicked: id,
    });
  };
  report = async () => {
    const data = this.props.userStore.reportData;
    const req = {
      headers: this.props.userStore.auth_token
        ? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
        : undefined,
      data: {
        type: this.state.clicked,
        content_type: data.mode,
        object_id: data.id,
      },
    };
    const res = await userAPI.report(req).then((res) => {
      alert("신고하여 주셔서 감사합니다. 확인 후 신속히 조치하겠습니다.");
      this.props.postStore.clearAllToggle();
      this.props.userStore.clearAllToggle();
      return res.data;
    });
  };
  render() {
    return (
      <Wrapper active={this.props.userStore.reportToggle}>
        <OutSideWrap onClick={this.props.userStore.toggleReport} />
        <Modal
          active={this.props.userStore.reportToggle}
          bottom={this.props.bottom}
        >
          <ModalItem
            id={1}
            clicked={this.state.clicked}
            onClick={() => this.handleClick(1)}
          >
            <span style={{ marginRight: 7 }}>내용과 정보가 불일치</span>
            <span>
              <ClickedChkIcon clicked={this.state.clicked == 1} />
            </span>
          </ModalItem>
          <ModalItem
            id={2}
            clicked={this.state.clicked}
            onClick={() => this.handleClick(2)}
          >
            <span style={{ marginRight: 7 }}>도용으로 인한 저작권 침해</span>
            <span>
              <ClickedChkIcon clicked={this.state.clicked == 2} />
            </span>
          </ModalItem>
          <ModalItem
            id={3}
            clicked={this.state.clicked}
            onClick={() => this.handleClick(3)}
          >
            <span style={{ marginRight: 7 }}>성적/폭력적 포스팅</span>
            <span>
              <ClickedChkIcon clicked={this.state.clicked == 3} />
            </span>
          </ModalItem>
          <ModalItem
            style={{ borderBottom: "none" }}
            id={4}
            clicked={this.state.clicked}
            onClick={() => this.handleClick(4)}
          >
            <span style={{ marginRight: 7 }}>악의적인 사용</span>
            <span>
              <ClickedChkIcon clicked={this.state.clicked == 4} />
            </span>
          </ModalItem>
          <Btn onClick={this.report}> 신고하기 </Btn>
        </Modal>
      </Wrapper>
    );
  }
}

export default ReportModal;
