import React, { Component } from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 24 24"
    {...props}
  >
    <g>
      <g>
        <g>
          <path d="M2.362 18.613a11.627 11.627 0 0 1-2.285-6.94C.077 5.228 5.295 0 11.737 0c6.456 0 11.685 5.229 11.685 11.672 0 6.443-5.23 11.672-11.684 11.672-2.285 0-4.417-.658-6.215-1.794-.683.565-2.555 1.845-5.523 1.867 1.232-1.314 1.948-3.18 2.362-4.804z" />
        </g>
        <g>
          <g>
            <path
              fill="#fff"
              d="M5 11.31a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0z"
            />
          </g>
          <g>
            <path
              fill="#fff"
              d="M10 11.31a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0z"
            />
          </g>
          <g>
            <path
              fill="#fff"
              d="M15 11.31a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
