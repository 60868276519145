import axios from 'axios';
import moment from 'moment';
import * as postAPI from 'axios/postAPI';
import getMidnightMoment from './getMidnightMoment';

const getPostsByMissionsAndFilterByAccountId = async (missions, accountId) => {
  const missionsAndPosts = (
    await Promise.all(
      missions.map(({ postId }) =>
        postAPI
          .getPost({ id: postId, headers: {} })
          .then(({ data }) => data)
          .catch((e) => null)
      )
    )
  )
    .filter((o) => Boolean(o))
    .map((post) => ({
      mission: missions.find(({ postId }) => postId === post.id),
      post,
    }))
    .filter(({ post }) => post.profile && post.profile.id === accountId)
    .map(({ mission }) => mission);
  return missionsAndPosts;
};

export default async (accountId) => {
  try {
    const [yesterday, today] = [null, null].map(() => getMidnightMoment());
    yesterday.subtract(30, 'day');

    // 테스트를 위해선 주석을 푼다.
    // yesterday.subtract(1, 'day');
    // today.add(1, 'day');

    let missionsRequested = await getPostsByMissionsAndFilterByAccountId(
      (
        await axios.get(
          `https://db.lay-er.me/missions?created_at_gt=${yesterday.toISOString()}&created_at_lt=${today.toISOString()}`
        )
      ).data,
      accountId
    );

    let missions = [];
    for (const mission of missionsRequested) {
      if (!missions.find((m) => m.title === mission.title)) {
        missions.push(mission);
      }
    }

    // 오늘 작성한 미션은 필터링
    const todayMissions = await getPostsByMissionsAndFilterByAccountId(
      (
        await axios.get(
          `https://db.lay-er.me/missions?created_at_gt=${today.toISOString()}}`
        )
      ).data,
      accountId
    );
    missions = missions.filter(
      ({ title }) =>
        !todayMissions.find((todayMission) => todayMission.title === title)
    );

    // 종료가 된 미션도 필터링
    const firstMissions = await Promise.all(
      missions.map(({ title }) =>
        axios
          .get(`https://db.lay-er.me/missions?title=${title}`)
          .then(({ data }) => {
            return data[0];
          })
          .catch((e) => console.error)
      )
    );
    missions = missions.filter((mission, i) => {
      const firstMission = firstMissions[i];
      if (firstMission) {
        let startDate, elapsedDays;

        const firstMissionCreatedAt = getMidnightMoment(
          firstMission.created_at
        );
        startDate = new Date(
          firstMissionCreatedAt.years(),
          firstMissionCreatedAt.month(),
          firstMissionCreatedAt.dates()
        );

        elapsedDays = moment(today).diff(startDate, 'days');
        const missionOver = elapsedDays > firstMission.days;
        return !missionOver;
      }
      return false;
    });
    const missionAndPost = (
      await Promise.all(
        missions.map(({ postId }) =>
          postAPI
            .getPost({ id: postId, headers: {} })
            .then(({ data }) => data)
            .catch((e) => null)
        )
      )
    )
      .filter((o) => Boolean(o))
      .map((post) => ({
        mission: missions.find(({ postId }) => postId === post.id),
        post,
      }))
      .filter(({ post: { profile } }) => profile && profile.id === accountId);

    return missionAndPost;
  } catch (e) {
    console.error(e);
  }

  return null;
};
