import React, { Component } from "react";

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 20 20"
    {...props}
  >
    <g>
      <g>
        <path d="M11.034 6.886c0-.668-.515-1.083-1.346-1.083-.883 0-1.139.403-1.365.757-.16.252-.3.47-.64.47-.434 0-.897-.318-.897-.908 0-.861.937-2.135 2.947-2.135 1.876 0 3.186 1.081 3.186 2.629 0 .698-.29 1.77-1.676 2.915-.837.698-.837 1.184-.837 1.99 0 .434-.296.654-.883.654-.622 0-.822-.26-.822-1.067 0-1.331.536-1.994 1.002-2.422.09-.08.19-.162.296-.249.485-.397 1.035-.847 1.035-1.55zm-1.496 6.758c.625 0 1.152.528 1.152 1.152 0 .624-.527 1.152-1.152 1.152a1.146 1.146 0 0 1-1.136-1.152c0-.635.51-1.152 1.136-1.152zM0 19.997c2.539-.019 4.138-1.116 4.718-1.597a9.89 9.89 0 0 0 5.305 1.535c5.501 0 9.977-4.471 9.977-9.968C20 4.472 15.524 0 10.023 0 4.533 0 .066 4.472.066 9.967c0 2.216.726 4.265 1.952 5.922-.353 1.388-.965 2.984-2.018 4.108z" />
      </g>
    </g>
  </svg>
);
