import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';

import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import CommentDeleteModal from 'components/popModal/commentDeleteModal';
import CommentCard from 'components/commentCard';
import { Tabs } from 'antd-mobile';

import makeLink from 'utils/makeLink';
import UserSelectorModal from './userSelectorModal';
import Backdrop from './backdrop';
import Wrapper from './wrapper';

import * as commentAPI from 'axios/commentAPI';
import * as postAPI from 'axios/postAPI';
import * as userAPI from 'axios/userAPI';
import sendTagPush from 'utils/sendTagPush';

const Modal = styled.div`
  max-width: 480px;
  word-break: keep-all;
  padding: 16px;
  left: 0px;
  right: 0px;
  margin: auto;
  position: fixed;
  bottom: -600px;
  width: 100%;
  height: 475px;
  background: #ffffff;
  overflow: auto;
  padding-bottom: 40px;
  transition: 0.2s ease;
  z-index: 1002;
  ${(props) =>
    props.active &&
    css`
      bottom: ${props.bottom ? props.bottom : '48px'};
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
      border-radius: 20px 20px 0 0;
    `}
  p {
    color: #333333;
    font-family: 'Noto Sans CJK KR';
    font-size: 16px;
    font-weight: 700;
  }
`;
const BtnWrap = styled.div`
  width: 100%;
  max-width: 480px;
  background-color: rgb(255, 255, 255);
  position: fixed;
  bottom: -600px;
  transition: 0.2s;
  margin: -16px;
  padding: 16px;
  margin-bottom: 0px;
  ${(props) =>
    props.active &&
    css`
      bottom: ${props.bottom ? props.bottom : '48px'};
    `}
`;
const Btn = styled.div`
  display: block;
  width: 100%;
  height: 44px;
  box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
  border-radius: 68px;
  background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
  color: #ffffff;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
const CommentForm = styled.div`
  position: fixed;
  width: 100%;
  max-width: 480px;
  height: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: none;
  margin: -16px;
  margin-bottom: 0px;
  ${(props) =>
    props.active &&
    css`
      display: block;
      bottom: ${props.bottom ? props.bottom : '48px'};
    `}
`;
const CommentInput = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  padding: 16px;
  padding-right: 75px;
  ::-webkit-scrollbar {
    display: none !important;
  }
`;
const ReplyInfoWrap = styled.div`
  position: absolute;
  display: none;
  top: -40px;
  width: 100%;
  max-width: 480px;
  height: 40px;
  border: none;
  padding: 12px;
  background-color: rgb(248, 248, 248);
  ${(props) =>
    props.active &&
    css`
      display: block;
      bottom: ${props.bottom ? props.bottom : '48px'};
    `}
  p {
    font-size: 13px;
    font-weight: 400;
    color: #bebebe;
    margin: 0px;
  }
`;
const CommentSubmit = styled.button`
  border: none;
  text-align: center;
  width: 50px;
  height: 32px;
  border-radius: 20px;
  vertical-align: middle;
  font-family: 'Noto Sans CJK KR';
  font-size: 13px;
  font-weight: 700;
  line-height: 32px;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 8px;
  background: #1f75fe;
  color: #ffffff;
`;
const CommentWrap = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
`;

@inject('userStore')
@inject('postStore')
@observer
class CommentModal extends Component {
  constructor() {
    super();
    this.state = {
      accessToken: false,
      comment: '',
      inputToggle: false,
      initData: false,
      replyToggle: false,
      replyComment: false,
      deleteModalOpen: false,
      deleteId: null,
      profile: null,
      loading: false,
      drawerOpen: false,
      page: 0,
      cursor: -1,
      tagged: [],
    };
  }
  async componentDidMount() {
    const profileReq = {
      headers: { Authorization: `Bearer ${this.props.userStore.auth_token}` },
    };
    let profile;
    let token;
    try {
      profile = await userAPI.getMyUserProfile(profileReq).then((res) => {
        return res.data;
      });
      token = this.props.userStore.getTokenOrToggleLogin();
    } catch (e) {
      console.error(e);
    }

    const req = {
      post: this.props.postId,
      headers: this.props.userStore.auth_token
        ? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
        : false,
    };
    let commentData;
    try {
      commentData = await Promise.all([
        postAPI.getPostComments(req).then((res) => {
          return res.data;
        }),
      ]);
    } catch (e) {}
    this.setState({
      initData: commentData ? commentData[0] : null,
      replyToggle: false,
      replyComment: false,
      profile,
      accessToken: token,
    });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps != this.props) {
      const req = {
        post: this.props.postId,
        headers: this.props.userStore.auth_token
          ? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
          : false,
      };
      if (this.props.postId) {
        const commentData = await Promise.all([
          postAPI.getPostComments(req).then((res) => {
            return res.data;
          }),
        ]);
        this.setState({
          inputToggle: this.props.open && Boolean(this.state.profile),
          initData: commentData[0],
          replyToggle: false,
          replyComment: false,
        });
      } else {
        this.setState({
          inputToggle: this.props.open && Boolean(this.state.profile),
          replyToggle: false,
          replyComment: false,
        });
      }
    }
  }

  handleWrite = () => {
    const token = this.props.userStore.getTokenOrToggleLogin();
    if (token) {
      this.setState(
        (_) => ({ inputToggle: true, accessToken: token }),
        () => {
          this.commentInput.focus();
        }
      );
    } else {
      this.props.handleClose();
    }
  };
  handleReply = (parentComment) => {
    const token = this.props.userStore.getTokenOrToggleLogin();
    if (!token) {
      this.props.handleClose();
    } else {
      this.setState(
        {
          inputToggle: true,
          accessToken: token,
          replyToggle: true,
          replyComment: parentComment,
        },
        () => {
          this.commentInput.focus();
          this.setState({ replyToggle: true });
        }
      );
    }
  };
  handleCloseWrite = () => {
    if (this.state.inputToggle) {
      this.setState({
        replyToggle: false,
      });
    }
  };

  _onChangeComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  handleSubmit = async () => {
    if (this.state.accessToken) {
      this.setState({ loading: true });
      let req;
      if (this.state.replyToggle) {
        req = {
          parent: this.state.replyComment.id,
          data: { content: this.state.comment },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
        };
      } else {
        req = {
          post: this.props.postId,
          data: { content: this.state.comment },
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
        };
      }
      req.data.content = makeLink({
        str: this.state.comment,
        tagged: this.state.tagged,
      });

      const commentResult = await Promise.all([
        postAPI
          .createPostComment(req)
          .then((res) => {
            return res.data;
          })
          .catch((e) => {
            return false;
          }),
      ]);
      if (commentResult[0]) {
        const { tagged } = this.state;
        await sendTagPush({
          ids: tagged.map(({ id }) => id),
          nickname: this.state.profile.nickname,
          postId: this.props.postId,
          prefix: '댓글',
        });
        const realTagged = tagged.filter(
          (data) => this.state.comment.indexOf(`@${data.nickname}`) > -1
        );
        // 태그된유저들

        this.handleCloseWrite();
        req = {
          post: this.props.postId,
          headers: { Authorization: `Bearer ${this.state.accessToken}` },
        };
        const reFetchedData = await Promise.all([
          postAPI
            .getPostComments(req)
            .then((res) => {
              return res.data;
            })
            .catch((e) => {
              return false;
            }),
        ]);
        if (reFetchedData[0]) {
          this.setState({
            comment: '',
            initData: reFetchedData[0],
          });
          this.props.handleChange(reFetchedData[0].count);
        }
      }
      this.setState({ loading: false });
    } else {
      this.props.handleClose();
      this.props.userStore.toggleLoginRequired();
    }
  };
  // 아이디를 갖고 삭제 진행
  handleRemove = async (data) => {
    this.setState({
      deleteModalOpen: true,
      deleteId: data.id,
    });
    // data.id를 가지고 삭제한다.
    // is_blocked를 true로 주어본다.
  };

  handleDelete = async () => {
    this.setState({
      initData: null,
      replyToggle: false,
      replyComment: false,
    });
    const headers = this.props.userStore.auth_token
      ? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
      : false;
    const deleteReq = {
      id: this.state.deleteId,
      headers,
      data: {
        is_blocked: true,
      },
    };
    const result = await commentAPI.handleDelete(deleteReq);
    this.setState({ deleteModalOpen: false });

    let commentData;
    try {
      const req = {
        post: this.props.postId,
        headers,
      };
      commentData = await Promise.all([
        postAPI.getPostComments(req).then((res) => {
          return res.data;
        }),
      ]);
    } catch (e) {}
    this.setState({
      initData: commentData ? commentData[0] : null,
      replyToggle: false,
      replyComment: false,
    });
  };

  getCaret = (el) => {
    if (el.selectionStart) {
      return el.selectionStart;
    } else if (document.selection) {
      el.focus();

      var r = document.selection.createRange();
      if (r == null) {
        return 0;
      }

      var re = el.createTextRange(),
        rc = re.duplicate();
      re.moveToBookmark(r.getBookmark());
      rc.setEndPoint('EndToStart', re);

      return rc.text.length;
    }
    return 0;
  };
  render() {
    const { handleClose, open, voteData } = this.props;
    return (
      <>
        <Backdrop open={open} handleClose={handleClose} />
        <Wrapper active={open}>
          <Modal active={open} bottom={this.props.bottom}>
            {this.state.initData ? (
              <Fragment>
                {this.state.loading ? (
                  <LoadingSpinner />
                ) : (
                  <CommentWrap
                    style={{
                      height: '100%',
                      width: '100%',
                      overflow: 'auto',
                      overflowX: 'hidden',
                    }}
                    onClick={this.handleCloseWrite}
                  >
                    <p> {`댓글 ${this.state.initData.count}개`} </p>
                    {this.state.initData.data.map((comment, index) => (
                      <Fragment key={comment.id}>
                        {!comment.parent && (
                          <CommentCard
                            voteData={voteData}
                            data={comment}
                            handleRemove={(data) => this.handleRemove(data)}
                            profile={this.state.profile}
                            handleReply={this.handleReply}
                          />
                        )}
                      </Fragment>
                    ))}
                  </CommentWrap>
                )}
                <BtnWrap active={open} bottom={this.props.bottom}>
                  {this.state.inputToggle ? (
                    <CommentForm
                      active={this.state.inputToggle}
                      bottom={this.props.bottom}
                    >
                      <ReplyInfoWrap active={this.state.replyToggle}>
                        {this.state.replyComment && (
                          <p>
                            {this.state.replyComment.profile.nickname}님에게
                            답글 남기는 중
                          </p>
                        )}
                      </ReplyInfoWrap>
                      <CommentInput
                        ref={(commentInput) =>
                          (this.commentInput = commentInput)
                        }
                        onFocus={(e) => {
                          e.preventDefault();
                          this.setState({
                            prevScroll: window.scrollY,
                            cursor: e.selectionEnd,
                          });
                        }}
                        onBlur={(e) => {
                          e.preventDefault();
                          setTimeout(() => {
                            window.scrollTo(0, this.state.prevScroll);
                          }, 500);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === '@') {
                            setTimeout(() => {
                              this.setState({ drawerOpen: true });
                            }, 300);
                          }
                          this.setState({ cursor: this.getCaret(e.target) });
                        }}
                        value={this.state.comment}
                        onChange={this._onChangeComment}
                      />
                      <CommentSubmit onClick={this.handleSubmit}>
                        게시
                      </CommentSubmit>
                    </CommentForm>
                  ) : (
                    <Btn onClick={this.handleWrite}>댓글 쓰기</Btn>
                  )}
                </BtnWrap>
              </Fragment>
            ) : (
              <LoadingSpinner />
            )}
          </Modal>
        </Wrapper>
        <CommentDeleteModal
          open={this.state.deleteModalOpen}
          handleClose={() =>
            this.setState({
              deleteModalOpen: false,
            })
          }
          handleDelete={this.handleDelete}
          bottom={'0px'}
        />
        <UserSelectorModal
          open={this.state.drawerOpen}
          onClose={() => this.setState({ drawerOpen: false })}
          onSelect={(data) => {
            const { cursor } = this.state;
            const nextComment = [
              this.state.comment.slice(0, cursor),
              `@${data.nickname} `,
              this.state.comment.slice(cursor + 1),
            ].join('');
            this.setState({
              drawerOpen: false,
              comment: nextComment,

              tagged: this.state.tagged.find((p) => p.id === data.id)
                ? this.state.tagged
                : this.state.tagged.concat(data),
            });
          }}
        />
      </>
    );
  }
}

export default CommentModal;
