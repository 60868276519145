import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import { getLastEnteredMobile } from '../../../utils/lastEnteredMobile';

const OrderText = styled.p`
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 15px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 0px;
  margin-top: 24px;
`;
const DescText = styled.p`
  color: #999999;
  font-family: 'Noto Sans CJK KR';
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 0px;
`;
const Amount = styled.p`
  color: #ff4e00;
  font-family: 'Noto Sans CJK KR';
  font-size: 18px;
  font-weight: 500;
  margin-top: 14px;
  margin-bottom: 14px;
`;
const Request = styled.p`
  color: #808080;
  font-family: 'Noto Sans CJK KR';
  font-size: 13px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0px;
`;
const Button = styled.button`
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  background: none;
  border: none;
  width: 50%;
  height: 50px;
  ${(props) =>
    props.red &&
    css`
      color: #f85e3a;
      padding-left: 1px;
      border-left: 1px solid #ebebeb;
    `}
`;
const ButtonWrapper = styled.div`
  margin-top: 30px;
  border-top: 1px solid #ebebeb;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    borderRadius: 10,
    textAlign: 'center',
  },
  input: {
    textAlign: 'center',
    width: 100,
    color: '#a2a2a2',
    fontSize: 20,
    fontWeight: 400,
    fontFamily: 'Noto Sans CJK KR',
  },
}));

export default function SimpleModal({ open, onClose, onConfirm, item }) {
  const classes = useStyles();
  const [phone, setPhone] = useState('');
  useEffect(() => {
    if (open) {
      const lastEnteredMobile = getLastEnteredMobile();
      if (lastEnteredMobile) {
        setPhone(lastEnteredMobile);
      }
    }
  }, [open]);
  if (!item) return null;
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <OrderText>주문하시겠습니까?</OrderText>
        <DescText>별포인트가 차감됩니다.</DescText>
        <Amount>- {item.CPN_PRICE}</Amount>
        <Request>기프티콘을 받을 전화번호를 입력해주세요.</Request>
        <Request>(-를 제외하고 입력해주세요.)</Request>
        <TextField
          className={classes.input}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <ButtonWrapper>
          <Button onClick={() => onConfirm(phone)}>주문하기</Button>
          <Button red onClick={onClose}>
            취소하기
          </Button>
        </ButtonWrapper>
      </div>
    </Modal>
  );
}
