import React, { Component, Fragment } from 'react';
import axios from 'axios';
import styled, { css } from 'styled-components';
import { observer, inject } from 'mobx-react';

import PostDeleteModal from 'components/popModal/postDeleteModal';
import Backdrop from './backdrop';
import * as postAPI from 'axios/postAPI';
import history from 'utils/history';
import Wrapper from './wrapper';
import BeautyCard from 'images/category_card_beauty.png';
import FashionCard from 'images/category_card_fashion.png';
import HairCard from 'images/category_card_hair.png';
import BeautyCardSelected from 'images/category_card_beauty_selected.png';
import FashionCardSelected from 'images/category_card_fashion_selected.png';
import HairCardSelected from 'images/category_card_hair_selected.png';

const Modal = styled.div`
  max-width: 480px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0px;
  right: 0px;
  margin: auto;
  bottom: -450px;
  width: 100%;
  background-color: #ffffff;
  transition: 0.2s ease;
  z-index: 1002;
  ${(props) =>
    props.active &&
    css`
      bottom: ${props.bottom ? props.bottom : '48px'};
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
      border-radius: 20px 20px 0 0;
    `}
  ${(props) =>
    props.isMine &&
    css`
      height: 284px;
    `}
`;
const BigTextWrap = styled.p`
  margin-top: 16px;
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
`;
const LoginBtn = styled.div`
  width: 100%;
  height: 44px;
  box-shadow: 0 2px 10px rgba(176, 141, 247, 0.5);
  border-radius: 68px;
  background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
  color: #ffffff;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
const CardList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
const Card = styled.img`
	width: 30%
	margin: 7px;
	${(props) =>
    props.blur &&
    css`
      opacity: 0.8;
    `}
`;

@inject('userStore')
@inject('postStore')
@observer
class PostMoreInfoModal extends Component {
  constructor() {
    super();
    this.state = {
      selected: null,
    };
  }
  deletePost = async () => {
    const req = {
      id: this.props.post.id,
      headers: this.props.userStore.auth_token
        ? { Authorization: `Bearer ${this.props.userStore.auth_token}` }
        : false,
    };
    await postAPI.deletePost(req).then((res) => {
      return res.data;
    });
    this.props.handleClose();
    this.props.postStore.togglePostDelete();
    // history.push('/');
    // window.location.reload();
    window.location.href = '/';
  };
  render() {
    const { open, post, handleClose } = this.props;

    return (
      <>
        <Backdrop open={open} handleClose={handleClose} />
        <Wrapper active={open}>
          <Modal
            isMine={this.props.isMine}
            active={open}
            bottom={this.props.bottom}
          >
            <BigTextWrap>어떤 주제의 포스팅인가요?</BigTextWrap>
            <CardList>
              <Card
                src={
                  this.state.selected === 0 ? FashionCardSelected : FashionCard
                }
                role="button"
                onClick={() =>
                  this.setState({
                    selected: 0,
                  })
                }
                blur={this.state.selected !== 0}
              />
              <Card
                src={
                  this.state.selected === 1 ? BeautyCardSelected : BeautyCard
                }
                role="button"
                onClick={() =>
                  this.setState({
                    selected: 1,
                  })
                }
              />
              <Card
                src={this.state.selected === 2 ? HairCardSelected : HairCard}
                role="button"
                onClick={() =>
                  this.setState({
                    selected: 2,
                  })
                }
              />
            </CardList>
            <LoginBtn
              onClick={() => {
                if (this.state.selected === null)
                  alert('주제를 선택하여 주십시요.');
                else this.props.onClick(this.state.selected);
              }}
            >
              선택완료
            </LoginBtn>
          </Modal>
          {/* <PostDeleteModal deletePost={this.deletePost} /> */}
        </Wrapper>
      </>
    );
  }
}

export default PostMoreInfoModal;
