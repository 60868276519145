import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from '../../Icon/likeBorder';
import FilledIcon from '../../Icon/likeOn';
import WhiteStar from 'images/whiteStar.png';

const isDev = process.env.NODE_ENV !== 'production';
const devImg =
  'http://atom.donutbook.co.kr:11405/goods/4OAPREM7BIJOS7BSWXN7.jpg';

const LikeHeader = styled.div`
  position: relative;
  background-color: transparent;
  text-align: right;
  padding: 8px;
  padding-right: 16px;
  margin-top: -8px;
`;
const Layout = styled.div`
  width: 48%;
  padding: 40px 0px 20px 0px;
  margin-top: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #ffffff;
`;
const Img = styled.img`
  width: 85%;
`;
const Body = styled.div`
  margin-top: -35px;
  text-align: center;
`;
const Company = styled.span`
  color: #999999;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
`;
const Name = styled.span`
  color: #333333;
  font-family: 'Noto Sans CJK KR';
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
`;
const PriceWrapper = styled.div`
  margin-top: 20px;
`;
const Price = styled.span`
  box-shadow: 1px 1px 50px rgba(191, 191, 191, 0.7);
  padding: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0);
  ${(props) =>
    props.on
      ? css`
          background-image: linear-gradient(to right, #828ff7 0%, #b08df7 100%);
        `
      : css`
          background-color: #aeaeae;
        `}

  display: inline;
`;
const Amount = styled.span`
  color: #ffffff;
  font-family: 'Noto Sans CJK KR';
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 15px;
`;
export default ({ item, onClick, disabled, onLike, like }) => {
  const { NM_GOODS_COMPANY, GOODS_IMAGE, NM_GOODS, CPN_PRICE } = item;
  return (
    <Layout>
      <Body>
        {/* <Img src={isDev ? devImg : GOODS_IMAGE} /> */}
        <Img src={GOODS_IMAGE} />
        <div>
          <Company>{NM_GOODS_COMPANY}</Company>
        </div>
        <div>
          <Name>{NM_GOODS}</Name>
        </div>
        <PriceWrapper onClick={disabled ? () => {} : onClick}>
          <Price on={!disabled}>
            <img
              src={WhiteStar}
              style={{
                width: 12,
                height: 12,
                marginRight: 4,
                lineHeight: 15,
                marginBottom: 3,
              }}
            />
            <Amount>{CPN_PRICE}</Amount>
          </Price>
        </PriceWrapper>
      </Body>
    </Layout>
  );
};
