import React, { Component } from "react";
import styled, { css } from "styled-components";

class LikeIcon extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="24"
        viewBox="0 0 26 24"
      >
        <g>
          <g>
            <path
              fill="#1f75fe"
              d="M11.972 5.797c.087.27.329.45.601.45a.638.638 0 0 0 .602-.45c.53-1.657 2.628-3.504 4.789-3.504 2.973 0 5.392 2.513 5.392 5.603 0 4.006-2.98 7.648-5.481 9.997a35.163 35.163 0 0 1-5.302 4.069 35.239 35.239 0 0 1-5.301-4.069c-2.5-2.35-5.481-5.994-5.481-9.997 0-3.09 2.418-5.603 5.392-5.603 2.16 0 4.258 1.847 4.789 3.504zm.91 17.5c.028-.015 2.887-1.651 5.826-4.409 1.7-1.596 3.059-3.227 4.037-4.85 1.247-2.072 1.879-4.139 1.879-6.142 0-3.816-2.988-6.92-6.66-6.92-1.243 0-2.564.484-3.718 1.362a7.17 7.17 0 0 0-1.673 1.792A7.146 7.146 0 0 0 10.9 2.338C9.746 1.46 8.426.976 7.183.976 3.51.976.523 4.08.523 7.896c0 2.003.632 4.07 1.879 6.142.982 1.627 2.341 3.26 4.038 4.85 2.929 2.748 5.796 4.393 5.824 4.41a.601.601 0 0 0 .31.084.59.59 0 0 0 .308-.084z"
            />
            <path
              fill="none"
              stroke="#1f75fe"
              stroke-miterlimit="20"
              stroke-width=".3"
              d="M11.972 5.797c.087.27.329.45.601.45a.638.638 0 0 0 .602-.45c.53-1.657 2.628-3.504 4.789-3.504 2.973 0 5.392 2.513 5.392 5.603 0 4.006-2.98 7.648-5.481 9.997a35.163 35.163 0 0 1-5.302 4.069 35.239 35.239 0 0 1-5.301-4.069c-2.5-2.35-5.481-5.994-5.481-9.997 0-3.09 2.418-5.603 5.392-5.603 2.16 0 4.258 1.847 4.789 3.504zm.91 17.5c.028-.015 2.887-1.651 5.826-4.409 1.7-1.596 3.059-3.227 4.037-4.85 1.247-2.072 1.879-4.139 1.879-6.142 0-3.816-2.988-6.92-6.66-6.92-1.243 0-2.564.484-3.718 1.362a7.17 7.17 0 0 0-1.673 1.792A7.146 7.146 0 0 0 10.9 2.338C9.746 1.46 8.426.976 7.183.976 3.51.976.523 4.08.523 7.896c0 2.003.632 4.07 1.879 6.142.982 1.627 2.341 3.26 4.038 4.85 2.929 2.748 5.796 4.393 5.824 4.41a.601.601 0 0 0 .31.084h0a.59.59 0 0 0 .308-.084z"
            />
          </g>
        </g>
      </svg>
    );
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        {...this.props}
      >
        <g>
          <g>
            <path d="M17.66.99c-2.64-1.8-5.9-.96-7.66 1.1C8.24.03 4.98-.82 2.34.99.94 1.95.06 3.57 0 5.28c-.14 3.88 3.3 6.99 8.55 11.76l.1.09c.76.69 1.93.69 2.69-.01l.11-.1C16.7 12.26 20.13 9.15 20 5.27c-.06-1.7-.94-3.32-2.34-4.28z" />
          </g>
        </g>
      </svg>
    );
  }
}

export default LikeIcon;
