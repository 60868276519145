import React from 'react';
import axios from 'axios';
import { observer, inject } from 'mobx-react';
import * as userAPI from 'axios/userAPI';
import Loader from '../../components/loadingSpinner/spinnerCurtain';
import Profile from './Profile';
import Layout from './Layout';
import Category from './Category';
import Products from './Products';
import Dialog from './Dialog';
import getUserId from './_libs/getUserId';
import { url } from '../../utils/starApi';
import { setLastEnteredMobile } from '../../utils/lastEnteredMobile';

import { Wrap } from '../../components/style/custom-styled-components';

const list = [
  '전체',
  '커피/음료',
  '아이스크림',
  '베이커리/떡',
  '편의점/마트',
  '문화/생활/기타',
  '피자/버거/치킨',
];
// 0: "피자/버거/치킨"
// 1: "아이스크림"
// 2: "커피/음료"
// 3: "편의점/마트"
// 4: "문화/생활/기타"
// 5: "베이커리/떡"

@inject('userStore')
@observer
class MyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      user: null,
      userFromStar: null,
      sumOfPoint: 0,
      onlyLike: false,
      listSelectedIdx: 0,
      modalOpen: false,
      item: null,
      loading: false,
    };
  }
  async componentDidMount() {
    try {
      const {
        data: { sum },
      } = await axios.get(
        `https://admin.clpick.com/histories/sum?user=${getUserId()}`
      );
      this.setState({
        sumOfPoint: sum,
      });
    } catch (e) {}
    // get users
    try {
      const { data } = await axios(`${url}/get-my-info`, {
        method: 'post',
        data: { userId: getUserId() },
      });
      this.setState({ user: data, userFromStar: data });
    } catch (e) {
      console.error(e);
      alert(e.message);
    }

    // get products
    const { data } = await axios(`${url}/products`);
    this.setState({ products: data });
  }
  order = (item) => {
    this.setState({ modalOpen: true, item });
  };
  onConfirm = async (mobile) => {
    if (mobile === '') {
      alert('올바르게 입력하여 주십시요.');
    } else {
      this.setState({ loading: true });
      const { id } = this.state.userFromStar;

      try {
        await axios(`${url}/purchase`, {
          method: 'post',
          data: { userId: id, mobile, product: this.state.item },
        });
        alert(`발송완료 되었습니다. 기프티콘 문자를 확인해주세요.`);
        setLastEnteredMobile(mobile);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } catch (e) {
        console.error(e, e.message, e.body);
        alert('에러가 발생했습니다.');
        this.setState({ modalOpen: false, loading: false });
      }
    }
  };
  onLike = async (productId) => {
    try {
      const { data } = await axios(`${url}/like`, {
        method: 'post',
        data: {
          userId: this.state.userFromStar.id,
          productId,
        },
      });
      this.setState({
        userFromStar: {
          ...this.state.userFromStar,
          likes: data.likes,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  render() {
    const { products, user, userFromStar, onlyLike, listSelectedIdx } =
      this.state;
    if (!user || !userFromStar || products.length === 0) {
      return (
        <Wrap>
          <Loader />
        </Wrap>
      );
    }
    const amount = this.state.sumOfPoint;
    if (this.state.loading) return <Loader />;
    return (
      <div>
        <Layout>
          <Profile user={user} amount={amount} />
          <Category
            list={list}
            selected={listSelectedIdx}
            onClick={(listSelectedIdx) => this.setState({ listSelectedIdx })}
          />
          <Products
            onlyLike={onlyLike}
            likeList={[]}
            list={
              listSelectedIdx > 0
                ? products.filter((o) => o.CATEGORY === list[listSelectedIdx])
                : products
            }
            amount={amount}
            onClick={this.order}
            onLike={this.onLike}
            user={userFromStar}
          />
        </Layout>
        <Dialog
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          onConfirm={this.onConfirm}
          item={this.state.item}
        />
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

export default MyPage;
