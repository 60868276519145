import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	left: 0;
	right: 0;
	margin: auto;
	width: 100%;
	// max-width: 480px;
	position: absolute;
	transition: 0.2s background-color ease, z-index 0.2s ease;
	z-index: 1002;
`;

class _Wrapper extends Component {
	render() {
		const { open, children } = this.props;
		return <Wrapper active={open}>{children}</Wrapper>;
	}
}

export default _Wrapper;
