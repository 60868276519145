import React from 'react';
import styled, { css } from 'styled-components';
import DetailMoreIcon from '../myIcons/detailMoreIcon';
import CloseIcon from '../myIcons/closeIcon';

const Layout = styled.div`
	padding-top: 28px;
	padding-bottom: 8px;
	z-index: 10;
	width: 100%;
	color: white;
	text-align: center;
	// background: red;
	position: absolute;
`;
const Detail = styled.span`
	position: absolute;
	right: 16px;
`;
const Close = styled.span`
	position: absolute;
	left: 16px;
`;
export default ({ onClick, onClose }) => (
	<Layout>
		<Detail onClick={() => onClick('detailMore')}>
			<DetailMoreIcon />
		</Detail>
		{onClose && (
			<Close
				onClick={e => {
					e.stopPropagation();
					onClose();
				}}
			>
				<CloseIcon style={{ fill: 'white' }} />
			</Close>
		)}
	</Layout>
);
